export type Theme = 'dark' | 'light'

const useBackground = (theme: Theme, index: number) => {
  const alternate = index % 2 === 0

  if (theme === 'dark') {
    return alternate
      ? 'bg-gradient-to-r from-blue-900 to-blue-500'
      : 'bg-gradient-to-l from-blue-900 to-blue-500'
  } else {
    return alternate
      ? 'bg-gradient-to-r from-white to-gray-100'
      : 'bg-gradient-to-l from-white to-gray-100'
  }
}

export default useBackground
