import React, { FC } from 'react'
import MobileNavigationMenuButton from '../navigation-menu/mobile-navigation-menu-button'
import { DropdownGroupingItemProps } from './desktop-dropdown-grouping-item'

const MobileDropdownGroupingItem: FC<DropdownGroupingItemProps> = ({
  data,
  inverse,
}) => {
  const caption = 'text' in data ? data.text : data.menuTitle

  return (
    <MobileNavigationMenuButton to={data} inverse={inverse}>
      {caption}
    </MobileNavigationMenuButton>
  )
}

export default MobileDropdownGroupingItem
