import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

type FormLabelProps = {
  htmlFor?: string
  inverse: boolean
  text: ReactNode
}

const FormLabel: FC<FormLabelProps> = ({ htmlFor, inverse, text }) => {
  return (
    <label
      htmlFor={htmlFor}
      className={classNames('block', {
        'text-white': inverse,
        'text-gray-900': !inverse,
      })}
    >
      {text}
    </label>
  )
}

export default FormLabel
