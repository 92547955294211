import React, { ReactNode } from 'react'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import CardThumbnail from './card-thumbnail'

type CardImageProps = {
  children?: ReactNode
  gatsbyImageData: IGatsbyImageData
  overlay?: boolean
  thumbnail?: {
    gatsbyImageData: IGatsbyImageData
  }
}

const CardImage: React.FC<CardImageProps> = ({
  children,
  gatsbyImageData,
  overlay,
  thumbnail,
}) => {
  return (
    <div className="relative">
      {gatsbyImageData ? (
        <GatsbyImage image={gatsbyImageData} className="w-full h-48 object-cover" />
      ) : (
        <div className="w-full h-48 bg-gradient-to-br from-blue-400 to-blue-900" />
      )}
      {overlay && (
        <div className="absolute left-0 top-0 w-full h-full bg-gradient-to-b from-transparent to-gray-900"></div>
      )}

      {thumbnail && (
        <div className="flex items-end absolute left-0 bottom-0 px-6 py-4 space-x-4">
          <CardThumbnail gatsbyImageData={thumbnail?.gatsbyImageData} />
          <div className="mb-4">{children}</div>
        </div>
      )}
    </div>
  );
}

export default CardImage
