import { Theme } from '../hooks/use-background'

export const BACKGROUND_THEME: { [key: string]: Theme } = {
  DARK: 'dark',
  DEFAULT: 'dark',
  LIGHT: 'light',
}

export const isDarkTheme = (theme: Theme): boolean =>
  theme === BACKGROUND_THEME.DARK
