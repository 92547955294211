import React from 'react'
import { CallToActionTarget } from '../call-to-action'
import Menu from '../menu/menu'

type HeaderCallToActionProps = {
  caption: string
  mobile?: boolean
  target: CallToActionTarget
}

const HeaderCallToAction: React.FC<HeaderCallToActionProps> = ({
  caption,
  mobile,
  target,
}) => {
  return (
    <Menu.ButtonLink to={target} fullWidth={mobile}>
      {caption}
    </Menu.ButtonLink>
  )
}

export default HeaderCallToAction
