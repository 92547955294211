import { Link as GatsbyLink } from 'gatsby'
import type { GatsbyLinkProps } from 'gatsby'
import React, { PropsWithoutRef, ReactNode } from 'react'
import { ExternalTarget, InternalTarget } from './call-to-action'

export type To = ExternalTarget | InternalTarget | string

export const isHrefExternal = (href: string): boolean =>
  href.startsWith(`http://`) ||
  href.startsWith(`https://`) ||
  href.startsWith(`//`) ||
  href.startsWith(`mailto:`) ||
  href.startsWith(`tel:`)

const findHref = (to: To): string => {
  if (typeof to === 'string') {
    return to
  }

  if ('url' in to) {
    return to.url
  }

  return to.fields?.route
}

const findText = (children: ReactNode, to: To) => {
  if (typeof to === 'string') {
    return children
  }

  if ('text' in to) {
    return children || to.text
  }

  return children || to.menuTitle
}

type LinkProps<TState> = PropsWithoutRef<
  Omit<GatsbyLinkProps<TState>, 'to'> & {
    to: To,
    children: ReactNode,
    openInNewTab?: boolean,
    target?: string,
  }
>

function Link<TState>({ children, to, ...props }: LinkProps<TState>) {
  const text = findText(children, to)
  const href = findHref(to)
  console.log('LINK PROPS:', { ...props, to: href})

  if (isHrefExternal(href) || props.openInNewTab) {
    if (props.target === '_blank' || props.openInNewTab) {
      return (
        <a
          {...props}
          href={findHref(to)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {text}
        </a>
      )
    }

    return (
      <a {...props} href={href}>
        {text}
      </a>
    )
  }

  return (
    <GatsbyLink<TState> to={href} {...props}>
      {text}
    </GatsbyLink>
  )
}

export default Link
