import React, { FC } from 'react'
import { SectionBlockData } from '../blocks/section-block'
import Section from './section/section'

type ChildSectionProps = Omit<SectionBlockData, 'backgroundImage'>

const ChildSection: FC<ChildSectionProps> = ({
  body,
  callToActionCaption,
  callToActionTarget,
  htmlElementId,
  title,
  theme,
}) => {
  const inverse = theme === 'dark'

  return (
    <Section.Body id={htmlElementId}>
      <Section.Heading variant="h3">{title}</Section.Heading>
      {body && <Section.Lead inverse={inverse}>{body}</Section.Lead>}
      {callToActionCaption && callToActionTarget && (
        <Section.CallToAction
          caption={callToActionCaption}
          target={callToActionTarget}
          variant={inverse ? 'secondary' : 'primary'}
        />
      )}
    </Section.Body>
  )
}

export default ChildSection
