import { useState } from 'react'

const useOperatorOrServiceSearchQuery = () => {
  const [searchQuery, setSearchQuery] = useState<string>('')

  const onSearchQueryChange = (event) => {
    const lowerCase = event.target.value.toLowerCase()
    setSearchQuery(lowerCase)
  }

  return { searchQuery, onSearchQueryChange }
}

export default useOperatorOrServiceSearchQuery
