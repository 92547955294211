import React, { FC, ReactNode } from 'react'
import MenuButton from './menu-button'
import MenuButtonLink from './menu-button-link'
import MenuDivider from './menu-divider'

type MenuProps = {
  children: ReactNode
}

type MenuComponent = React.FC<MenuProps> & {
  Button: typeof MenuButton
  ButtonLink: typeof MenuButtonLink
  Divider: typeof MenuDivider
}

const Menu: MenuComponent = ({ children }) => {
  return <div>{children}</div>
}

Menu.Button = MenuButton
Menu.ButtonLink = MenuButtonLink
Menu.Divider = MenuDivider

export default Menu
