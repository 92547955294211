import React, { FC } from 'react'

type CardTagProps = {
  children: string
}

const CardTag: FC<CardTagProps> = ({ children }) => {
  return (
    <div className="my-1 mx-1">
      <span className="px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800">
        {children}
      </span>
    </div>
  )
}

export default CardTag
