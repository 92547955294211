import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import { ExternalTarget, InternalTarget } from '../call-to-action'
import Link from '../link'

type MobileNavigationMenuButtonProps = {
  children: ReactNode
  inverse: boolean
  to?: ExternalTarget | InternalTarget | string
}

const MobileNavigationMenuButton: FC<MobileNavigationMenuButtonProps> = ({
  children,
  inverse,
  ...props
}) => {
  const as = props.to ? Link : 'button'

  return (
    <div className="sm:mx-4">
      <Disclosure.Button
        as={as}
        {...props}
        className={classNames(
          'flex items-center justify-between w-full px-2 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset',
          {
            'text-blue-100 hover:text-white focus:ring-white': inverse,
            'text-blue-700 hover:text-blue-900 focus:ring-blue-600': !inverse,
          }
        )}
      >
        {children}
      </Disclosure.Button>
    </div>
  )
}

export default MobileNavigationMenuButton
