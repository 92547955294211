import React, { FC } from 'react'
import useInnerHTML from '../../hooks/use-inner-html'
import { ExternalTarget, InternalTarget } from '../call-to-action'
import Link from '../link'

type AnnouncementLinkProps = {
  text: string
  to: string | ExternalTarget | InternalTarget
}

const AnnouncementLink: FC<AnnouncementLinkProps> = ({ text, to }) => {
  const [innerHTML, innerNodes] = useInnerHTML(text)

  return (
    <Link
      to={to}
      className="inline-block my-1 px-4 py-0.5 rounded-full shadow-sm bg-gradient-to-b from-blue-100 to-blue-200 text-blue-900"
      {...innerHTML}
    >
      {innerNodes}
    </Link>
  )
}

export default AnnouncementLink
