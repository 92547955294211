import React, { FC } from 'react'
import { ContactData } from '../contact/contact'
import EcosystemCardContactLink from './ecosystem-card-contact-link'

type EcosystemCardContactProps = {
  cc: string
  data: Omit<ContactData, 'image'>
}

const EcosystemCardContact: FC<EcosystemCardContactProps> = ({ cc, data }) => {
  const { displayName, email, phone, responsibility, title } = data

  return (
    <div className="">
      <div className="text-sm text-gray-700">
        {responsibility}
        {responsibility && title && ' / '}
        {title}
      </div>
      <div>{displayName}</div>
      <div>
        <EcosystemCardContactLink href={`mailto:${email}?cc=${cc}`}>
          {email}
        </EcosystemCardContactLink>
      </div>
      <div>
        <EcosystemCardContactLink href={`tel:${phone}`}>
          {phone}
        </EcosystemCardContactLink>
      </div>
    </div>
  )
}

export default EcosystemCardContact
