import React, { FC } from 'react'
import { ServiceData } from '../../blocks/service-collection-block'
import { OperatorData } from '../../blocks/operator-collection-block'
import { Disclosure } from '@headlessui/react'
import RichText from '../rich-text'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import Button from '../button'
import ServiceListItemTitle from './service-list-item-title'
import Link from '../link'

// TODO: Move to localization files
const MORE_ABOUT_US = 'More about us'

type ServiceListItemProps = {
  data: OperatorData | ServiceData
}

const ServiceListItem: FC<ServiceListItemProps> = ({ data }) => {
  if (!('childrenContentfulService' in data)) {
    return <></>
  }

  const { childrenContentfulService } = data

  const {
    category,
    contacts,
    description,
    displayName,
    metaDescription,
    operator: operatorData,
    referenceLinks,
    website,
  } = childrenContentfulService[0]

  const operator = operatorData && operatorData[0]
  const operatorDisplayName = operator && operator.displayName
  const operatorLogo = operator && operator.logo

  return (
    <li className="my-0 pl-0">
      <Disclosure>
        {({ open }) => (
          <div className="">
            <Disclosure.Button className="w-full text-left">
              <div className="grid grid-cols-2 gap-x-12 px-4 sm:px-6 py-5">
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <ServiceListItemTitle className="font-semibold">
                      {displayName}
                    </ServiceListItemTitle>
                  </div>
                </div>
                <div className="flex justify-between items-center gap-x-4">
                  <div>
                    <ServiceListItemTitle>
                      {operatorDisplayName}
                    </ServiceListItemTitle>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className={classNames('flex-none w-5 h-5 text-gray-600', {
                      'rotate-180 transform': open,
                    })}
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 sm:px-6 py-5 pb-16 bg-white">
              <div
                className={classNames({
                  'grid grid-cols-2 gap-x-12': !!operator,
                })}
              >
                <div className="prose">
                  <RichText>{description}</RichText>
                </div>
                {operator && operatorLogo && (
                  <div className="w-64">
                    <GatsbyImage
                      image={operatorLogo.gatsbyImageData}
                      imgStyle={{
                        objectFit: 'contain',
                        objectPosition: 'center',
                      }}
                      className="h-64 border-8 border-white rounded-lg shadow-md bg-white"
                    />
                    <div className="block mt-6">
                      <Link
                        to={operator.fields.route}
                        className="inline-block w-full px-4 py-2 border border-transparent rounded shadow-sm text-white hover:text-white text-center bg-blue-600 hover:bg-blue-700 text-base font-medium"
                      >
                        {MORE_ABOUT_US}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </li>
  )
}

export default ServiceListItem
