import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import classnames from 'classnames'
import Video from './video/video'

type AssetData = {
  file: {
    contentType: string
    url: string
  }
  gatsbyImageData?: IGatsbyImageData
  title: string
}

const isImage = (asset: AssetData) => {
  if (!asset || !asset.file) {
    return false
  }

  return !!asset.file.contentType.match(/^image\/\S+/);
}

const isVideo = (asset: AssetData) => {
  if (!asset || !asset.file) {
    return false
  }

  return !!asset.file.contentType.match(/^video\/\S+/);
}

const isPDF = (asset: AssetData) => {
  if (!asset || !asset.file) {
    return false
  }

  return !!asset.file.contentType.match(/^application\/pdf$/);
}

type EmbeddedAssetProps = {
  data: AssetData
  fullWidth?: boolean
}

const EmbeddedAsset: React.FC<EmbeddedAssetProps> = ({ data, fullWidth }) => {
  if (isImage(data)) {
    return (
      <GatsbyImage
        image={data.gatsbyImageData}
        className={classnames('mx-auto mb-4', {
          'w-full': fullWidth,
          'sm:float-right w-full max-w-xs sm:w-1/3 sm:max-w-none ml-4':
            !fullWidth,
        })}
        imgStyle={{
          objectFit: 'contain',
        }} />
    );
  }

  if (isVideo(data)) {
    return (
      <div className="relative">
        <Video video={data} inverse={false} />
      </div>
    )
  }

  if (isPDF(data)) {
    return <a href={`https:${data.file.url}`}>{data.title}</a>
  }

  return <></>
}

export default EmbeddedAsset

export const query = graphql`
  fragment Asset on ContentfulAsset {
    gatsbyImageData (
      width: 2560
    )

    file {
      contentType
      url
    }
    contentful_id
    title
  }
`
