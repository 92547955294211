import React, { ReactNode } from 'react'
import CardGrid from './card-grid'
import CardTag from './card-tag'
import CardTagGroup from './card-tag-group'
import CardFallback from './card-fallback'

type CardProps = {
  children: ReactNode
}

const Card = ({ children }: CardProps): JSX.Element => {
  return <div>{children}</div>
}

export default Object.assign(Card, {
  Fallback: CardFallback,
  Grid: CardGrid,
  Tag: CardTag,
  TagGroup: CardTagGroup,
})
