import { useEffect, useState } from 'react'
import { OperatorData } from '../blocks/operator-collection-block'
import { ServiceData } from '../blocks/service-collection-block'
import { EcosystemCategoryData } from '../components/ecosystem-card.tsx/ecosystem-card'

const useOperatorOrServiceSearchCategory = (
  data: ({ node: OperatorData } | { node: ServiceData })[],
  locale: 'en-US' | 'fi-FI'
): {
  groupedCategories: {
    Company: EcosystemCategoryData[]
    Data: EcosystemCategoryData[]
    Partner: EcosystemCategoryData[]
    Service: EcosystemCategoryData[]
  }
  searchCategories: string[]
  onSearchCategoryChange: (name: string) => void
  onSearchCategoriesReset: (category: string) => void
} => {
  const [searchCategories, setSearchCategories] = useState<string[]>([])
  const [groupedCategories, setGroupedCategories] = useState<{
    Company: EcosystemCategoryData[]
    Data: EcosystemCategoryData[]
    Partner: EcosystemCategoryData[]
    Service: EcosystemCategoryData[]
  }>({ Company: [], Data: [], Partner: [], Service: [] })

  useEffect(() => {
    const uniqueCategories = data
      .map(({ node }) => {
        const children =
          'childrenContentfulOperator' in node
            ? node.childrenContentfulOperator
            : node.childrenContentfulService

        return children[0].category
      })
      .flat()
      .filter((category) => category.node_locale === locale)
      .sort((a, b) => {
        if (!a.title) {
          return -1
        }

        if (!b.title) {
          return 1
        }

        return a.title.localeCompare(b.title)
      })
      .filter(function (item, pos, ary) {
        return !pos || item.name !== ary[pos - 1].name
      })

    const result = uniqueCategories.reduce(
      (group, currentCategory) => {
        if (!currentCategory.category) {
          return group
        }

        return {
          ...group,
          [`${currentCategory.category}`]: [
            ...group[currentCategory.category],
            currentCategory,
          ],
        }
      },
      {
        Service: new Array<EcosystemCategoryData>(),
        Company: new Array<EcosystemCategoryData>(),
        Partner: new Array<EcosystemCategoryData>(),
        Data: new Array<EcosystemCategoryData>(),
      }
    )

    setGroupedCategories(result)
  }, [])

  const onSearchCategoryChange = (name: string) => {
    if (searchCategories.find((categoryName) => categoryName === name)) {
      return setSearchCategories(
        searchCategories.filter((categoryName) => categoryName !== name)
      )
    }

    return setSearchCategories([...searchCategories, name])
  }

  const onSearchCategoriesReset = (category: string) => {
    const categories: EcosystemCategoryData[] = groupedCategories[category]

    const filterableSearchCategories = [...searchCategories]

    setSearchCategories(
      filterableSearchCategories.filter(
        (searchCategory) =>
          !categories.map((category) => category.name).includes(searchCategory)
      )
    )
  }

  return {
    groupedCategories,
    searchCategories,
    onSearchCategoryChange,
    onSearchCategoriesReset,
  }
}

export default useOperatorOrServiceSearchCategory
