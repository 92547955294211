import React from 'react'
import Link from '../link'
import { ExternalLink, InternalLink } from '../navbar/navbar'

export type DropdownGroupingItemProps = {
  data: ExternalLink | InternalLink
  inverse: boolean
}

const DesktopDropdownGroupingItem: React.FC<DropdownGroupingItemProps> = ({
  data,
}) => {
  const to = 'url' in data ? data.url : data.fields.route
  const caption = 'text' in data ? data.text : data.menuTitle
  const description =
    'description' in data
      ? data.description?.description
      : 'menuDescription' in data
      ? data.menuDescription?.menuDescription
      : null

  return (
    <li className="flow-root">
      <Link
        to={to}
        className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150 no-underline"
      >
        <div>
          <p className="text-base font-medium text-gray-900">{caption}</p>
          {description && (
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          )}
        </div>
      </Link>
    </li>
  )
}

export default DesktopDropdownGroupingItem
