import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'

type ApplicationData = {
  application: {
    url: string
  }
}

type ShareButtonsProps = { path: string }

const ShareButtons: React.FC<ShareButtonsProps> = ({ path }) => {
  const data: ApplicationData = useStaticQuery(graphql`
    query {
      application {
        url
      }
    }
  `)

  const siteUrl = data.application.url
  const pageUrl = `${siteUrl}${path}`

  return (
    <>
      <LinkedinShareButton url={pageUrl}>
        <LinkedinIcon size="32" />
      </LinkedinShareButton>
      <TwitterShareButton url={pageUrl}>
        <TwitterIcon size="32" />
      </TwitterShareButton>
      <FacebookShareButton url={pageUrl}>
        <FacebookIcon size="32" />
      </FacebookShareButton>
      <EmailShareButton url={pageUrl}>
        <EmailIcon size="32" />
      </EmailShareButton>
    </>
  )
}

export default ShareButtons
