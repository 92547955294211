import { PlayIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

type VideoButtonProps = {
  children: ReactNode
  inverse: boolean
}

const VideoButton: FC<VideoButtonProps> = ({ children, inverse }) => {
  return (
    <div
      className={classNames(
        'relative z-10 inline-flex items-center px-8 py-3 md:px-10 md:py-4 border border-transparent rounded-md shadow text-base font-medium md:text-lg no-underline',
        {
          'text-white bg-blue-600 hover:bg-blue-700': !inverse,
          'text-blue-900 bg-white hover:bg-blue-50': inverse,
        }
      )}
    >
      <PlayIcon className="mr-2 w-6 h-6" />
      <span>{children}</span>
    </div>
  )
}

export default VideoButton
