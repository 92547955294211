import React from 'react'
import classNames from 'classnames'
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'

import EmbeddedAsset from '../embedded-asset'
import RichText from '../rich-text'

type SectionRichTextProps = {
  align?: 'center' | 'left'
  children: RenderRichTextData<ContentfulRichTextGatsbyReference>
  inverse?: boolean
  overflowVisible?: boolean
  size?: 'base' | 'large'
}

const SectionRichText: React.FC<SectionRichTextProps> = ({
  align = 'center',
  children,
  inverse = false,
  overflowVisible = false,
  size = 'large',
}) => {
  const renderNodeOptions = {
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <EmbeddedAsset data={node.data.target} />
    ),
  }

  return (
    <div
      className={classNames(
        'prose max-w-3xl',
        size === 'large' && 'lg:prose-xl xl:prose-xl',
        {
          'prose-inverse': inverse,
          'mx-auto': align === 'center',
          'overflow-hidden': !overflowVisible,
        }
      )}
    >
      <RichText renderNodeOptions={renderNodeOptions}>{children}</RichText>
    </div>
  )
}

export default SectionRichText
