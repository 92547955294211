import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import React, { FC } from 'react'
import { ExternalTarget, InternalTarget } from '../components/call-to-action'
import Section from '../components/section/section'

export type ColumnedSectionBlockData = {
  backgroundImage: {
    gatsbyImageData: GatsbyImage
  }
  backgroundOverlay: boolean
  callToAction: ExternalTarget | InternalTarget
  firstColumn: RenderRichTextData<ContentfulRichTextGatsbyReference>
  heading: string
  htmlElementId: string
  secondColumn: RenderRichTextData<ContentfulRichTextGatsbyReference>
  theme: 'dark' | 'light'
  thirdColumn: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

type ColumnedSectionBlockProps = {
  data: ColumnedSectionBlockData
  index: number
}

const ColumnedSectionBlock: FC<ColumnedSectionBlockProps> = ({
  data,
  index,
}) => {
  const inverse = data.theme === 'dark'
  const isFullWidth = data.secondColumn && data.thirdColumn

  return (
    <Section.Background
      id={data.htmlElementId}
      theme={data.theme}
      index={index}
      image={data.backgroundImage}
      overlay={data.backgroundOverlay}
    >
      <Section.Body>
        <Section.Heading
          inverse={inverse}
          align={isFullWidth ? 'left' : 'center'}
        >
          {data.heading}
        </Section.Heading>
        <div className="flex flex-wrap justify-center mt-8 sm:mt-16 sm:-mx-8">
          <Section.Column data={data.firstColumn} inverse={inverse} />
          {data.secondColumn && (
            <Section.Column data={data.secondColumn} inverse={inverse} />
          )}
          {data.thirdColumn && (
            <Section.Column data={data.thirdColumn} inverse={inverse} />
          )}
        </div>
        {data.callToAction && (
          <Section.CTA cta={data.callToAction} theme="dark" />
        )}
      </Section.Body>
    </Section.Background>
  )
}

export default ColumnedSectionBlock

export const query = graphql`
  fragment ColumnedSectionBlock on ContentfulColumnedSectionBlock {
    backgroundImage {
      gatsbyImageData (
        width: 2560
      )
    }
    backgroundOverlay
    callToAction {
      __typename
      ... on Node {
        ... on ContentfulLink {
          text
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
          menuTitle
        }
        ... on ContentfulYawaveButton {
          source {
            source
          }
        }
      }
    }
    firstColumn {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulAsset {
            ...Asset
          }
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    heading
    id
    secondColumn {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulAsset {
            ...Asset
          }
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    theme
    thirdColumn {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulAsset {
            ...Asset
          }
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
  }
`
