import React from 'react'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import BackgroundImage from './background-image'
import Hero from './hero/hero'
import ShareButtons from './share-buttons'
import { Link } from 'gatsby'

type ArticleHeaderProps = {
  gatsbyImageData?: IGatsbyImageData
  parent: {
    fields: {
      route: string
    }
    menuTitle: string
  }
  path: string
  publishedAt: string
  title: string
}

const ArticleHeader: React.FC<ArticleHeaderProps> = ({
  gatsbyImageData,
  parent,
  path,
  publishedAt,
  title,
}) => {
  return (
    <header className="relative bg-blue px-4 sm:px-8 pt-16 pb-8">
      {gatsbyImageData && (
        <BackgroundImage gatsbyImageData={gatsbyImageData} overlay />
      )}
      <div className="relative z-20 max-w-3xl mx-auto text-white">
        <Link
          className="text-lg text-blue-50 no-underline hover:underline hover:text-white"
          to={parent.fields.route}
        >
          {parent.menuTitle}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline-block h-5 w-5 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </Link>
        &hellip;
        <Hero.Title landing={false} inverse shadow className="mt-2">
          {title}
        </Hero.Title>
        <div className="flex items-center mt-8">
          <ShareButtons path={path} />
          <div className="ml-6">{publishedAt}</div>
        </div>
      </div>
    </header>
  )
}

export default ArticleHeader
