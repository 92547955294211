import classNames from 'classnames'
import React, { FC, InputHTMLAttributes } from 'react'

type FormSubmitProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  inverse: boolean
}

const FormSubmit: FC<FormSubmitProps> = (props) => {
  const { inverse } = props

  return (
    <input
      type="submit"
      className={classNames(
        'inline-block px-8 py-3 md:px-10 md:py-4 border border-transparent rounded-md shadow text-base font-medium md:text-lg no-underline cursor-pointer',
        {
          'text-white bg-blue-600 hover:bg-blue-700': !inverse,
          'text-blue-900 bg-white hover:bg-blue-50': inverse,
        }
      )}
      {...props}
    />
  )
}

export default FormSubmit
