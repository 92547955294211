import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { utils } from '@wunderdogsw/finbb-dms-theme'

const HeroMedia = ({ data }) => (
  <div className="sm:flex-none w-full sm:w-1/2 mt-8 sm:mt-0 sm:ml-16">
    {utils.isVideo(data.file.contentType) ? (
      <video controls>
        <source src={data.file.url} type={data.file.contentType} />
      </video>
    ) : (
      <GatsbyImage image={data.gatsbyImageData} />
    )}
  </div>
)

export default HeroMedia
