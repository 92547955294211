import { graphql } from 'gatsby'
import React from 'react'
import { ExternalLink, InternalLink } from '../navbar/navbar'
import DesktopDropdownGroupingItem from './desktop-dropdown-grouping-item'

export type DropdownGroupingData = {
  id: string
  links: (ExternalLink | InternalLink)[]
  title?: string
}

export type DropdownGroupingProps = {
  inverse: boolean
  links: (ExternalLink | InternalLink)[]
  title?: string
}

const DesktopDropdownGrouping: React.FC<DropdownGroupingProps> = ({
  links,
  title,
}) => {
  return (
    <div className="w-60">
      {title && (
        <h3 className="mb-5 text-sm font-medium tracking-wide text-gray-500 uppercase">
          {title}
        </h3>
      )}
      <ul className="space-y-6">
        {links.map((item) => (
          <DesktopDropdownGroupingItem
            key={item.id}
            data={item}
            inverse={false}
          />
        ))}
      </ul>
    </div>
  )
}

export default DesktopDropdownGrouping

export const query = graphql`
  fragment DropdownGrouping on ContentfulDropdownGrouping {
    id
    links {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          ...ArticlePage
        }
        ... on ContentfulLink {
          ...Link
        }
        ... on ContentfulPage {
          ...Page
        }
      }
    }
    title
  }
`
