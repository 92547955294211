import React, { FC } from 'react'
import useInnerHTML from '../../hooks/use-inner-html'

type AnnouncementTitleProps = {
  data: string
}

const AnnouncementTitle: FC<AnnouncementTitleProps> = ({ data }) => {
  const [innerHTML, innerNodes] = useInnerHTML(data)

  return (
    <h3 className="text-blue-50 text-base font-bold" {...innerHTML}>
      {innerNodes}
    </h3>
  )
}

export default AnnouncementTitle
