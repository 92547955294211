import React, { FC, ReactNode } from 'react'

type MobileNavigationMenuListItemProps = {
  children: ReactNode
}

const MobileNavigationMenuListItem: FC<MobileNavigationMenuListItemProps> = ({
  children,
}) => {
  return <li>{children}</li>
}

export default MobileNavigationMenuListItem
