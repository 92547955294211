import React, { FC } from 'react'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { ExternalTarget } from '../components/call-to-action'
import Section from '../components/section/section'
import { graphql, useStaticQuery } from 'gatsby'
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import ServiceCard from '../components/service-card/service-card'
import Search from '../components/search/search'
import { ContactData } from '../components/contact/contact'
import { EcosystemCategoryData } from '../components/ecosystem-card.tsx/ecosystem-card'
import { SectionBlockData } from './section-block'
import Card from '../components/card/card'
import useFilteredOperatorOrServiceData from '../hooks/use-filtered-operator-or-service-data'
import useOperatorOrServiceSearchQuery from '../hooks/use-operator-or-service-search-query'
import useOperatorOrServiceSearchCategory from '../hooks/use-operator-or-service-search-category'
import { BACKGROUND_THEME } from '../common/background-theme'

export type ServiceCollectionBlockData = SectionBlockData & { cc: string }

type ServiceCollectionBlockProps = {
  data: ServiceCollectionBlockData
  index: number
  locale: 'en-US' | 'fi-FI'
}

type ServiceOperatorData = {
  coverImage: {
    gatsbyImageData: IGatsbyImageData
  }
  displayName: string
  fields?: {
    route: string
  }
  id: string
  logo: {
    gatsbyImageData: IGatsbyImageData
  }
  website: string
}

export type ChildrenContentfulService = {
  category?: EcosystemCategoryData[]
  contacts: Omit<ContactData, 'image'>[]
  description: RenderRichTextData<ContentfulRichTextGatsbyReference>
  displayName: string
  id: string
  metaDescription: {
    metaDescription: string
  }
  node_locale: string
  operator?: ServiceOperatorData[]
  referenceLinks?: ExternalTarget[]
  website: string
}

export type ServiceData = {
  childrenContentfulService: ChildrenContentfulService[]
  id: string
}

export type ServicesData = {
  edges: {
    node: ServiceData
  }[]
}

const ServiceCollectionBlock: FC<ServiceCollectionBlockProps> = ({
  data,
  index,
  locale,
}) => {
  const {
    services: { edges: services },
  }: { services: ServicesData } = useStaticQuery(graphql`
    query {
      services: allService(
        sort: {
          fields: [childrenContentfulService___fields___sortableDisplayName]
          order: ASC
        }
      ) {
        edges {
          node {
            childrenContentfulService {
              category {
                ... on Node {
                  ... on ContentfulEcosystemCategory {
                    ...EcosystemCategory
                  }
                }
              }
              contacts {
                displayName
                email
                id
                phone
                responsibility
                title
              }
              displayName
              id
              description {
                raw
                references {
                  __typename
                  ... on Node {
                    ... on ContentfulSuperscriptInline {
                      ...SuperscriptInline
                    }
                  }
                }
              }
              metaDescription {
                metaDescription
              }
              node_locale
              operator {
                coverImage {
                  gatsbyImageData(width: 512)
                }
                displayName
                fields {
                  route
                }
                id
                logo {
                  gatsbyImageData(width: 256)
                }
                website
              }
              referenceLinks {
                ... on Node {
                  ... on ContentfulLink {
                    ...Link
                  }
                }
              }
              website
            }
            id
          }
        }
      }
    }
  `)

  const { searchQuery, onSearchQueryChange } = useOperatorOrServiceSearchQuery()

  const {
    groupedCategories,
    searchCategories,
    onSearchCategoryChange,
    onSearchCategoriesReset,
  } = useOperatorOrServiceSearchCategory(services, locale)

  const filteredData = useFilteredOperatorOrServiceData(
    services,
    locale,
    'Service',
    searchQuery,
    searchCategories
  ) as ServiceData[]

  const {
    backgroundImage,
    backgroundOverlay,
    body,
    callToActionCaption,
    callToActionTarget,
    cc,
    htmlElementId,
    title,
  } = data

  const theme = data.theme || BACKGROUND_THEME.DEFAULT
  const inverse = theme === 'dark'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay || false}
    >
      <Section.Body>
        {title && (
          <div className="max-w-3xl mx-auto">
            <Section.Heading align="left" inverse={inverse}>
              {title}
            </Section.Heading>
          </div>
        )}
        {body && (
          <div className="">
            <Section.RichText size="base" align="center" inverse={inverse}>
              {body}
            </Section.RichText>
          </div>
        )}
        <Search
          inverse={inverse}
          categoryGroups={Object.keys(groupedCategories).map((key) => {
            if (groupedCategories[key].length === 0) {
              return null
            }

            return (
              <Search.CategoryGroup
                key={key}
                title={key}
                categories={groupedCategories[key]}
                selectedCategories={searchCategories}
                onCategoryClick={onSearchCategoryChange}
                onClearSelection={() => onSearchCategoriesReset(key)}
                inverse={inverse}
              />
            )
          })}
          onQueryChange={onSearchQueryChange}
        />
        <Card.Grid>
          {filteredData.map((service) => (
            <ServiceCard
              key={service.id}
              cc={cc}
              data={service}
              locale={locale}
            />
          ))}
        </Card.Grid>
        {callToActionCaption && callToActionTarget && (
          <Section.CallToAction
            caption={callToActionCaption}
            target={callToActionTarget}
            variant={inverse ? 'secondary' : 'primary'}
          />
        )}
      </Section.Body>
    </Section.Background>
  )
}

export default ServiceCollectionBlock

export const query = graphql`
  fragment ServiceCollectionBlock on ContentfulServiceCollectionBlock {
    backgroundImage {
      gatsbyImageData(width: 2560)
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    cc
    htmlElementId
    id
    theme
    title
  }
`
