import React, { FC, ReactNode } from 'react'

type SectionBodyProps = {
  children: ReactNode
  id?: string
}

const SectionBody: FC<SectionBodyProps> = ({ children, id }) => {
  return (
    <div id={id} className="relative z-10 container mx-auto px-4 py-16 sm:px-8">
      {children}
    </div>
  )
}

export default SectionBody
