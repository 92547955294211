import React, { FC } from 'react'
import CardTag from './card-tag'

type CardTagGroupProps = {
  tags: string[]
  title: string
}

const CardTagGroup: FC<CardTagGroupProps> = ({ tags, title }) => {
  return (
    <div className="my-2">
      <h4 className="uppercase tracking-wider text-sm text-gray-500">
        {title}
      </h4>
      <div className="flex flex-wrap -mx-1">
        {tags.map((tag) => (
          <CardTag key={tag}>{tag}</CardTag>
        ))}
      </div>
    </div>
  )
}

export default CardTagGroup
