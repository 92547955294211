import React, { FC } from 'react'
import { ExternalTarget, InternalTarget } from '../call-to-action'
import AnnouncementBody from './announcement-body'
import AnnouncementLink from './announcement-link'
import AnnouncementTitle from './announcement-title'

type AnnouncementProps = {
  body?: {
    raw: string
    references: any[]
  }
  link?: {
    text: string
    to: string | ExternalTarget | InternalTarget
  }
  title: string
}

const Announcement: FC<AnnouncementProps> = ({ body, link, title }) => {
  return (
    <div className="bg-slate-900">
      <div className="md:flex justify-between items-center mx-auto px-4 sm:px-8 py-1 text-center">
        <div className="flex justify-center my-1">
          <AnnouncementTitle data={title} />
          {body && <AnnouncementBody data={body} />}
        </div>
        <AnnouncementLink text={link.text} to={link.to} />
      </div>
    </div>
  )
}

export default Announcement
