import React, { FC, PropsWithChildren } from 'react'
import FormCheckbox from './form-checkbox'
import FormCheckboxWithLabel from './form-checkbox-with-label'
import FormInput from './form-input'
import FormLabel from './form-label'
import FormSelect from './form-select'
import FormSubmit from './form-submit'
import FormTextarea from './form-textarea'
import FormLegend from './form-legend'

type FormComponent = FC<PropsWithChildren> & {
  Checkbox: typeof FormCheckbox
  CheckboxWithLabel: typeof FormCheckboxWithLabel
  Input: typeof FormInput
  Label: typeof FormLabel
  Legend: typeof FormLegend
  Select: typeof FormSelect
  Submit: typeof FormSubmit
  Textarea: typeof FormTextarea
}

const Form: FC<PropsWithChildren> = ({ children }) => <form>{children}</form>

const FormWithProps = Form as FormComponent

FormWithProps.Checkbox = FormCheckbox
FormWithProps.CheckboxWithLabel = FormCheckboxWithLabel
FormWithProps.Input = FormInput
FormWithProps.Label = FormLabel
FormWithProps.Legend = FormLegend
FormWithProps.Select = FormSelect
FormWithProps.Submit = FormSubmit
FormWithProps.Textarea = FormTextarea

export default FormWithProps
