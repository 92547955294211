import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import React, { FC } from 'react'
import useDropdownCallToActions from '../../hooks/use-dropdown-call-to-actions'
import useDropdownItems from '../../hooks/use-dropdown-items'
import { DropdownProps } from './desktop-dropdown'
import MobileDropdownCallToAction from './mobile-dropdown-call-to-action'
import MobileDropdownItem from './mobile-dropdown-item'

const MobileDropdown: FC<DropdownProps> = ({ data, inverse }) => {
  const items = useDropdownItems(data.items)
  const callToActions = useDropdownCallToActions(data)

  const { caption } = data

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames(
              'flex items-center justify-between w-full px-2 sm:px-6 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset',
              {
                'text-blue-100 hover:text-white hover:bg-blue-700 focus:ring-white':
                  inverse,
                'text-blue-700 hover:text-blue-800 hover:bg-blue-100 focus:ring-blue-600':
                  !inverse,
              }
            )}
          >
            <div>{caption}</div>
            {/* TODO: Move to localization file */}
            <span className="sr-only">Open main menu</span>
            <ChevronDownIcon className="block h-5 w-5" aria-hidden="true" />
          </Disclosure.Button>
          <Disclosure.Panel className="xl:hidden">
            <ul className="px-2">
              {items.map((item) => {
                const id = 'id' in item ? item.id : item[0].id

                return (
                  <MobileDropdownItem key={id} data={item} inverse={inverse} />
                )
              })}
            </ul>
            {callToActions.length > 0 && (
              <div className="mt-4 mb-8 px-4 sm:px-6 space-y-4">
                {callToActions.map((callToAction) => (
                  <MobileDropdownCallToAction
                    key={callToAction.caption}
                    caption={callToAction.caption}
                    target={callToAction.target}
                    variant={callToAction.variant}
                  />
                ))}
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default MobileDropdown
