import React from 'react'
import classNames from 'classnames'

import useInnerHTML from '../../hooks/use-inner-html'

const TEXT_ALIGN = {
  center: 'text-center',
  left: 'text-left',
  right: 'text-right',
}

type SectionHeadingProps = {
  align?: 'center' | 'left' | 'right'
  children: React.ReactNode
  className?: string
  inverse: boolean
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const SectionHeading: React.FC<SectionHeadingProps> = ({
  align = 'center',
  children,
  className,
  inverse,
  variant = 'h2',
}) => {
  const [innerHTML, innerNodes] = useInnerHTML(children)
  const HeadingVariant = variant

  const textAlign = TEXT_ALIGN[align]

  return (
    <HeadingVariant
      className={classNames(
        `mb-4 w-full text-3xl font-bold`,
        textAlign,
        className,
        { 'text-white': inverse, 'text-gray-900': !inverse }
      )}
      {...innerHTML}
    >
      {innerNodes}
    </HeadingVariant>
  )
}

export default SectionHeading
