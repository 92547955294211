import React from 'react'
import { Container, Hero } from '@wunderdogsw/finbb-dms-theme'

import LandingHeroMedia from './landing-hero-media'

const LandingHero = ({ heading, media }) => (
  <header className="bg-white">
    <Container className="sm:flex sm:items-center sm:justify-between mt-8 mb-16 sm:my-24">
      <Hero.Title className="text-center sm:text-left text-blue">
        {heading}
      </Hero.Title>
      {media && <LandingHeroMedia data={media} />}
    </Container>
  </header>
)

export default LandingHero
