import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@wunderdogsw/finbb-dms-theme'

import ArrowIcon from '../../assets/inline-svg/arrow.inline.svg'
import { isPage, isYawaveButton } from '../../common/content-types'
import { isDarkTheme } from '../../common/background-theme'

const Wrapper = ({ children, className, inverse, html }) => {
  return (
    <div
      className={classnames(className ? className : 'my-8 text-center', {
        'btn-wrapper-dark': inverse,
        'btn-wrapper-light': !inverse,
      })}
      dangerouslySetInnerHTML={html && { __html: html }}
    >
      {children}
    </div>
  )
}

const SectionCta = ({ cta, className, theme, arrow }) => {
  const isWrapperDark = isDarkTheme(theme)

  if (isYawaveButton(cta)) {
    return (
      <Wrapper
        className={className}
        inverse={isWrapperDark}
        html={cta.source.source}
      />
    )
  }

  const to = isPage(cta) ? cta.fields.route : cta.url || cta.target
  const text = isPage(cta) ? cta.menuTitle : cta.text

  return (
    <Wrapper className={className} inverse={isWrapperDark}>
      <Link
        to={to}
        className={classnames('btn inline-block no-underline text-xl', {
          'border-white text-white': isWrapperDark,
          'border-blue text-blue': !isWrapperDark,
        })}
      >
        <span dangerouslySetInnerHTML={{ __html: text }} />
        {arrow && (
          <ArrowIcon
            className={classnames('fill-current inline mx-2', {
              'text-white': isWrapperDark,
              'text-blue': !isWrapperDark,
            })}
          />
        )}
      </Link>
    </Wrapper>
  )
}

SectionCta.propTypes = {
  cta: PropTypes.object.isRequired,
  className: PropTypes.string,
  theme: PropTypes.string.isRequired,
  arrow: PropTypes.bool,
}

export default SectionCta
