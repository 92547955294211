import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

type ServiceListHeaderItemProps = {
  active: boolean
  children: ReactNode
  direction: 'asc' | 'desc'
  onClick: () => void
}

const ServiceListHeaderItem: FC<ServiceListHeaderItemProps> = ({
  active,
  children,
  direction,
  onClick,
}) => {
  return (
    <button onClick={onClick} className="flex items-center gap-x-1">
      <span className={classNames({ 'font-semibold': active })}>
        {children}
      </span>
      {direction === 'asc' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5"
        >
          <path
            fillRule="evenodd"
            d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
            clipRule="evenodd"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5"
        >
          <path
            fillRule="evenodd"
            d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </button>
  )
}

export default ServiceListHeaderItem
