import React, { FC } from 'react'
import { DropdownData } from '../dropdown/desktop-dropdown'
import MobileDropdown from '../dropdown/mobile-dropdown'
import { ExternalLink, InternalLink } from '../navbar/navbar'
import MobileNavigationMenuButton from './mobile-navigation-menu-button'
import MobileNavigationMenuListItem from './mobile-navigation-menu-list-item'

export type MobileNavigationMenuItemData =
  | DropdownData
  | ExternalLink
  | InternalLink

type MobileNavigationMenuItemProps = {
  data: MobileNavigationMenuItemData
  inverse: boolean
}

const MobileNavigationMenuItem: FC<MobileNavigationMenuItemProps> = ({
  data,
  inverse,
}) => {
  if ('items' in data) {
    return (
      <MobileNavigationMenuListItem>
        <MobileDropdown data={data} inverse={inverse} />
      </MobileNavigationMenuListItem>
    )
  }

  const to = 'url' in data ? data.url : data.fields.route
  const caption = 'text' in data ? data.text : data.menuTitle

  return (
    <MobileNavigationMenuListItem>
      <MobileNavigationMenuButton to={to} inverse={inverse}>
        {caption}
      </MobileNavigationMenuButton>
    </MobileNavigationMenuListItem>
  )
}

export default MobileNavigationMenuItem
