import React from 'react'
import CallToAction, { CallToActionProps } from '../call-to-action'

type SectionCallToActionProps = Omit<CallToActionProps, 'size'>

const SectionCallToAction: React.FC<SectionCallToActionProps> = ({
  caption,
  target,
  variant = 'primary',
}) => {
  return (
    <div className="my-8 text-center">
      <CallToAction
        size="large"
        caption={caption}
        target={target}
        variant={variant}
      />
    </div>
  )
}

export default SectionCallToAction
