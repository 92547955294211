import { BLOCKS } from '@contentful/rich-text-types'
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import React, { FC } from 'react'
import RichText from '../rich-text'

type RichTextWithoutBlocksProps = {
  data: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

const RichTextWithoutBlocks: FC<RichTextWithoutBlocksProps> = ({ data }) => {
  return (
    <RichText
      renderNodeOptions={{
        [BLOCKS.PARAGRAPH]: (_, children) => <span>{children}</span>,
      }}
    >
      {data}
    </RichText>
  )
}

export default RichTextWithoutBlocks
