import React, { FC, ReactNode } from 'react'
import useInnerHTML from '../../hooks/use-inner-html'

type EcosystemCardDialogMetaProps = {
  children: ReactNode
}

const EcosystemCardDialogMeta: FC<EcosystemCardDialogMetaProps> = ({
  children,
}) => {
  const [innerHTML, innerNodes] = useInnerHTML(children)

  return (
    <p className="text-sm font-medium text-gray-600" {...innerHTML}>
      {innerNodes}
    </p>
  )
}

export default EcosystemCardDialogMeta
