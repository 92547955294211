import React from 'react'
import { OperatorData } from '../../blocks/operator-collection-block'
import EcosystemCard from '../ecosystem-card.tsx/ecosystem-card'
import EcosystemCardLink from '../ecosystem-card.tsx/ecosystem-card-link'
import EcosystemCardSection from '../ecosystem-card.tsx/ecosystem-card-section'
import RichText from '../rich-text'
import { ServiceData } from '../../blocks/service-collection-block'

// TODO: Move to localization files
const WEBSITE = 'Website'
const SERVICE_LINKS = 'Service links'
const REFERENCE_LINKS = 'References'
const OUR_SERVICES = 'Our services'
const PARTNER_LINKS = 'Affiliate partners'
const SERVICES = 'Services'

type OperatorCardProps = {
  cc: string
  data: OperatorData | ServiceData
  locale: string
}

const OperatorCard: React.FC<OperatorCardProps> = ({ cc, data, locale }) => {
  if (!('childrenContentfulOperator' in data)) {
    return <></>
  }

  const { childrenContentfulOperator } = data

  const {
    category,
    contacts,
    coverImage,
    description,
    displayName,
    fields: { route },
    logo,
    metaDescription,
    partnerLinks,
    referenceLinks,
    serviceLinks,
    services,
    website,
  } = childrenContentfulOperator[0]

  const linkGroups = (
    <>
      <EcosystemCard.LinkGroup title={WEBSITE} links={[website]} />
      {serviceLinks && (
        <EcosystemCard.LinkGroup title={SERVICE_LINKS} links={serviceLinks} />
      )}
      {referenceLinks && (
        <EcosystemCard.LinkGroup
          title={REFERENCE_LINKS}
          links={referenceLinks}
        />
      )}
      {partnerLinks && (
        <EcosystemCard.LinkGroup title={PARTNER_LINKS} links={partnerLinks} />
      )}
    </>
  )

  return (
    <EcosystemCard
      title={displayName}
      body={description}
      lead={metaDescription.metaDescription}
      image={coverImage}
      logo={logo}
      sections={
        <>
          <h4 className="mt-6 text-lg font-semibold text-gray-900">
            {SERVICES}
          </h4>
          <div className="space-y-2">
            {services.map((service) => (
              <EcosystemCardSection
                key={service.id}
                title={service.displayName}
              >
                <div className="prose mt-2">
                  <RichText>{service.description}</RichText>
                </div>
                {service.website && (
                  <>
                    <h6 className="mt-3 text-sm font-semibold text-gray-900">
                      {WEBSITE}
                    </h6>
                    <div className="space-y-2 text-sm">
                      <EcosystemCardLink to={service.website}>
                        {service.website}
                      </EcosystemCardLink>
                    </div>
                  </>
                )}
                {service.referenceLinks?.length > 0 && (
                  <>
                    <h6 className="mt-3 text-sm font-semibold text-gray-900">
                      {REFERENCE_LINKS}
                    </h6>
                    <div className="space-y-2 text-sm">
                      {service.referenceLinks.map((link) => (
                        <EcosystemCardLink key={link.id} to={link.url}>
                          {link.text}
                        </EcosystemCardLink>
                      ))}
                    </div>
                  </>
                )}
              </EcosystemCardSection>
            ))}
          </div>
        </>
      }
      linkGroups={linkGroups}
      contacts={contacts}
      cc={cc}
      to={route}
      callToActionCaption={OUR_SERVICES}
    />
  )
}

export default OperatorCard
