import classNames from 'classnames'
import React, { FC } from 'react'
import { ExternalTarget, InternalTarget } from '../call-to-action'
import Link from '../link'

export type DropdownCallToAction = {
  caption: string
  target: ExternalTarget | InternalTarget | string
  variant: 'primary' | 'secondary'
}

type DesktopDropdownCallToActionProps = DropdownCallToAction

const DesktopDropdownCallToAction: FC<DesktopDropdownCallToActionProps> = ({
  caption,
  target,
  variant,
}) => {
  return (
    <div className="flow-root">
      <Link
        to={target}
        className={classNames(
          'flex items-center -m-3 px-4 py-2 border rounded shadow-sm text-base font-medium transition ease-in-out duration-150',
          {
            'border-blue-600 hover:border-blue-800 text-blue-800 hover:text-blue-900':
              variant === 'secondary',
            'border-transparent text-white hover:text-white bg-blue-600 hover:bg-blue-700':
              variant === 'primary',
          }
        )}
      >
        {caption}
      </Link>
    </div>
  )
}

export default DesktopDropdownCallToAction
