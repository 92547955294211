import React from 'react'
import { ServiceData } from '../../blocks/service-collection-block'
import EcosystemCard from '../ecosystem-card.tsx/ecosystem-card'
import { OperatorData } from '../../blocks/operator-collection-block'

// TODO: Move to localization files
const WEBSITE = 'Website'
const REFERENCE_LINKS = 'References'
const OPERATOR = 'Operator'

type ServiceCardProps = {
  cc: string
  data: OperatorData | ServiceData
  locale: string
}

const ServiceCard: React.FC<ServiceCardProps> = ({ cc, data, locale }) => {
  if (!('childrenContentfulService' in data)) {
    return <></>
  }

  const { childrenContentfulService } = data

  const {
    category,
    contacts,
    description,
    displayName,
    metaDescription,
    referenceLinks,
    website,
  } = childrenContentfulService[0]

  const operator = childrenContentfulService[0].operator || []

  const linkGroups = (
    <>
      <EcosystemCard.LinkGroup title={WEBSITE} links={[website]} />
      {referenceLinks && (
        <>
          <EcosystemCard.LinkGroup
            title={OPERATOR}
            links={[
              {
                id: operator[0]?.id,
                text: operator[0]?.displayName,
                url: operator[0]?.website,
              },
            ]}
          />
          <EcosystemCard.LinkGroup
            title={REFERENCE_LINKS}
            links={referenceLinks}
          />
        </>
      )}
    </>
  )

  return (
    <EcosystemCard
      title={displayName}
      body={description}
      lead={metaDescription.metaDescription}
      meta={operator[0]?.displayName}
      categories={category
        .filter((category) => category.node_locale === locale)
        .sort((a, b) => a.title.localeCompare(b.title))}
      image={operator[0]?.coverImage}
      logo={operator[0]?.logo}
      linkGroups={linkGroups}
      contacts={contacts}
      cc={cc}
    />
  )
}

export default ServiceCard
