import React, { FC, ReactNode } from 'react'
import FormLabel from './form-label'
import FormError from './form-error'
import { FormInputProps } from './form-input'
import FormCheckbox from './form-checkbox'

type FormCheckboxWithLabelProps = FormInputProps & {
  text: ReactNode
}

const FormCheckboxWithLabel: FC<FormCheckboxWithLabelProps> = ({
  error,
  inverse,
  name,
  text,
  ...other
}) => {
  if (!name) {
    return <></>
  }

  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <FormCheckbox name={name} error={error} inverse={inverse} {...other} />
      </div>
      <div className="ml-3">
        <FormLabel htmlFor={name} inverse={inverse} text={text} />
        <FormError error={error} inverse={inverse} />
      </div>
    </div>
  )
}

export default FormCheckboxWithLabel
