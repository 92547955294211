import { Popover, Transition } from '@headlessui/react'
import { UserCircleIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import React, { Fragment } from 'react'
import DesktopDropdownItem, {
  DropdownItemData,
} from '../dropdown/desktop-dropdown-item'

export type UserMenuProps = {
  inverse: boolean
  items: DropdownItemData[]
}

const DesktopUserMenu: React.FC<UserMenuProps> = ({ inverse, items }) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <div className="flex items-center">
            <Popover.Button
              className={classNames(
                open ? 'text-white' : 'text-gray-200',
                'group bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none'
              )}
            >
              <UserCircleIcon
                className={classNames(
                  open
                    ? { 'text-blue-100': inverse, 'text-blue-800': !inverse }
                    : { 'text-blue-200': inverse, 'text-blue-900': !inverse },
                  'block h-7 w-7',
                  {
                    'group-hover:text-blue-100': inverse,
                    'group-hover:text-blue-800': !inverse,
                  }
                )}
                aria-hidden="true"
              />
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-30 right-0 -ml-4 mt-3 transform w-screen max-w-sm">
              <div className="rounded-lg shadow-lg">
                <div className="relative rounded-lg bg-white px-5 py-6 sm:p-8 space-y-6">
                  {items.map((item) => (
                    <DesktopDropdownItem
                      key={item.id}
                      data={item}
                      inverse={inverse}
                    />
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default DesktopUserMenu
