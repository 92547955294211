import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC } from 'react'
import Video from '../video/video'

type SectionVideoProps = {
  inverse: boolean
  play?: string
  thumbnail?: {
    gatsbyImageData: IGatsbyImageData
  }
  video: {
    file: {
      contentType: string
      url: string
    }
  }
}

const SectionVideo: FC<SectionVideoProps> = ({
  inverse,
  play,
  thumbnail,
  video,
}) => {
  return (
    <div className="relative max-w-3xl xl:max-w-4xl mx-auto my-6 sm:my-12">
      <Video
        inverse={inverse}
        video={video}
        thumbnail={thumbnail}
        play={play}
      />
    </div>
  )
}

export default SectionVideo
