import React from 'react'
import { IntlContextConsumer, changeLocale, useIntl } from 'gatsby-plugin-intl'

import LanguageSwitchButton from './language-switch-button'
import { SectionTheme } from '../section/section'

type LanguageSwitchProps = {
  defaultBackgroundTheme: SectionTheme
  scrollAtTop: boolean
}

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({
  defaultBackgroundTheme,
  scrollAtTop,
}) => {
  const intl = useIntl()

  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => {
        if (languages.length > 1) {
          return (
            <div className="my-6 xl:my-0 mx-6 xl:mx-4 space-x-2">
              {languages.map((language) => (
                <LanguageSwitchButton
                  key={language}
                  onClick={() => changeLocale(language)}
                  active={language === currentLocale}
                  defaultBackgroundTheme={defaultBackgroundTheme}
                  scrollAtTop={scrollAtTop}
                >
                  {intl.formatMessage({ id: `supportedLanguages.${language}` })}
                </LanguageSwitchButton>
              ))}
            </div>
          )
        }
      }}
    </IntlContextConsumer>
  )
}

export default LanguageSwitch
