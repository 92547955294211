import React, { FC } from 'react'
import MobileNavigationMenuButton from '../navigation-menu/mobile-navigation-menu-button'
import { DropdownItemProps } from './desktop-dropdown-item'
import MobileDropdownGrouping from './mobile-dropdown-grouping'

const MobileDropdownItem: FC<DropdownItemProps> = ({ data, inverse }) => {
  if ('links' in data || Array.isArray(data)) {
    const links = 'links' in data ? data.links : data
    const title = 'title' in data ? data.title : null

    return (
      <MobileDropdownGrouping links={links} title={title} inverse={inverse} />
    )
  }

  const caption = 'text' in data ? data.text : data.menuTitle

  return (
    <li>
      <MobileNavigationMenuButton to={data} inverse={inverse}>
        {caption}
      </MobileNavigationMenuButton>
    </li>
  )
}

export default MobileDropdownItem
