import classNames from 'classnames'
import React from 'react'
import useInnerHTML from '../../hooks/use-inner-html'

type HeroTitleProps = {
  children: React.ReactNode
  className?: string
  inverse: boolean
  landing: boolean
  shadow?: boolean
}

const HeroTitle: React.FC<HeroTitleProps> = ({
  children,
  inverse,
  shadow = false,
  landing,
}) => {
  const [innerHTML, innerNodes] = useInnerHTML(children)

  return (
    <h1
      className={classNames('tracking-tight font-bold', {
        'text-4xl md:text-5xl': landing,
        'max-w-3xl mx-auto text-3xl md:text-4xl': !landing,
        'text-white': inverse,
        'text-gray-900': !inverse,
      })}
      style={{ textShadow: shadow ? '2px 4px 3px rgba(0,0,0,0.3)' : '' }}
      {...innerHTML}
    >
      {innerNodes}
    </h1>
  )
}

export default HeroTitle
