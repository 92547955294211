import { PlayIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC, useRef, useState } from 'react'
import BackgroundImage from '../background-image'
import VideoButton from './video-button'

type VideoProps = {
  inverse: boolean
  play?: string
  thumbnail?: {
    gatsbyImageData: IGatsbyImageData
  }
  video: {
    file: {
      contentType: string
      url: string
    }
  }
}

const Video: FC<VideoProps> = ({ inverse, play, thumbnail, video }) => {
  const {
    file: { contentType, url },
  } = video
  const videoRef = useRef<HTMLVideoElement>(null)
  const [started, setStarted] = useState<boolean>(false)

  const handleClick = () => {
    setStarted(true)
    videoRef.current.play()
  }

  const httpsUrl = `https:${url}`

  return (
    <>
      <video controls ref={videoRef} className="w-full shadow-lg">
        <source src={httpsUrl} type={contentType} />
      </video>
      <button
        onClick={handleClick}
        className={classNames(
          'absolute top-0 left-0 flex justify-center items-center w-full h-full',
          started && 'hidden',
          {
            'text-blue-600 hover:text-blue-700 bg-gradient-to-r from-white to-gray-100':
              inverse && !thumbnail,
            'text-white hover:text-blue-50 bg-gradient-to-r from-blue-900 to-blue-500':
              !inverse || thumbnail,
          }
        )}
      >
        {!thumbnail && 'No thumbnail'}
        {thumbnail && (
          <BackgroundImage gatsbyImageData={thumbnail.gatsbyImageData} />
        )}
        {play ? (
          <VideoButton inverse={!!thumbnail || !inverse}>{play}</VideoButton>
        ) : (
          <PlayIcon className="relative z-10 w-14 h-14 sm:w-20 sm:h-20 lg:w-24 lg:h-24" />
        )}
      </button>
    </>
  )
}

export default Video
