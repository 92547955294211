import React from 'react'
import { graphql } from 'gatsby'
import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import renderInline from '../common/render-inline'
import EmbeddedAsset from './embedded-asset'
import IFrameBlock from '../blocks/iframe-block'
import CallToActionButtonBlock from '../blocks/call-to-action-button-block'
import VideoBlock from '../blocks/rich-text/video-block'

const renderTextWithLineBreaks = (text) =>
  text.split('\n').reduce((children, textSegment, index) => {
    return [...children, index > 0 && <br key={index} />, textSegment]
  }, [])

type RenderNodeFunction = (
  node: any,
  children?: React.ReactNode
) => React.ReactNode

type RenderNodeOptions = {
  [key: string]: RenderNodeFunction
}

type RichTextProps = {
  children: RenderRichTextData<ContentfulRichTextGatsbyReference>
  renderNodeOptions?: RenderNodeOptions
}

const RichText: React.FC<RichTextProps> = ({ children, renderNodeOptions }) => {
  const defaultRenderNodeOptions: RenderNodeOptions = {
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <EmbeddedAsset data={node.data.target} />
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { target } = node.data

      if (target && target.__typename === 'ContentfulCallToActionButtonBlock') {
        return (
          <CallToActionButtonBlock
            caption={target.caption}
            target={target.target}
          />
        )
      }

      if (target && target.__typename === 'ContentfulIFrameBlock') {
        return <IFrameBlock html={target.htmlCode?.htmlCode} />
      }

      if (target && target.__typename === 'ContentfulVideoBlock') {
        return (
          <VideoBlock
            video={target.video}
            videoThumbnail={target.videoThumbnail}
            videoCallToActionCaption={target.videoCallToActionCaption}
            youTubeId={target.youTubeId}
          />
        )
      }

      return null
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [INLINES.EMBEDDED_ENTRY]: (node) => renderInline(node.data.target),
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} target="_blank" rel="noreferrer">
        {children}
      </a>
    ),
  }

  const options = {
    renderNode: { ...defaultRenderNodeOptions, ...renderNodeOptions },
    renderText: (text) => renderTextWithLineBreaks(text),
  }
  return <>{children && renderRichText(children, options)}</>
}

export default RichText

export const query = graphql`
  fragment RichTextReferences on Node {
    ... on ContentfulAsset {
      ...Asset
    }
    ... on ContentfulCallToActionButtonBlock {
      ...CallToActionButtonBlock
    }
    ... on ContentfulIFrameBlock {
      ...IFrameBlock
    }
    ... on ContentfulVideoBlock {
      ...VideoBlock
    }
    ... on ContentfulSuperscriptInline {
      ...SuperscriptInline
    }
  }
`
