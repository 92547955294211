import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { FC } from 'react'
import { Section } from '../..'
import Contact, { ContactData } from '../components/contact/contact'
import { SectionBlockData } from './section-block'

export type OrganizationBlockData = SectionBlockData & {
  address?: string[]
  contacts?: ContactData[]
  email?: string
  logo?: {
    gatsbyImageData: IGatsbyImageData
  }
  phone?: string
}

type OrganizationBlockProps = {
  data: OrganizationBlockData
  index: number
}

const OrganizationBlock: FC<OrganizationBlockProps> = ({ data, index }) => {
  const {
    address,
    backgroundImage,
    backgroundOverlay,
    body,
    callToActionCaption,
    callToActionTarget,
    contacts,
    email,
    htmlElementId,
    logo,
    phone,
    theme,
    title,
  } = data

  const inverse = theme === 'dark'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      image={backgroundImage}
      index={index}
      overlay={backgroundOverlay}
    >
      <Section.Body>
        <div className="max-w-3xl mx-auto">
          {logo && (
            <div className="sm:float-left w-48 h-48 sm:w-64 sm:h-64 mx-auto sm:ml-0 sm:mr-8 mb-8 rounded-lg shadow-md bg-white">
              <GatsbyImage
                image={logo.gatsbyImageData}
                imgStyle={{ objectFit: 'contain', objectPosition: 'center' }}
                className="w-full h-full" />
            </div>
          )}
          {title && (
            <Section.Heading align="left" inverse={inverse}>
              {title}
            </Section.Heading>
          )}
          {body && (
            <Section.RichText
              size="base"
              align="left"
              overflowVisible
              inverse={inverse}
            >
              {body}
            </Section.RichText>
          )}
          <ul className="mt-8 space-y-6 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-8 sm:space-y-0 md:grid-cols-3 md:gap-x-8">
            <div className="sm:p-4 space-y-1 sm:rounded-md sm:shadow sm:bg-gray-100">
              {title && <h3 className="text-gray-900 font-bold">{title}</h3>}
              {email && (
                <a
                  href={`mailto:${email}`}
                  className="block text-blue-600 hover:text-blue-700"
                >
                  {email}
                </a>
              )}
              {phone && (
                <a
                  href={`tel:${phone}`}
                  className="block text-blue-600 hover:text-blue-700"
                >
                  {phone}
                </a>
              )}

              {address && (
                <ul className="space-y-0 text-gray-600 leading-tight">
                  {address.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              )}
            </div>
            {contacts.map((contact) => (
              <Contact
                key={contact.id}
                data={contact}
                size="small"
                inverse={inverse}
              />
            ))}
          </ul>
        </div>
        {callToActionCaption && callToActionTarget && (
          <Section.CallToAction
            caption={callToActionCaption}
            target={callToActionTarget}
            variant={inverse ? 'secondary' : 'primary'}
          />
        )}
      </Section.Body>
    </Section.Background>
  );
}

export default OrganizationBlock

export const query = graphql`
  fragment OrganizationBlock on ContentfulOrganizationBlock {
    address
    backgroundImage {
      gatsbyImageData (
        width: 2560
      )
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    contacts {
      ...Contact
    }
    email
    htmlElementId
    id
    logo {
      gatsbyImageData (
        width: 1024
      )
    }
    phone
    theme
    title
  }
`
