import {
  DropdownGroupingData,
  DropdownItemData,
} from '../components/dropdown/desktop-dropdown'
import { ExternalLink, InternalLink } from '../components/navbar/navbar'

const useDropdownItems = (data: DropdownItemData[]) => {
  return data.reduce<
    (DropdownGroupingData | (ExternalLink | InternalLink)[])[]
  >((result, item) => {
    if ('links' in item) {
      return [...result, item]
    }

    if (result.length === 0 || 'links' in result[result.length - 1]) {
      return [...result, [item]]
    }

    return result.map((data, index) => {
      if (index === result.length - 1) {
        if (Array.isArray(data)) {
          return [...data, item]
        }
      }

      return data
    })
  }, [])
}

export default useDropdownItems
