import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

const SuperscriptInline = ({ data }) => <sup>{data.text}</sup>

SuperscriptInline.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
}

export default SuperscriptInline

export const query = graphql`
  fragment SuperscriptInline on ContentfulSuperscriptInline {
    contentful_id
    text
  }
`
