import React from 'react'
import classnames from 'classnames'
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'

import EmbeddedAsset from './embedded-asset'
import RichText from './rich-text'
import IFrameBlock from '../blocks/iframe-block'
import CallToActionButtonBlock from '../blocks/call-to-action-button-block'
import VideoBlock from '../blocks/rich-text/video-block'

type PageRichTextProps = {
  children: RenderRichTextData<ContentfulRichTextGatsbyReference>
  inverse?: boolean
}

const PageRichText: React.FC<PageRichTextProps> = ({ children, inverse }) => {
  const renderNodeOptions = {
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <EmbeddedAsset data={node.data.target} fullWidth />
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { target } = node.data

      if (target && target.__typename === 'ContentfulCallToActionButtonBlock') {
        return (
          <CallToActionButtonBlock
            caption={target.caption}
            target={target.target}
          />
        )
      }

      if (target && target.__typename === 'ContentfulIFrameBlock') {
        return <IFrameBlock html={target.htmlCode.htmlCode} />
      }

      if (target && target.__typename === 'ContentfulVideoBlock') {
        return (
          <VideoBlock
            video={target.video}
            videoThumbnail={target.videoThumbnail}
            videoCallToActionCaption={target.videoCallToActionCaption}
            youTubeId={target.youTubeId}
          />
        )
      }

      return null
    },
  }

  return (
    <div
      className={classnames(
        'prose prose-h1:font-bold prose-h2:font-bold prose-h3:font-bold prose-h4:font-bold max-w-3xl mx-auto overflow-hidden',
        {
          'prose-inverse': inverse,
        }
      )}
    >
      <RichText renderNodeOptions={renderNodeOptions}>{children}</RichText>
    </div>
  )
}

export default PageRichText
