import {
  Footer as ThemeFooter,
  FooterApplicationData,
} from '@wunderdogsw/finbb-dms-theme'
import React, { FC } from 'react'
import useEnApplicationFooter from '../hooks/use-en-application-footer'
import useFiApplicationFooter from '../hooks/use-fi-application-footer'

type FooterProps = {
  locale: 'en-US' | 'fi-FI'
}

const Footer: FC<FooterProps> = ({ locale }) => {
  let applicationFooter: FooterApplicationData

  if (locale === 'fi-FI') {
    applicationFooter = useFiApplicationFooter()
  } else {
    applicationFooter = useEnApplicationFooter()
  }

  return <ThemeFooter data={applicationFooter} />
}

export default Footer
