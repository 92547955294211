import React, { FC, ReactNode } from 'react'

type MenuButtonProps = {
  children: ReactNode
  onClick: () => void
}

const MenuButton: FC<MenuButtonProps> = ({ children, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded shadow-sm text-base font-medium text-white hover:text-white bg-blue-600 hover:bg-blue-700"
    >
      {children}
    </button>
  )
}

export default MenuButton
