import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import React, { FC, Fragment, ReactNode, useState } from 'react'
import Card from '../card/card'
import CardWithTitleHeader from '../card/card-with-title-header'
import { ContactData } from '../contact/contact'
import RichText from '../rich-text'
import EcosystemCardContact from './ecosystem-card-contact'
import EcosystemCardDialogMeta from './ecosystem-card-dialog-meta'
import EcosystemCardLinkGroup from './ecosystem-card-link-group'
import EcosystemCardTitle from './ecosystem-card-title'
import { ExternalTarget, InternalTarget } from '../call-to-action'

export type EcosystemCategoryData = {
  category?: 'Company' | 'Data' | 'Partner' | 'Service'
  description?: {
    description?: string
  }
  id?: string
  name?: string
  node_locale?: string
  title?: string
}

type EcosystemCardProps = {
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>
  categories?: EcosystemCategoryData[]
  callToActionCaption: string
  cc: string
  contacts: Omit<ContactData, 'image'>[]
  image: {
    gatsbyImageData: IGatsbyImageData
  }
  lead: string
  linkGroups: ReactNode
  logo: {
    gatsbyImageData: IGatsbyImageData
  }
  meta?: string
  sections?: ReactNode
  title: string
  to: ExternalTarget | InternalTarget | string
}

const EcosystemCard = ({
  body,
  callToActionCaption,
  categories,
  cc,
  contacts,
  image,
  lead,
  linkGroups,
  logo,
  meta,
  sections,
  title,
  to,
}: EcosystemCardProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)

  const handleClick = () => {
    setOpen(true)
    window['dataLayer'] &&
      window['dataLayer'].push({ event: 'ecosystem_card_click', title })
  }

  const groupedCategories =
    categories &&
    categories.reduce(
      (group, currentCategory) => {
        if (!currentCategory.category) {
          return group
        }

        return {
          ...group,
          [`${currentCategory.category}`]: [
            ...group[currentCategory.category],
            currentCategory.title,
          ],
        }
      },
      {
        Service: new Array<string>(),
        Partner: new Array<string>(),
        Company: new Array<string>(),
        Data: new Array<string>(),
      }
    )

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-20 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                {image ? (
                  <GatsbyImage
                    image={image?.gatsbyImageData}
                    className="h-48 w-full object-cover"
                  />
                ) : (
                  <div className="w-full h-48 bg-gradient-to-br from-blue-400 to-blue-900" />
                )}
                <div className="px-4 pt-5 pb-4 sm:p-6">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      {meta && (
                        <EcosystemCardDialogMeta>
                          {meta}
                        </EcosystemCardDialogMeta>
                      )}
                      {title && (
                        <EcosystemCardTitle>{title}</EcosystemCardTitle>
                      )}
                      {body && (
                        <div className="prose mt-2">
                          <RichText>{body}</RichText>
                        </div>
                      )}
                      {linkGroups}
                      {sections}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row sm:justify-between">
                  <div className="space-y-4">
                    {contacts.map((contact) => (
                      <EcosystemCardContact
                        key={contact.id}
                        cc={cc}
                        data={contact}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <CardWithTitleHeader
        title={title}
        meta={meta}
        image={image}
        thumbnail={logo}
        tags={
          categories &&
          groupedCategories &&
          Object.keys(groupedCategories).map((key) => {
            if (groupedCategories[key].length === 0) {
              return null
            }

            return (
              <Card.TagGroup
                key={key}
                title={key}
                tags={groupedCategories[key]}
              />
            )
          })
        }
        callToActionCaption={callToActionCaption}
        to={to && `${to}/#services`}
        onClick={handleClick}
      >
        {lead}
      </CardWithTitleHeader>
    </>
  )
}

export default Object.assign(EcosystemCard, {
  LinkGroup: EcosystemCardLinkGroup,
})

export const query = graphql`
  fragment EcosystemCategory on ContentfulEcosystemCategory {
    category
    description {
      description
    }
    id
    name
    node_locale
    title
  }
`
