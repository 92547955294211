import React from 'react'
import Highlight, { HighlightData } from '../components/highlight'
import Section from '../components/section/section'
import { graphql } from 'gatsby'
import { SectionBlockData } from './section-block'

export type HighlightCollectionBlockData = SectionBlockData & {
  highlights: HighlightData[]
}

type HighlightCollectionBlockProps = {
  data: HighlightCollectionBlockData
  index: number
}

const HighlightCollectionBlock: React.FC<HighlightCollectionBlockProps> = ({
  data,
  index,
}) => {
  const {
    backgroundImage,
    backgroundOverlay,
    body,
    callToActionCaption,
    callToActionTarget,
    htmlElementId,
    highlights,
    theme,
    title,
  } = data

  const inverse = theme === 'dark'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay}
    >
      <Section.Body>
        <div className="md:flex md:justify-center md:space-x-24">
          <div className="">
            {title && (
              <Section.Heading inverse={inverse} align="left">
                {title}
              </Section.Heading>
            )}
            {body && (
              <Section.RichText inverse={inverse} align="left">
                {body}
              </Section.RichText>
            )}
          </div>
          <dl className="sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 my-10 sm:mt-0 space-y-10 sm:space-y-0">
            {highlights.map((highlight) => (
              <Highlight key={highlight.id} data={highlight} theme={theme} />
            ))}
          </dl>
        </div>
        {callToActionCaption && callToActionTarget && (
          <Section.CallToAction
            caption={callToActionCaption}
            target={callToActionTarget}
            variant={inverse ? 'secondary' : 'primary'}
          />
        )}
      </Section.Body>
    </Section.Background>
  )
}

export default HighlightCollectionBlock

export const query = graphql`
  fragment HighlightCollectionBlock on ContentfulHighlightCollectionBlock {
    backgroundImage {
      gatsbyImageData (
        width: 2560
      )
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulAsset {
            ...Asset
          }
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    highlights {
      body {
        raw
        references {
          __typename
          ... on Node {
            ... on ContentfulAsset {
              ...Asset
            }
            ... on ContentfulSuperscriptInline {
              ...SuperscriptInline
            }
          }
        }
      }
      id
      image {
        gatsbyImageData (
          width: 256
        )
      }
      title
    }
    htmlElementId
    id
    theme
    title
  }
`
