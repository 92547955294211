import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import useInnerHTML from '../../hooks/use-inner-html'

type CardMetaProps = {
  children: ReactNode
  inverse?: boolean
}

const CardMeta: FC<CardMetaProps> = ({ children, inverse = false }) => {
  const [innerHTML, innerNodes] = useInnerHTML(children)

  return (
    <p
      className={classNames('text-sm font-medium', {
        'text-white': inverse,
        'text-gray-600': !inverse,
      })}
      {...innerHTML}
    >
      {innerNodes}
    </p>
  )
}

export default CardMeta
