import classNames from 'classnames'
import React from 'react'
import RichText from '../rich-text'

type HeroLeadProps = {
  align?: 'left' | 'center'
  children:
    | {
        raw: string
        references: any[]
      }
    | string
  inverse?: boolean
  shadow?: boolean
}

const HeroLead: React.FC<HeroLeadProps> = ({
  align = 'center',
  children,
  inverse = false,
  shadow = false,
}) => (
  <div
    className={classNames(
      align === 'center' ? 'mx-auto' : '',
      'max-w-3xl mt-3 md:mt-5 text-base sm:text-lg md:text-xl',
      { 'text-gray-100': inverse, 'text-gray-500': !inverse }
    )}
    style={{ textShadow: shadow && '2px 4px 3px rgba(0,0,0,0.3)' }}
  >
    {typeof children === 'string' ? children : <RichText>{children}</RichText>}
  </div>
)

export default HeroLead
