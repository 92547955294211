import classNames from 'classnames'
import React from 'react'
import Link, { isHrefExternal } from './link'

export type ExternalTarget = {
  id: string
  text: string
  url: string
}

export type InternalTarget = {
  id: string
  fields: {
    route: string
  }
  menuTitle: string
}

export type CallToActionTarget = ExternalTarget | InternalTarget | string

export type CallToActionVariant = 'primary' | 'secondary' | 'tertiary'

export type CallToActionProps = {
  caption?: string
  fullWidth?: boolean
  inverse: boolean
  size: 'small' | 'large'
  target?: CallToActionTarget
  variant?: CallToActionVariant
}

const CallToAction: React.FC<CallToActionProps> = ({
  caption,
  fullWidth = false,
  inverse,
  size,
  target,
  variant,
}) => {
  const className = classNames(
    'inline-block px-8 py-3 border border-transparent rounded-md shadow text-base font-medium md:text-lg',
    {
      'md:px-10 md:py-4': size === 'large',
      'w-full': fullWidth && variant !== 'tertiary',
      'text-white bg-blue-600 hover:bg-blue-700 no-underline':
        variant === 'primary',
      'text-blue-900 bg-white hover:bg-blue-50 no-underline':
        variant === 'secondary',
      'inline-flex items-center bg-transparent underline':
        variant === 'tertiary',
      'text-white drop-shadow-md': variant === 'tertiary' && inverse,
    }
  )
  const style =
    variant === 'tertiary' ? { textShadow: '2px 4px 3px rgba(0,0,0,0.3)' } : {}
  const content = caption && (
    <>
      <span dangerouslySetInnerHTML={{ __html: caption }} />{' '}
      {variant === 'tertiary' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="ml-2 w-5 h-5"
        >
          <path
            fillRule="evenodd"
            d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
            clipRule="evenodd"
          />
        </svg>
      ) : (
        ''
      )}
    </>
  )

  if (!target) {
    return (
      <button style={style} className={className}>
        {content}
      </button>
    )
  }

  const href =
    typeof target === 'string'
      ? target
      : target['url'] || target['fields']['route']

  return (
    <Link
      to={target}
      target={isHrefExternal(href) ? '_blank' : ''}
      style={style}
      className={className}
    >
      {content}
    </Link>
  )
}

export default CallToAction
