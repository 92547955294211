import React, { FC } from 'react'
import Link from '../link'
import { ExternalTarget, InternalTarget } from '../call-to-action'

type CardCallToActionProps = {
  caption: string
  to: ExternalTarget | InternalTarget | string
}

const CardCallToAction: FC<CardCallToActionProps> = ({ caption, to }) => {
  return (
    <div className="mt-6 flex items-center justify-center">
      <Link
        to={to}
        className={
          'inline-block px-6 py-3 border border-transparent rounded-md shadow bg-blue-600 hover:bg-blue-700 text-base font-medium text-white'
        }
      >
        <span dangerouslySetInnerHTML={{ __html: caption }} />
      </Link>
    </div>
  )
}

export default CardCallToAction
