import classNames from 'classnames'
import { useIntl } from 'gatsby-plugin-intl'
import React, { FC, InputHTMLAttributes } from 'react'
import FormError from './form-error'

export type Register = (
  name: string,
  options: { required?: string }
) => Record<string, unknown>

export type FormInputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: string
  inverse: boolean
  register: Register
}

const FormInput: FC<FormInputProps> = ({
  error,
  inverse,
  name,
  register,
  ...other
}) => {
  const intl = useIntl()

  if (!name) {
    return <></>
  }

  return (
    <>
      <input
        name={name}
        {...register(name, {
          required: intl.formatMessage({ id: 'form.required' }),
        })}
        className={classNames(
          'focus:ring-blue-500 focus:border-blue-500 block w-full rounded-md px-2 text-gray-900',
          {
            'border-blue-300': !error && inverse,
            'border-gray-300': !error && !inverse,
            'border-red-300 bg-red-100': !!error,
          }
        )}
        {...other}
      />
      <FormError error={error} inverse={inverse} />
    </>
  )
}

export default FormInput
