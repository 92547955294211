import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import useInnerHTML from '../../hooks/use-inner-html'

type TabsPillProps = {
  children: ReactNode
  current?: boolean
  inverse?: boolean
  onClick: () => void
}

const TabsPill: FC<TabsPillProps> = ({
  children,
  current = false,
  inverse = false,
  onClick,
}) => {
  const [innerHTML, innerNodes] = useInnerHTML(children)

  return (
    <button
      onClick={onClick}
      className={classNames(
        current
          ? 'bg-blue-100 text-blue-700'
          : {
              'text-gray-500 hover:text-gray-700': !inverse,
              'text-blue-100 hover:text-white': inverse,
            },
        'px-3 py-2 font-medium rounded-md'
      )}
      aria-current={current ? 'location' : undefined}
      {...innerHTML}
    >
      {innerNodes}
    </button>
  )
}

export default TabsPill
