import classNames from 'classnames'
import React, { ReactNode } from 'react'

type FormLegendProps = {
  children: ReactNode
  inverse: boolean
}

const FormLegend = ({ children, inverse }: FormLegendProps): JSX.Element => {
  return (
    <div>
      <legend
        className={classNames('block', {
          'text-white': inverse,
          'text-gray-900': !inverse,
        })}
      >
        {children}
      </legend>
    </div>
  )
}

export default FormLegend
