import classNames from 'classnames'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { FC, ReactNode } from 'react'
import useBackground, { Theme } from '../../hooks/use-background'

type SectionBackgroundProps = {
  children: ReactNode
  className?: string
  id: string
  image?: {
    gatsbyImageData: IGatsbyImageData
  }
  index: number
  overlay: boolean
  theme: Theme
}

const SectionBackground: FC<SectionBackgroundProps> = ({
  children,
  className,
  id,
  image,
  index,
  overlay,
  theme,
}) => {
  const background = useBackground(theme, index)
  const inverse = theme === 'dark'

  return (
    <section
      id={id}
      className={classNames(
        `relative ${background}`,
        inverse ? `text-white` : `text-blue-900`,
        className
      )}
    >
      {image && (
        <div className="absolute inset-0 w-full min-h-full">
          <GatsbyImage image={image.gatsbyImageData} className="h-full" />
          {overlay && (
            <div className="z-10 absolute top-0 left-0 w-full h-full bg-blue-900 opacity-50" />
          )}
        </div>
      )}
      {children}
    </section>
  );
}

export default SectionBackground
