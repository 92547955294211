import { Section } from '@wunderdogsw/finbb-dms-theme'
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import React, { FC } from 'react'

export type StepData = {
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

type StepProps = {
  data: StepData
  index: number
  inverse?: boolean
  last?: boolean
}

const Step: FC<StepProps> = ({
  data,
  index,
  inverse = false,
  last = false,
}) => {
  return (
    <div className="w-full">
      <div className="flex items-center">
        <div className="text-5xl font-bold mr-4">{index + 1}</div>
        <div className="text-xl">
          <Section.RichText inverse={inverse}>{data.body}</Section.RichText>
        </div>
      </div>
      {!last && <div className="border-l-2 border-white h-16 ml-3"></div>}
    </div>
  )
}

export default Step
