import { UserIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC } from 'react'
import ContactLinkItem from './contact-link-item'

export type ContactData = {
  displayName?: string
  email?: string
  id: string
  image?: { gatsbyImageData?: IGatsbyImageData }
  kind?: 'Person' | 'Organization'
  phone?: string
  responsibility?: string
  title?: string
}

type ContactProps = {
  data: ContactData
  inverse: boolean
  size: 'large' | 'small'
}

const Contact: FC<ContactProps> = ({ data, inverse, size }) => {
  const { displayName, email, image, kind, phone, responsibility, title } = data

  if (kind === 'Organization') {
    return (
      <li className="my-0 sm:p-4 space-y-1 sm:rounded-md sm:shadow sm:bg-gray-100">
        {displayName && (
          <h3 className="my-0 text-base text-gray-900 font-bold">
            {displayName}
          </h3>
        )}
        {email && (
          <a
            href={`mailto:${email}`}
            className="block text-blue-600 hover:text-blue-700 no-underline"
          >
            {email}
          </a>
        )}
        {phone && (
          <a
            href={`tel:${phone}`}
            className="block text-blue-600 hover:text-blue-700 no-underline"
          >
            {phone}
          </a>
        )}
      </li>
    )
  }

  return (
    <li className="my-0">
      <div
        className={classNames({
          'space-y-4': size === 'large',
          'space-y-2': size === 'small',
        })}
      >
        <div
          className={classNames('aspect-w-3 aspect-h-2', {
            'hidden sm:block': size === 'small',
          })}
        >
          {image ? (
            <GatsbyImage
              image={image.gatsbyImageData}
              imgStyle={{ objectPosition: 'center' }}
              className="object-cover shadow rounded-md"
              style={{ position: 'absolute' }}
            />
          ) : (
            <div className="flex items-center justify-center bg-gray-500 shadow-lg rounded-md">
              <UserIcon className="h-2/3 text-gray-100" />
            </div>
          )}
        </div>

        <div className="space-y-2">
          <div
            className={classNames('leading-6', {
              'space-y-1 text-lg': size === 'large',
            })}
          >
            <h3
              className={classNames('my-0 text-base font-bold', {
                'text-gray-50': inverse,
                'text-gray-900': !inverse,
              })}
            >
              {displayName}
            </h3>
            <p
              className={classNames('my-0', {
                'text-gray-300': inverse,
                'text-gray-600': !inverse,
              })}
            >
              {responsibility}
              {responsibility && title && ' / '}
              {title}
            </p>
          </div>
          <ul
            className={classNames(
              'list-none pl-0',
              size === 'large' && 'space-y-1'
            )}
          >
            {phone && (
              <ContactLinkItem href={`tel:${phone}`} inverse={inverse}>
                {phone}
              </ContactLinkItem>
            )}
            {email && (
              <ContactLinkItem href={`mailto:${email}`} inverse={inverse}>
                {email}
              </ContactLinkItem>
            )}
          </ul>
        </div>
      </div>
    </li>
  )
}

export default Contact
