import React, { FC, ReactNode } from 'react'

type CardGridProps = {
  children: ReactNode
}

const CardGrid: FC<CardGridProps> = ({ children }) => {
  return (
    <div className="mt-6 mb-16 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
      {children}
    </div>
  )
}

export default CardGrid
