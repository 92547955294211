const isType = (entry, type) => entry.__typename === type

const isApplication = (entry) => isType(entry, 'ContentfulApplication')

const isArticlePage = (entry) => isType(entry, 'ContentfulArticlePage')

const isLink = (entry) => isType(entry, 'ContentfulLink')

const isPage = (entry) => isType(entry, 'ContentfulPage')

const isPageGroup = (entry) => isType(entry, 'ContentfulPageGroup')

const isYawaveButton = (entry) => isType(entry, 'ContentfulYawaveButton')

module.exports = {
  isApplication,
  isArticlePage,
  isLink,
  isPage,
  isPageGroup,
  isYawaveButton,
}
