import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import useInnerHTML from '../../hooks/use-inner-html'
import Tooltip from '../tooltip'

type SearchCategoryProps = {
  children: ReactNode
  description: string
  onClick: () => void
  selected: boolean
}

const SearchCategory: FC<SearchCategoryProps> = ({
  children,
  description,
  onClick,
  selected,
}) => {
  const [innerHTML, innerNodes] = useInnerHTML(children)

  return (
    <Tooltip data={description} className="my-1 mx-1">
      <button
        onClick={onClick}
        className={classNames(
          selected ? 'bg-blue-500 text-blue-50' : 'bg-gray-100 text-gray-500',
          'inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium'
        )}
        {...innerHTML}
      >
        {innerNodes}
      </button>
    </Tooltip>
  )
}

export default SearchCategory
