import React, { FC, useEffect, useState } from 'react'
import { EcosystemCategoryData } from '../ecosystem-card.tsx/ecosystem-card'
import SearchCategory from './search-category'
import classNames from 'classnames'

// TODO: Move to localization files
const SHOW_LESS = 'Less categories'
const SHOW_MORE = 'More categories'

type SearchCategoryGroupProps = {
  categories: EcosystemCategoryData[]
  collapsable: boolean
  inverse: boolean
  onCategoryClick: (name: string) => void
  onClearSelection: () => void
  onCollapseSelection: () => void
  selectedCategories: string[]
  title: string
}

const SearchCategoryGroup: FC<SearchCategoryGroupProps> = ({
  categories,
  collapsable,
  inverse,
  onCategoryClick,
  onClearSelection,
  onCollapseSelection,
  selectedCategories,
  title,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  const categoriesSelected = categories.some((category) =>
    selectedCategories.includes(category.name)
  )

  const toggleCollapsed = () => {
    if (!collapsed) {
      onCollapseSelection()
    }

    setCollapsed(!collapsed)
  }

  useEffect(() => {
    setCollapsed(collapsable)
  }, [])

  return (
    <div className="my-2">
      {!collapsed && (
        <>
          <h4 className="uppercase tracking-wider text-sm text-blue-900">
            {title}{' '}
          </h4>

          <div className="flex flex-wrap -mx-1">
            {categories.map((category) => (
              <SearchCategory
                key={category.id}
                description={category.description?.description}
                selected={selectedCategories.includes(category.name)}
                onClick={() => onCategoryClick(category.name)}
              >
                {category.name}
              </SearchCategory>
            ))}
            <div className="my-1 mx-1">
              <button
                onClick={onClearSelection}
                disabled={!categoriesSelected}
                className="bg-transparent text-blue-800 disabled:text-gray-500 inline-flex items-center px-2.5 py-0.5 text-sm font-medium"
              >
                {/* TODO: Move to localization file */}
                Clear selection
              </button>
            </div>
          </div>
        </>
      )}
      {collapsable && (
        <div className="mt-4 text-right">
          <button
            onClick={toggleCollapsed}
            className={classNames(
              'inline-flex items-center justify-center px-2 py-2 border rounded shadow-sm text-sm font-medium',
              {
                'border-transparent text-white hover:text-white bg-blue-600 hover:bg-blue-700':
                  collapsed,
                'border-blue-600 hover:border-blue-800 text-blue-800 hover:text-blue-900':
                  !collapsed,
              }
            )}
          >
            {collapsed ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
              </svg>
            )}
            {collapsed ? SHOW_MORE : SHOW_LESS}
          </button>
        </div>
      )}
    </div>
  )
}

export default SearchCategoryGroup
