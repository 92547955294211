import { Disclosure, Popover } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import React, { MutableRefObject, useState } from 'react'
import { AnnouncementBlockData } from '../../blocks/announcement-block'
import Announcement from '../announcement/announcement'
import { CallToActionTarget } from '../call-to-action'
import Menu from '../menu/menu'
import DesktopNavigationMenu from '../navigation-menu/desktop-navigation-menu'
import { NavigationMenuItemData } from '../navigation-menu/desktop-navigation-menu-item'
import MobileNavigationMenu from '../navigation-menu/mobile-navigation-menu'
import MobileUserMenu from '../user-menu/mobile-user-menu'
import DesktopUserMenu from '../user-menu/desktop-user-menu'
import HeaderCallToAction from './header-call-to-action'
import HeaderLogo from './header-logo'
import { DropdownItemData } from '../dropdown/desktop-dropdown-item'
import classNames from 'classnames'
import LanguageSwitch from '../language-switch/language-switch'

type HeaderProps = {
  announcement?: AnnouncementBlockData
  inverse: boolean
  logo: string
  logoInverse: string
  navigationCallToActionCaption: string
  navigationCallToActionTarget: CallToActionTarget
  navigationMenu?: NavigationMenuItemData[]
  title: string
  userMenu?: DropdownItemData[]
}

const Header: React.FC<HeaderProps> = ({
  announcement,
  inverse,
  logo,
  logoInverse,
  navigationCallToActionCaption,
  navigationCallToActionTarget,
  navigationMenu,
  title,
  userMenu,
}) => {
  const [logoRef, setLogoRef] =
    useState<MutableRefObject<HTMLImageElement>>(null)

  const logoWidth = logoRef?.current?.offsetWidth

  return (
    <>
      {announcement && (
        <Announcement
          title={announcement.title}
          body={announcement.body}
          link={{
            text: announcement.callToActionCaption,
            to: announcement.callToActionCaption,
          }}
        />
      )}
      <Popover
        className={classNames('relative', {
          'bg-blue-900': inverse,
          'bg-transparent': !inverse,
        })}
      >
        <Disclosure as="nav">
          {({ open }) => (
            <>
              <div className="px-4 sm:px-8 py-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <HeaderLogo
                        setRef={setLogoRef}
                        src={inverse ? logoInverse : logo}
                        alt={title}
                      />
                    </div>
                    {navigationMenu && (
                      <div className="hidden lg:block lg:ml-6">
                        <div className="flex items-center space-x-4">
                          <DesktopNavigationMenu
                            items={navigationMenu}
                            logoWidth={logoWidth}
                            inverse={inverse}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="hidden lg:block lg:ml-6" data-name="nav">
                    <div className="flex items-center space-x-6">
                      {navigationCallToActionCaption &&
                        navigationCallToActionTarget && (
                          <div className="lg:hidden xl:block">
                            <HeaderCallToAction
                              caption={navigationCallToActionCaption}
                              target={navigationCallToActionTarget}
                            />
                          </div>
                        )}
                      <LanguageSwitch
                        defaultBackgroundTheme={inverse ? 'dark' : 'light'}
                        scrollAtTop={true}
                      />
                      {userMenu && (
                        <DesktopUserMenu items={userMenu} inverse={inverse} />
                      )}
                    </div>
                  </div>
                  <div className="-mr-2 flex lg:hidden" data-name="nav">
                    <Disclosure.Button
                      className={classNames(
                        'inline-flex items-center justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset',
                        {
                          'text-blue-200 hover:text-white hover:bg-blue-900 focus:ring-white':
                            inverse,
                          'text-blue-700 hover:text-blue-900 hover:bg-blue-100 focus:ring-blue-600':
                            !inverse,
                        }
                      )}
                    >
                      {/* TODO: Move text to localization file */}
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel
                className={classNames('lg:hidden', {
                  'shadow-md': !inverse,
                })}
              >
                <div className="px-2 pt-2 pb-3">
                  {navigationMenu && (
                    <MobileNavigationMenu
                      items={navigationMenu}
                      inverse={inverse}
                    />
                  )}
                  {userMenu && (
                    <>
                      <Menu.Divider />
                      <MobileUserMenu items={userMenu} />
                    </>
                  )}

                  {navigationCallToActionCaption &&
                    navigationCallToActionTarget && (
                      <>
                        <Menu.Divider />
                        <div className="my-4 px-2 sm:px-6">
                          <HeaderCallToAction
                            caption={navigationCallToActionCaption}
                            target={navigationCallToActionTarget}
                            mobile
                          />
                        </div>
                      </>
                    )}
                  <LanguageSwitch
                    defaultBackgroundTheme={inverse ? 'dark' : 'light'}
                    scrollAtTop={true}
                  />
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Popover>
    </>
  )
}

export default Header
