import React from 'react'
import Link from '../link'
import { ExternalLink, InternalLink } from '../navbar/navbar'
import { DropdownGroupingData } from './desktop-dropdown'
import DesktopDropdownGrouping from './desktop-dropdown-grouping'

export type GroupedDropdownItemData =
  | DropdownGroupingData
  | ExternalLink
  | InternalLink
  | (ExternalLink | InternalLink)[]

export type DropdownItemData =
  | DropdownGroupingData
  | ExternalLink
  | InternalLink

export type DropdownItemProps = {
  data: GroupedDropdownItemData
  inverse: boolean
}

const DesktopDropdownItem: React.FC<DropdownItemProps> = ({
  data,
  inverse,
}) => {
  if ('links' in data || Array.isArray(data)) {
    const links = 'links' in data ? data.links : data
    const title = 'title' in data ? data.title : null

    return (
      <DesktopDropdownGrouping links={links} title={title} inverse={inverse} />
    )
  }

  const to = 'url' in data ? data.url : data.fields.route
  const caption = 'text' in data ? data.text : data.menuTitle
  const description =
    'description' in data
      ? data.description?.description
      : 'menuDescription' in data
      ? data.menuDescription?.menuDescription
      : null

  return (
    <div className="flow-root w-60">
      <Link
        to={to}
        className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150 no-underline"
      >
        <div>
          <p className="text-base font-medium text-gray-900">{caption}</p>
          {description && (
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          )}
        </div>
      </Link>
    </div>
  )
}

export default DesktopDropdownItem
