import React, { FC, ReactNode } from 'react'

type CardWrapperProps = {
  children: ReactNode
}

const CardWrapper: FC<CardWrapperProps> = ({ children }) => {
  return (
    <div className="flex flex-col rounded-lg shadow-md overflow-hidden">
      {children}
    </div>
  )
}

export default CardWrapper
