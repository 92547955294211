import React from 'react'
import classnames from 'classnames'
import { SectionTheme } from '../section/section'

type LanguageSwitchButtonProps = {
  active: boolean
  children: React.ReactNode
  defaultBackgroundTheme: SectionTheme
  onClick: () => void
  scrollAtTop: boolean
}

const LanguageSwitchButton: React.FC<LanguageSwitchButtonProps> = ({
  active,
  children,
  defaultBackgroundTheme,
  onClick,
  scrollAtTop,
}) => {
  const hasLightBackground = defaultBackgroundTheme === 'light' && scrollAtTop

  return (
    <button
      onClick={onClick}
      className={classnames(
        'border rounded-md px-2 font-bold',
        hasLightBackground ? '' : 'border-white',
        active
          ? classnames(
              hasLightBackground
                ? 'border-blue-900 bg-blue-900 text-white'
                : 'border-white bg-white text-blue-900'
            )
          : classnames(
              hasLightBackground
                ? 'border-blue-500 text-blue-900 hover:text-blue-800'
                : 'border-blue-100 text-blue-200 hover:text-white'
            )
      )}
    >
      {children}
    </button>
  )
}

export default LanguageSwitchButton
