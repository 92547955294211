import { Disclosure } from '@headlessui/react'
import React, { FC, ReactNode } from 'react'
import EcosystemCardSectionTitle from './ecosystem-card-section-title'

type EcosystemCardSectionProps = {
  children: ReactNode
  title: string
}

const EcosystemCardSection: FC<EcosystemCardSectionProps> = ({
  children,
  title,
}) => {
  return (
    <Disclosure>
      <div className="px-2 py-1 border rounded-lg shadow">
        <EcosystemCardSectionTitle>{title}</EcosystemCardSectionTitle>
        <Disclosure.Panel className="my-2">{children}</Disclosure.Panel>
      </div>
    </Disclosure>
  )
}

export default EcosystemCardSection
