import React from 'react'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import classNames from 'classnames'
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import { RichText } from '@wunderdogsw/finbb-dms-theme'
import { SectionTheme } from './section/section'

export type HighlightData = {
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>
  id: string
  image: {
    gatsbyImageData: IGatsbyImageData
  }
  title: string
}

type HighlightProps = {
  data: HighlightData
  theme: SectionTheme
}

const Highlight: React.FC<HighlightProps> = ({ data, theme }) => {
  const { body, image, title } = data

  const inverse = theme === 'dark'

  return (
    <div className="text-left">
      <dt>
        <div
          className={classNames(
            'flex items-center justify-center h-16 w-16 rounded-md',
            {
              'bg-blue-500 text-white': inverse,
              'bg-blue-100 text-blue-900': !inverse,
            }
          )}
        >
          <GatsbyImage
            image={image.gatsbyImageData}
            className="h-8 w-8"
            imgStyle={{ objectFit: 'contain' }} />
        </div>
        <p
          className={classNames('mt-5 text-lg font-bold', {
            'text-gray-900': !inverse,
            'text-white': inverse,
          })}
        >
          {title}
        </p>
      </dt>
      <dd
        className={classNames('mt-2 text-base', {
          'text-gray-800': !inverse,
          'text-gray-100': inverse,
        })}
      >
        <RichText>{body}</RichText>
      </dd>
    </div>
  );
}

export default Highlight
