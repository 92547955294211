import { GatsbyImage } from "gatsby-plugin-image";
import React, { FC } from 'react'
import Section from '../../components/section/section'

type VideoBlockProps = {
  video?: {
    file: {
      contentType
      url
    }
  }
  videoCallToActionCaption?: string
  videoThumbnail?: {
    gatsbyImageData: GatsbyImage
  }
  youTubeId?: string
}

const VideoBlock: FC<VideoBlockProps> = ({
  video,
  videoThumbnail,
  videoCallToActionCaption,
  youTubeId,
}) => {
  return (
    <div>
      {video && (
        <Section.Video
          video={video}
          thumbnail={videoThumbnail}
          play={videoCallToActionCaption}
          inverse={false}
        />
      )}
      {youTubeId && (
        <Section.YouTubeEmbed youTubeId={youTubeId} inverse={false} />
      )}
    </div>
  )
}

export default VideoBlock
