import React, { FC, ReactNode } from 'react'

type EcosystemCardContactLinkProps = {
  children: ReactNode
  href: string
}

const EcosystemCardContactLink: FC<EcosystemCardContactLinkProps> = ({
  children,
  href,
}) => {
  const handleClick = () => {
    window['dataLayer'] &&
      window['dataLayer']({ event: 'ecosystem_card_contact_click', href })
  }

  return (
    <a onClick={handleClick} href={href} className="text-blue-600">
      {children}
    </a>
  )
}

export default EcosystemCardContactLink
