import { useIntl } from 'gatsby-plugin-intl'
import React, { FC, InputHTMLAttributes } from 'react'
import { Register } from './form-input'
import classNames from 'classnames'
import FormError from './form-error'

type FormSelectProps = InputHTMLAttributes<HTMLSelectElement> & {
  error?: string
  inverse: boolean
  options: string[]
  register: Register
}

const FormSelect: FC<FormSelectProps> = ({
  error,
  inverse,
  name,
  options,
  register,
  required,
  ...other
}) => {
  const intl = useIntl()

  if (!name) {
    return <></>
  }

  return (
    <>
      <select
        name={name}
        {...register(name, {
          required: required
            ? intl.formatMessage({ id: 'form.required' })
            : undefined,
        })}
        className={classNames(
          'focus:ring-blue-500 focus:border-blue-500 block w-full rounded-md px-2 text-gray-900',
          {
            'border-blue-300': !error && inverse,
            'border-gray-300': !error && !inverse,
            'border-red-300 bg-red-100': !!error,
          }
        )}
        {...other}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <FormError error={error} inverse={inverse} />
    </>
  )
}

export default FormSelect
