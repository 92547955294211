import React, { FC } from 'react'
import MobileDropdownItem from '../dropdown/mobile-dropdown-item'
import { UserMenuProps } from './desktop-user-menu'

const MobileUserMenu: FC<UserMenuProps> = ({ items }) => {
  return (
    <ul>
      {items.map((item) => (
        <MobileDropdownItem key={item.id} data={item} inverse={true} />
      ))}
    </ul>
  )
}

export default MobileUserMenu
