import React, { FC } from 'react'
import ServiceListHeaderItem from './service-list-header-item'

// TODO: Move to localization files
const PARTNER = 'Partner'
const SERVICE = 'Service'

type ServiceListHeaderProps = {
  onClickOrderBy: (orderBy: 'Partner' | 'Service') => void
  orderBy: 'Partner' | 'Service'
  orderDirection: 'asc' | 'desc'
}

const ServiceListHeader: FC<ServiceListHeaderProps> = ({
  onClickOrderBy,
  orderBy,
  orderDirection,
}) => {
  const serviceDirection = orderBy === 'Service' ? orderDirection : 'asc'
  const partnerDirection = orderBy === 'Partner' ? orderDirection : 'asc'

  return (
    <div className="grid grid-cols-2 gap-x-12 mb-2 px-4 sm:px-6">
      <ServiceListHeaderItem
        active={orderBy === 'Service'}
        direction={serviceDirection}
        onClick={() => onClickOrderBy('Service')}
      >
        {SERVICE}
      </ServiceListHeaderItem>
      <ServiceListHeaderItem
        active={orderBy === 'Partner'}
        direction={partnerDirection}
        onClick={() => onClickOrderBy('Partner')}
      >
        {PARTNER}
      </ServiceListHeaderItem>
    </div>
  )
}

export default ServiceListHeader
