import { PlayIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import React, { FC, useEffect, useState } from 'react'

type SectionYouTubeEmbedProps = {
  inverse: boolean
  youTubeId: string
}

const SectionYouTubeEmbed: FC<SectionYouTubeEmbedProps> = ({
  inverse,
  youTubeId,
}) => {
  const [src, setSrc] = useState<string>('')
  const [started, setStarted] = useState<boolean>(false)

  const handleClick = () => setStarted(true)

  useEffect(() => {
    if (started) {
      setSrc(`https://www.youtube-nocookie.com/embed/${youTubeId}?autoplay=1`)
    }
  }, [started, youTubeId])

  return (
    <div className="relative max-w-2xl mx-auto my-6 sm:my-12 shadow-lg">
      <iframe
        width="672"
        height="378"
        src={src}
        title="YouTube video player"
        frameBorder={0}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <button
        onClick={handleClick}
        className={classNames(
          'absolute top-0 left-0 flex justify-center items-center w-full h-full',
          started && 'hidden',
          {
            'text-blue-600 hover:text-blue-700 bg-white': inverse,
            'text-white hover:text-blue-50 bg-blue-700': !inverse,
          }
        )}
      >
        <PlayIcon className="w-24 h-24" />
      </button>
    </div>
  )
}

export default SectionYouTubeEmbed
