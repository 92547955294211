import React from 'react'
import { graphql } from 'gatsby'
import HubspotForm from 'react-hubspot-form'
import Section from '../components/section/section'
import { SectionBlockData } from './section-block'

export type HubSpotFormBlockData = Omit<
  Omit<SectionBlockData, 'callToActionCaption'>,
  'callToActionTarget'
> & {
  formId: string
}

type HubSpotFormBlockProps = {
  data: HubSpotFormBlockData
  index: number
}

const HubSpotFormBlock: React.FC<HubSpotFormBlockProps> = ({ data, index }) => {
  const {
    backgroundImage,
    backgroundOverlay,
    body,
    formId,
    htmlElementId,
    theme,
    title,
  } = data

  const inverse = theme === 'dark'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay}
    >
      <Section.Body>
        {title && (
          <Section.Heading align="center" inverse={inverse}>
            {title}
          </Section.Heading>
        )}
        {body && <Section.Lead inverse={inverse}>{body}</Section.Lead>}
        <div className="max-w-xl mx-auto">
          <HubspotForm
            portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
            formId={formId}
            css={``}
          />
        </div>
      </Section.Body>
    </Section.Background>
  )
}

export default HubSpotFormBlock

export const query = graphql`
  fragment HubSpotFormBlock on ContentfulHubSpotFormBlock {
    backgroundImage {
      gatsbyImageData (
        width: 2560
      )
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    formId
    htmlElementId
    id
    theme
    title
  }
`
