import { Disclosure } from '@headlessui/react'
import React, { FC } from 'react'
import { NavigationMenuProps } from './desktop-navigation-menu'
import MobileNavigationMenuItem from './mobile-navigation-menu-item'

const MobileNavigationMenu: FC<NavigationMenuProps> = ({ inverse, items }) => {
  return (
    <Disclosure as="ul">
      {items.map((item) => (
        <MobileNavigationMenuItem key={item.id} data={item} inverse={inverse} />
      ))}
    </Disclosure>
  )
}

export default MobileNavigationMenu
