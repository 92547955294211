import { graphql } from 'gatsby'
import React, { FC } from 'react'
import Contact, { ContactData } from '../components/contact/contact'
import Section from '../components/section/section'
import { SectionBlockData } from './section-block'

export type ContactCollectionBlockData = SectionBlockData & {
  contacts: ContactData[]
}

type ContactCollectionBlockProps = {
  data: ContactCollectionBlockData
  index: number
}

const ContactCollectionBlock: FC<ContactCollectionBlockProps> = ({
  data,
  index,
}) => {
  const {
    backgroundImage,
    backgroundOverlay,
    body,
    callToActionCaption,
    callToActionTarget,
    contacts,
    htmlElementId,
    theme,
    title,
  } = data

  const inverse = theme === 'dark'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay}
    >
      <Section.Body>
        {title && <Section.Heading inverse={inverse}>{title}</Section.Heading>}
        {body && <Section.Lead inverse={inverse}>{body}</Section.Lead>}
        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
          {contacts.map((contact) => (
            <Contact
              key={contact.id}
              data={contact}
              size="large"
              inverse={inverse}
            />
          ))}
        </ul>
        {callToActionCaption && callToActionTarget && (
          <Section.CallToAction
            caption={callToActionCaption}
            target={callToActionTarget}
            variant={inverse ? 'secondary' : 'primary'}
          />
        )}
      </Section.Body>
    </Section.Background>
  )
}

export default ContactCollectionBlock

export const query = graphql`
  fragment Contact on ContentfulContact {
    displayName
    email
    id
    image {
      gatsbyImageData(width: 1024)
    }
    kind
    phone
    responsibility
    title
  }

  fragment ContactCollectionBlock on ContentfulContactCollectionBlock {
    backgroundImage {
      gatsbyImageData(width: 2560)
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    contacts {
      ...Contact
    }
    htmlElementId
    id
    theme
    title
  }
`
