import React, { FC } from 'react'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

type CardThumbnailProps = {
  gatsbyImageData?: IGatsbyImageData
}

const CardThumbnail: FC<CardThumbnailProps> = ({ gatsbyImageData }) => {
  if (!gatsbyImageData) {
    return (
      <div className="shrink-0 w-32 h-32 border-4 border-white rounded-lg shadow-md bg-gradient-to-br from-gray-50 to-gray-400"></div>
    )
  }

  return (
    <GatsbyImage
      image={gatsbyImageData}
      imgStyle={{ objectFit: 'contain', objectPosition: 'center' }}
      className="shrink-0 w-32 h-32 border-4 border-white rounded-lg shadow-md bg-white" />
  );
}

export default CardThumbnail
