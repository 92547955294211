import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC, ReactNode } from 'react'
import Link from '../link'
import CardBody from './card-body'
import CardCallToAction from './card-call-to-action'
import CardImage from './card-image'
import CardMeta from './card-meta'
import CardTitle from './card-title'
import CardWrapper from './card-wrapper'
import { ExternalTarget, InternalTarget } from '../call-to-action'

export type CardWithImageHeaderProps = {
  callToActionCaption?: string
  children: string
  gatsbyImageData?: IGatsbyImageData
  meta?: ReactNode
  onClick?: () => void
  tags?: ReactNode
  title?: string
  to?: ExternalTarget | InternalTarget | string
}

const CardWithImageHeader: FC<CardWithImageHeaderProps> = ({
  callToActionCaption,
  children,
  gatsbyImageData,
  meta,
  onClick,
  tags,
  title,
  to,
}) => {
  const content = (
    <>
      <CardMeta>{meta}</CardMeta>
      <CardTitle>{title}</CardTitle>
      <CardBody padded>{children}</CardBody>
    </>
  )

  return (
    <CardWrapper>
      <div className="relative shrink-0">
        {gatsbyImageData ? (
          <CardImage gatsbyImageData={gatsbyImageData} />
        ) : (
          <div className="w-full h-48 bg-gradient-to-br from-blue-400 to-blue-900" />
        )}
      </div>
      <div className="flex flex-col justify-between flex-1 p-6 bg-white">
        <div className="flex-1">
          {to && (
            <Link to={to} className="block mt-2">
              {content}
            </Link>
          )}
          {onClick && (
            <button onClick={onClick} className="block mt-2 text-left">
              {content}
            </button>
          )}
        </div>
        {callToActionCaption && to && (
          <CardCallToAction caption={callToActionCaption} to={to} />
        )}
      </div>
      {tags && <div className="p-6 bg-white">{tags}</div>}
    </CardWrapper>
  )
}

export default CardWithImageHeader
