import React from 'react'

type CardFallbackProps = {
  text: string
}

const CardFallback = ({ text }: CardFallbackProps): JSX.Element => {
  return (
    <div className="px-6 py-4 rounded-lg shadow-md bg-gray-100 text-center text-xl text-gray-500">
      {text}
    </div>
  )
}

export default CardFallback
