import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Link, Section } from '@wunderdogsw/finbb-dms-theme'
import { SectionBlockData } from './section-block'

export type LogoCollectionBlockData = SectionBlockData & {
  logos: {
    id: string
    image: {
      imageData: IGatsbyImageData
    }
    target: string
  }[]
}

type LogoCollectionBlockProps = {
  data: LogoCollectionBlockData
  index: number
}

const LogoCollectionBlock: React.FC<LogoCollectionBlockProps> = ({
  data,
  index,
}) => {
  const {
    backgroundImage,
    backgroundOverlay,
    body,
    callToActionCaption,
    callToActionTarget,
    htmlElementId,
    logos,
    title,
    theme,
  } = data

  const inverse = theme === 'dark'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay}
    >
      <Section.Body>
        {title && <Section.Heading inverse={inverse}>{title}</Section.Heading>}
        {body && (
          <div className="text-center">
            <Section.RichText inverse={inverse}>{body}</Section.RichText>
          </div>
        )}
        <div className="w-3/4 mx-auto flex flex-wrap justify-center items-center">
          {logos.map((logo) => (
            <Link
              to={logo.target}
              key={logo.id}
              className="p-2 sm:p-4 md:p-8 w-1/2 lg:w-1/3 xl:w-1/4"
            >
              <GatsbyImage image={logo.image.gatsbyImageData} />
            </Link>
          ))}
        </div>
        {callToActionCaption && callToActionTarget && (
          <Section.CallToAction
            caption={callToActionCaption}
            target={callToActionTarget}
            variant={inverse ? 'secondary' : 'primary'}
          />
        )}
      </Section.Body>
    </Section.Background>
  );
}

export default LogoCollectionBlock

export const query = graphql`
  fragment LogoCollectionBlock on ContentfulLogoCollectionBlock {
    backgroundImage {
      gatsbyImageData (
        width: 2560
      )
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulAsset {
            ...Asset
          }
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    htmlElementId
    id
    logos {
      id
      image {
        gatsbyImageData (
          width: 512
        )
      }
      target
    }
    theme
    title
  }
`
