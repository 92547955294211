import { Dialog } from '@headlessui/react'
import React, { FC } from 'react'
import useInnerHTML from '../../hooks/use-inner-html'

type EcosystemCardTitleProps = {
  children: string
}

const EcosystemCardTitle: FC<EcosystemCardTitleProps> = ({ children }) => {
  const [innerHTML, innerNodes] = useInnerHTML(children)

  return (
    <Dialog.Title
      as="h3"
      className="text-xl font-semibold text-gray-900"
      {...innerHTML}
    >
      {innerNodes}
    </Dialog.Title>
  )
}

export default EcosystemCardTitle
