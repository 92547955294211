import classNames from 'classnames'
import React, { FC } from 'react'
import { DropdownGroupingProps } from './desktop-dropdown-grouping'
import MobileDropdownGroupingItem from './mobile-dropdown-grouping-item'

const MobileDropdownGrouping: FC<DropdownGroupingProps> = ({
  inverse,
  links,
  title,
}) => {
  return (
    <li className="my-4 first:mt-2">
      {title && (
        <h3
          className={classNames(
            'px-2 sm:px-6 text-blue-300 text-sm uppercase tracking-wider',
            {
              'text-blue-300': inverse,
              'text-gray-600': !inverse,
            }
          )}
        >
          {title}
        </h3>
      )}
      {links.map((link) => (
        <MobileDropdownGroupingItem
          key={link.id}
          data={link}
          inverse={inverse}
        />
      ))}
    </li>
  )
}

export default MobileDropdownGrouping
