import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useInnerHTML from '../hooks/use-inner-html'

const Container = ({ children, className }) => {
  const [innerHTML, innerNodes] = useInnerHTML(children)

  return (
    <div
      className={classnames('container mx-auto px-4 sm:px-8', className)}
      {...innerHTML}
    >
      {innerNodes}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Container
