import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { FC } from 'react'
import { Section } from '../..'
import { SectionBlockData } from './section-block'

export type VideoBlockData = SectionBlockData & {
  contentful_id: string
  video?: {
    file: {
      contentType
      url
    }
  }
  videoCallToActionCaption?: string
  videoThumbnail?: {
    gatsbyImageData: IGatsbyImageData
  }
  youTubeId?: string
}

type VideoBlockProps = {
  data: VideoBlockData
  index: number
}

const VideoBlock: FC<VideoBlockProps> = ({ data, index }) => {
  const {
    backgroundImage,
    backgroundOverlay,
    body,
    callToActionCaption,
    callToActionTarget,
    htmlElementId,
    theme,
    title,
    video,
    videoCallToActionCaption,
    videoThumbnail,
    youTubeId,
  } = data

  const inverse = theme === 'dark'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay}
    >
      <Section.Body>
        {title && <Section.Heading inverse={inverse}>{title}</Section.Heading>}
        {body && <Section.Lead inverse={inverse}>{body}</Section.Lead>}
        {video && (
          <Section.Video
            video={video}
            thumbnail={videoThumbnail}
            play={videoCallToActionCaption}
            inverse={inverse}
          />
        )}
        {youTubeId && (
          <Section.YouTubeEmbed youTubeId={youTubeId} inverse={inverse} />
        )}
        {callToActionCaption && callToActionTarget && (
          <Section.CallToAction
            caption={callToActionCaption}
            target={callToActionTarget}
            variant={inverse ? 'secondary' : 'primary'}
          />
        )}
      </Section.Body>
    </Section.Background>
  )
}

export default VideoBlock

export const query = graphql`
  fragment VideoBlock on ContentfulVideoBlock {
    backgroundImage {
      gatsbyImageData (
        width: 2560
      )
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulAsset {
            ...Asset
          }
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    contentful_id
    htmlElementId
    id
    theme
    title
    video {
      file {
        contentType
        url
      }
    }
    videoCallToActionCaption
    videoThumbnail {
      gatsbyImageData (
        width: 2560
      )
    }
    youTubeId
  }
`
