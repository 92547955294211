import { ApplicationPrivacyPolicyData } from '@wunderdogsw/finbb-dms-theme'
import { graphql, useStaticQuery } from 'gatsby'

export default (): ApplicationPrivacyPolicyData => {
  const {
    contentfulApplication,
  }: { contentfulApplication: ApplicationPrivacyPolicyData } =
    useStaticQuery(graphql`
      query {
        contentfulApplication(
          title: { eq: "FINBB" }
          node_locale: { eq: "en-US" }
        ) {
          privacyPolicy {
            __typename
            ... on Node {
              ... on ContentfulArticlePage {
                id
                fields {
                  route
                }
                menuTitle
              }
              ... on ContentfulLink {
                id
                text
                url
              }
              ... on ContentfulPage {
                id
                fields {
                  route
                }
                menuTitle
              }
            }
          }
        }
      }
    `)

  return contentfulApplication
}
