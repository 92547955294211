import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import React, { FC, ReactNode } from 'react'
import useInnerHTML from '../../hooks/use-inner-html'

type EcosystemCardSectionTitleProps = {
  children: ReactNode
}

const EcosystemCardSectionTitle: FC<EcosystemCardSectionTitleProps> = ({
  children,
}) => {
  const [innerHTML, innerNodes] = useInnerHTML(children)

  return (
    <Disclosure.Button className="flex items-center justify-between w-full">
      <h5 className="text-base font-semibold text-gray-900" {...innerHTML}>
        {innerNodes}
      </h5>
      <ChevronDownIcon className="w-5 h-5" />
    </Disclosure.Button>
  )
}

export default EcosystemCardSectionTitle
