import { NavigationMenuItemData } from '@wunderdogsw/finbb-dms-theme'
import React, { FC } from 'react'
import FooterMenuItem from './footer-menu-item'

type FooterMenuProps = {
  data: NavigationMenuItemData
}

const FooterMenu: FC<FooterMenuProps> = ({ data }) => {
  if (!('items' in data)) {
    return <></>
  }

  return (
    <div>
      <h3 className="text-sm font-semibold text-gray-300 tracking-wider uppercase">
        {data.caption}
      </h3>
      <ul className="mt-4 space-y-2">
        {data.items.map((item) => (
          <FooterMenuItem key={item.id} data={item} />
        ))}
      </ul>
    </div>
  )
}

export default FooterMenu
