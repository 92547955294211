import classNames from 'classnames'
import React, { FC } from 'react'
import { ExternalTarget, InternalTarget } from '../call-to-action'
import Link from '../link'

export type DropdownCallToAction = {
  caption: string
  target: ExternalTarget | InternalTarget | string
  variant: 'primary' | 'secondary'
}

type MobileDropdownCallToActionProps = DropdownCallToAction

const MobileDropdownCallToAction: FC<MobileDropdownCallToActionProps> = ({
  caption,
  target,
  variant,
}) => {
  return (
    <div className="flow-root">
      <Link
        to={target}
        className={classNames(
          'block px-4 py-2 border rounded shadow-sm text-base font-medium transition ease-in-out duration-150',
          {
            'border-blue-100 hover:border-blue-300 text-blue-100 hover:text-blue-300':
              variant === 'secondary',
            'border-transparent text-white hover:text-white bg-blue-500 hover:bg-blue-600':
              variant === 'primary',
          }
        )}
      >
        {caption}
      </Link>
    </div>
  )
}

export default MobileDropdownCallToAction
