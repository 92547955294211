import React, { FC, ReactNode } from 'react'
import TabsPill from './tabs-pill'

const SELECT_TAB = 'Select a tab' // TODO: Move to locale file

type TabsProps = {
  buttons: ReactNode
  defaultValue: string
  onChange: (value: string) => void
  options: ReactNode
}

type TabsComponent = FC<TabsProps> & {
  Pill: typeof TabsPill
}

const Tabs: TabsComponent = ({ buttons, defaultValue, onChange, options }) => {
  return (
    <div className="mt-3 sm:mt-4">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          {SELECT_TAB}
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md text-gray-900"
          defaultValue={defaultValue}
          onChange={(event) => onChange(event.target.value || null)}
        >
          {options}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {buttons}
        </nav>
      </div>
    </div>
  )
}

Tabs.Pill = TabsPill

export default Tabs
