import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import classnames from 'classnames'

type BackgroundImageProps = {
  className?: string
  gatsbyImageData: IGatsbyImageData
  overlay?: boolean
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({
  className,
  gatsbyImageData,
  overlay,
}) => {
  return (
    <>
      <div className={classnames('absolute inset-0', className)}>
        {gatsbyImageData && (
          <GatsbyImage image={gatsbyImageData} className="h-full" />
        )}
      </div>
      {overlay && (
        <div className="z-10 absolute top-0 left-0 w-full h-full bg-blue-900 opacity-50" />
      )}
    </>
  )
}

export default BackgroundImage
