import classNames from 'classnames'
import React from 'react'
import DesktopDropdown, { DropdownData } from '../dropdown/desktop-dropdown'
import Link from '../link'
import { ExternalLink, InternalLink } from '../navbar/navbar'

export type NavigationMenuItemData = DropdownData | ExternalLink | InternalLink

export type NavigationMenuItemProps = {
  data: NavigationMenuItemData
  inverse: boolean
  logoWidth: number
}

const DesktopNavigationMenuItem: React.FC<NavigationMenuItemProps> = ({
  data,
  inverse,
  logoWidth,
}) => {
  if ('items' in data) {
    return (
      <DesktopDropdown data={data} logoWidth={logoWidth} inverse={inverse} />
    )
  }

  const to = 'url' in data ? data.url : data.fields.route
  const caption = 'text' in data ? data.text : data.menuTitle

  return (
    <Link
      to={to}
      className={classNames('text-base font-medium no-underline', {
        'text-gray-200 hover:text-white': inverse,
        'text-blue-900 hover:text-blue-800': !inverse,
      })}
    >
      {caption}
    </Link>
  )
}

export default DesktopNavigationMenuItem
