import { graphql } from 'gatsby'
import React, { FC } from 'react'
import CallToAction, {
  ExternalTarget,
  InternalTarget,
} from '../components/call-to-action'

type CallToActionButtonBlockProps = {
  caption?: string
  target?: ExternalTarget | InternalTarget
}

const CallToActionButtonBlock: FC<CallToActionButtonBlockProps> = ({
  caption,
  target,
}) => {
  return (
    <div className="my-6 text-center">
      <CallToAction
        caption={caption}
        target={target}
        variant="primary"
        size="small"
      />
    </div>
  )
}

export default CallToActionButtonBlock

export const query = graphql`
  fragment CallToActionButtonBlock on ContentfulCallToActionButtonBlock {
    caption
    contentful_id
    id
    target {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
  }
`
