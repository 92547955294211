import React from 'react'
import PropTypes from 'prop-types'

import SectionRichText from './section-rich-text'

const SectionColumn = ({ data, inverse }) => (
  <div className="w-full sm:w-1/2 md:w-1/3 mb-8 p-0 sm:px-8">
    <SectionRichText size="base" inverse={inverse}>
      {data}
    </SectionRichText>
  </div>
)

SectionColumn.propTypes = {
  data: PropTypes.object.isRequired,
  inverse: PropTypes.bool,
}

SectionColumn.defaultProps = {
  inverse: false,
}

export default SectionColumn
