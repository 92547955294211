import React, { useEffect, useRef } from 'react'
import Link from '../link'

type HeaderLogoProps = {
  alt: string
  setRef: (ref) => void
  src: string
}

const HeaderLogo: React.FC<HeaderLogoProps> = ({ alt, setRef, src }) => {
  const imgRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    setRef(imgRef)
  }, [imgRef, setRef])

  return (
    <div>
      <Link to="/" className="flex">
        <span className="sr-only">{alt}</span>
        <img ref={imgRef} className="h-8 w-auto sm:h-10" src={src} alt="" />
      </Link>
    </div>
  )
}

export default HeaderLogo
