import {
  CallToActionTarget,
  DropdownGroupingData,
  ExternalTarget,
  InternalTarget,
  Link,
  Menu,
  NavigationMenuItemData,
} from '@wunderdogsw/finbb-dms-theme'
import SocialMediaChannel from '@wunderdogsw/finbb-dms-theme/src/components/layout/footer/social-media-channel'
import React, { FC } from 'react'
import FooterMenu from './footer-menu'
import FooterMenuItem from './footer-menu-item'

type SocialMediaChannelData = {
  channelType: string
  id: string
  title: string
  url: string
}

export type FooterApplicationData = {
  contentfulApplication: {
    address: string[]
    copyright: string
    navigationCallToActionCaption: string
    navigationCallToActionTarget: CallToActionTarget
    navigationMenu: NavigationMenuItemData[]
    organizationLogo: {
      file: {
        fileName: string
        url: string
      }
    }
    organizationName: string
    socialMediaChannels: SocialMediaChannelData[]
    privacyPolicy: ExternalTarget | InternalTarget
    userMenu: (DropdownGroupingData | ExternalTarget | InternalTarget)[]
  }
}

type FooterProps = {
  data: FooterApplicationData
}

const Footer: FC<FooterProps> = ({ data }) => {
  const {
    contentfulApplication: {
      address,
      copyright,
      navigationCallToActionCaption,
      navigationCallToActionTarget,
      navigationMenu,
      organizationLogo,
      organizationName,
      privacyPolicy,
      socialMediaChannels,
      userMenu,
    },
  } = data

  return (
    <footer
      className="bg-gradient-to-br from-gray-900 to-gray-700"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        {/* TODO: Move to a localization file */}
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="sm:flex sm:space-x-24">
          <div className="space-y-8 xl:col-span-1">
            <img
              className="h-10"
              src={organizationLogo.file.url}
              alt={organizationLogo.file.fileName}
            />
            <p className="text-gray-100 text-base">{organizationName}</p>
            <div>
              {address.map((address) => (
                <p key={address} className="text-gray-100 text-base">
                  {address}
                </p>
              ))}
            </div>
            {navigationCallToActionCaption && navigationCallToActionTarget && (
              <div>
                <Menu.ButtonLink to={navigationCallToActionTarget}>
                  {navigationCallToActionCaption}
                </Menu.ButtonLink>
              </div>
            )}
            {userMenu && (
              <ul className="space-y-2">
                {userMenu.map((item) => (
                  <FooterMenuItem key={item.id} data={item} />
                ))}
              </ul>
            )}
          </div>
          {navigationMenu && (
            <div className="mt-12 sm:mt-0 sm:flex grow space-y-12 sm:space-y-0 sm:space-x-8">
              {navigationMenu.map((item) => (
                <FooterMenu key={item.id} data={item} />
              ))}
            </div>
          )}
        </div>
        <div className="flex justify-between mt-12 border-t border-gray-200 pt-8">
          {privacyPolicy && (
            <p className="text-base xl:text-center">
              <Link
                to={privacyPolicy}
                className="text-gray-300 hover:text-white underline"
                openInNewTab
              />
            </p>
          )}
          <p className="text-base text-gray-100 xl:text-center">{copyright}</p>
          <div className="flex space-x-6">
            {socialMediaChannels.map((channel) => (
              <SocialMediaChannel
                key={channel.id}
                channelType={channel.channelType}
                url={channel.url}
              />
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
