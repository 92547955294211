import { isVideo } from './is-video'
import { isDarkTheme } from './background-theme'
import { isLink, isPage, isPageGroup, isYawaveButton } from './content-types'

export default {
  isVideo,
  isDarkTheme,
  isLink,
  isPage,
  isPageGroup,
  isYawaveButton,
}
