import classNames from 'classnames'
import React, { FC } from 'react'

type FormErrorProps = {
  error?: string
  inverse: boolean
}

const FormError: FC<FormErrorProps> = ({ error, inverse }) => {
  if (!error) {
    return <></>
  }

  return (
    <div
      className={classNames('mt-1 text-red-200', {
        'text-red-200': inverse,
        'text-red-700': !inverse,
      })}
    >
      {error}
    </div>
  )
}

export default FormError
