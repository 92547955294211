import React from 'react'
import HeroCallToAction from './hero-call-to-action'
import HeroCallToActionList from './hero-call-to-action-list'
import HeroLead from './hero-lead'
import HeroTitle from './hero-title'

type HeroProps = {
  children: React.ReactNode
}

type HeroComponent = React.FC<HeroProps> & {
  CallToAction: typeof HeroCallToAction
  CallToActionList: typeof HeroCallToActionList
  Lead: typeof HeroLead
  Title: typeof HeroTitle
}

const Hero: HeroComponent = ({ children }) => (
  <header className="relative z-20 container mx-auto px-4 sm:px-8 text-center">
    {children}
  </header>
)

Hero.CallToAction = HeroCallToAction
Hero.CallToActionList = HeroCallToActionList
Hero.Lead = HeroLead
Hero.Title = HeroTitle

export default Hero
