import classNames from 'classnames'
import { useIntl } from 'gatsby-plugin-intl'
import React, { FC, TextareaHTMLAttributes } from 'react'
import { Register } from './form-input'

type FormTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  error?: string
  inverse: boolean
  register: Register
}

const FormTextarea: FC<FormTextareaProps> = ({
  error,
  inverse,
  name,
  register,
  ...other
}) => {
  const intl = useIntl()

  if (!name) {
    return <></>
  }

  return (
    <>
      <textarea
        name={name}
        {...register(name, {
          required: intl.formatMessage({ id: 'form.required' }),
        })}
        className={classNames(
          'focus:ring-blue-500 focus:border-blue-500 block w-full rounded-md px-2 text-gray-900',
          {
            'border-blue-300': !error && inverse,
            'border-gray-300': !error && !inverse,
            'border-red-300 bg-red-100': !!error,
          }
        )}
        {...other}
      />
      {error && (
        <div
          className={classNames('mt-1 text-red-200', {
            'text-red-200': inverse,
            'text-red-700': !inverse,
          })}
        >
          {error}
        </div>
      )}
    </>
  )
}

export default FormTextarea
