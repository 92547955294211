import React, { FC, useEffect, useState } from 'react'
import ServiceListHeader from './service-list-header'
import ServiceListItem from './service-list-item'
import { OperatorData } from '../../blocks/operator-collection-block'
import { ServiceData } from '../../blocks/service-collection-block'

type ServiceListProps = {
  data: (OperatorData | ServiceData)[]
  headers?: boolean
}

const ServiceList: FC<ServiceListProps> = ({ data, headers = true }) => {
  const [orderBy, setOrderBy] = useState<'Service' | 'Partner'>('Service')
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc')
  const [sortedData, setSortedData] =
    useState<(OperatorData | ServiceData)[]>(data)

  useEffect(() => {
    const sortableData = [...data]
    const result = sortableData.sort((a, b) => {
      if (
        'childrenContentfulService' in a &&
        'childrenContentfulService' in b
      ) {
        if (orderBy === 'Partner') {
          const firstOperator =
            a.childrenContentfulService[0].operator &&
            a.childrenContentfulService[0].operator[0].displayName
          const secondOperator =
            b.childrenContentfulService[0].operator &&
            b.childrenContentfulService[0].operator[0].displayName

          if (orderDirection === 'asc') {
            return firstOperator?.localeCompare(secondOperator || '')
          } else {
            return secondOperator?.localeCompare(firstOperator || '')
          }
        } else {
          const firstService = a.childrenContentfulService[0].displayName
          const secondService = b.childrenContentfulService[0].displayName

          if (orderDirection === 'asc') {
            return firstService.localeCompare(secondService)
          } else {
            return secondService.localeCompare(firstService)
          }
        }
      } else {
        return 0
      }
    })

    setSortedData(result)
  }, [data, orderBy, orderDirection])

  const toggleOrderDirection = () => {
    orderDirection === 'asc'
      ? setOrderDirection('desc')
      : setOrderDirection('asc')
  }

  const toggleOrderBy = () =>
    orderBy === 'Partner' ? setOrderBy('Service') : setOrderBy('Partner')

  const resetOrderDirection = () => setOrderDirection('asc')

  const onClickOrderBy = (value: 'Partner' | 'Service') => {
    if (value === orderBy) {
      toggleOrderDirection()
    } else {
      toggleOrderBy()
      resetOrderDirection()
    }
  }

  return (
    <>
      {headers && (
        <ServiceListHeader
          orderBy={orderBy}
          orderDirection={orderDirection}
          onClickOrderBy={onClickOrderBy}
        />
      )}
      <ul className="list-none mx-0 px-0 rounded-md divide-y divide-gray-300 shadow-sm bg-gray-100">
        {sortedData.map((service) => (
          <ServiceListItem key={service.id} data={service} />
        ))}
      </ul>
    </>
  )
}

export default ServiceList
