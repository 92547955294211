import React, { FC, ReactNode } from 'react'
import Navbar from '../navbar'
import Footer from '../footer'

type LayoutProps = {
  children: ReactNode
  locale: 'en-US' | 'fi-FI'
  navbarInverse: boolean
}

const Layout: FC<LayoutProps> = ({ children, locale, navbarInverse }) => {
  return (
    <div className="flex flex-col h-screen">
      <Navbar locale={locale} inverse={navbarInverse} />
      <main className="flex-grow">{children}</main>
      <Footer locale={locale} />
    </div>
  )
}

export default Layout
