import React from 'react'

import SectionRichText from './section-rich-text'

type SectionLeadProps = {
  children:
    | {
        raw: string
        references: any[]
      }
    | string
  inverse?: boolean
}

const SectionLead: React.FC<SectionLeadProps> = ({
  children,
  inverse = false,
}) => {
  return (
    <div className="mb-8 text-xl text-center">
      {typeof children === 'string' ? (
        children
      ) : (
        <SectionRichText inverse={inverse}>{children}</SectionRichText>
      )}
    </div>
  )
}

export default SectionLead
