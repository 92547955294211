import React from 'react'
import PropTypes from 'prop-types'

import TwitterIcon from '../../../assets/inline-svg/twitter.inline.svg'
import FacebookIcon from '../../../assets/inline-svg/facebook.inline.svg'
import LinkedinIcon from '../../../assets/inline-svg/linkedin.inline.svg'

const SOCIAL_MEDIA_CHANNEL_TYPES = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
}

const SocialMediaChannel = ({ url, channelType }) => {
  switch (channelType) {
    case SOCIAL_MEDIA_CHANNEL_TYPES.FACEBOOK:
      return (
        <a href={url} target="_blank" rel="noreferrer">
          <FacebookIcon className="social-media-icon" />
        </a>
      )
    case SOCIAL_MEDIA_CHANNEL_TYPES.TWITTER:
      return (
        <a href={url} target="_blank" rel="noreferrer">
          <TwitterIcon className="social-media-icon" />
        </a>
      )
    case SOCIAL_MEDIA_CHANNEL_TYPES.LINKEDIN:
      return (
        <a href={url} target="_blank" rel="noreferrer">
          <LinkedinIcon className="social-media-icon" />
        </a>
      )
    default:
      return null
  }
}

SocialMediaChannel.propTypes = {
  url: PropTypes.string.isRequired,
  channelType: PropTypes.string.isRequired,
}

export default SocialMediaChannel
