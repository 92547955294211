import React from 'react'
import { graphql } from 'gatsby'

type IFrameBlockProps = {
  html: string
}

const IFrameBlock: React.FC<IFrameBlockProps> = ({ html }) => {
  return (
    <div className="text-center">
      <div
        className="inline-block"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  )
}

export default IFrameBlock

export const query = graphql`
  fragment IFrameBlock on ContentfulIFrameBlock {
    contentful_id
    htmlCode {
      htmlCode
    }
  }
`
