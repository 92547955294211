import React, { FC } from 'react'
import { ContactData } from '../components/contact/contact'
import Section from '../components/section/section'
import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import OperatorCard from '../components/operator-card/operator-card'
import Search from '../components/search/search'
import { SectionBlockData } from './section-block'
import Card from '../components/card/card'
import { ExternalTarget } from '../components/call-to-action'
import { EcosystemCategoryData } from '../components/ecosystem-card.tsx/ecosystem-card'
import useFilteredOperatorOrServiceData from '../hooks/use-filtered-operator-or-service-data'
import useOperatorOrServiceSearchQuery from '../hooks/use-operator-or-service-search-query'
import useOperatorOrServiceSearchCategory from '../hooks/use-operator-or-service-search-category'
import { BACKGROUND_THEME } from '../common/background-theme'

export type OperatorCollectionBlockData = SectionBlockData & { cc: string }

type OperatorCollectionBlockProps = {
  data: OperatorCollectionBlockData
  index: number
  locale: 'en-US' | 'fi-FI'
}

export type ChildrenContentfulOperator = {
  category: EcosystemCategoryData[]
  contacts: Omit<ContactData, 'image'>[]
  coverImage: {
    gatsbyImageData: IGatsbyImageData
  }
  displayName: string
  id: string
  description: RenderRichTextData<ContentfulRichTextGatsbyReference>
  fields?: {
    route: string
  }
  logo: {
    gatsbyImageData: IGatsbyImageData
  }
  metaDescription: {
    metaDescription: string
  }
  node_locale: string
  partnerLinks?: ExternalTarget[]
  referenceLinks?: ExternalTarget[]
  serviceLinks?: ExternalTarget[]
  services: {
    id: string
    description: RenderRichTextData<ContentfulRichTextGatsbyReference>
    displayName: string
    referenceLinks?: ExternalTarget[]
    website: string
  }[]
  website: string
}

export type OperatorData = {
  childrenContentfulOperator: ChildrenContentfulOperator[]
  id: string
}

export type OperatorsData = {
  edges: {
    node: OperatorData
  }[]
}

const OperatorCollectionBlock: FC<OperatorCollectionBlockProps> = ({
  data,
  index,
  locale,
}) => {
  const {
    operators: { edges: operators },
  }: { operators: OperatorsData } = useStaticQuery(graphql`
    query {
      operators: allOperator(
        sort: {
          fields: [childrenContentfulOperator___fields___sortableDisplayName]
          order: ASC
        }
      ) {
        edges {
          node {
            childrenContentfulOperator {
              category {
                ... on Node {
                  ... on ContentfulEcosystemCategory {
                    ...EcosystemCategory
                  }
                }
              }
              contacts {
                displayName
                email
                id
                phone
                responsibility
                title
              }
              coverImage {
                gatsbyImageData(width: 512)
              }
              displayName
              id
              description {
                raw
                references {
                  __typename
                  ... on Node {
                    ... on ContentfulSuperscriptInline {
                      ...SuperscriptInline
                    }
                  }
                }
              }
              fields {
                route
              }
              logo {
                gatsbyImageData(width: 256)
              }
              metaDescription {
                metaDescription
              }
              node_locale
              partnerLinks {
                ... on Node {
                  ... on ContentfulLink {
                    ...Link
                  }
                }
              }
              referenceLinks {
                ... on Node {
                  ... on ContentfulLink {
                    ...Link
                  }
                }
              }
              serviceLinks {
                ... on Node {
                  ... on ContentfulLink {
                    ...Link
                  }
                }
              }
              services {
                id
                description {
                  raw
                  references {
                    __typename
                    ... on Node {
                      ... on ContentfulSuperscriptInline {
                        ...SuperscriptInline
                      }
                    }
                  }
                }
                displayName
                referenceLinks {
                  ... on Node {
                    ... on ContentfulLink {
                      ...Link
                    }
                  }
                }
                website
              }
              website
            }
            id
          }
        }
      }
    }
  `)

  const { searchQuery, onSearchQueryChange } = useOperatorOrServiceSearchQuery()

  const {
    groupedCategories,
    searchCategories,
    onSearchCategoryChange,
    onSearchCategoriesReset,
  } = useOperatorOrServiceSearchCategory(operators, locale)

  const filteredData = useFilteredOperatorOrServiceData(
    operators,
    locale,
    'Operator',
    searchQuery,
    searchCategories
  ) as OperatorData[]

  const {
    backgroundImage,
    backgroundOverlay,
    body,
    callToActionCaption,
    callToActionTarget,
    cc,
    htmlElementId,
    title,
  } = data

  const theme = data.theme || BACKGROUND_THEME.DEFAULT
  const inverse = theme === 'dark'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay || false}
    >
      <Section.Body>
        {title && (
          <div className="max-w-3xl mx-auto">
            <Section.Heading align="left" inverse={inverse}>
              {title}
            </Section.Heading>
          </div>
        )}
        {body && (
          <div className="">
            <Section.RichText size="base" align="center" inverse={inverse}>
              {body}
            </Section.RichText>
          </div>
        )}
        <Search
          inverse={inverse}
          categoryGroups={Object.keys(groupedCategories).map((key) => {
            if (groupedCategories[key].length === 0) {
              return null
            }

            return (
              <Search.CategoryGroup
                key={key}
                title={key}
                categories={groupedCategories[key]}
                selectedCategories={searchCategories}
                onCategoryClick={onSearchCategoryChange}
                onClearSelection={() => onSearchCategoriesReset(key)}
                inverse={inverse}
              />
            )
          })}
          onQueryChange={onSearchQueryChange}
        />
        <Card.Grid>
          {filteredData.map((operator) => (
            <OperatorCard
              key={operator.id}
              cc={cc}
              data={operator}
              locale={locale}
            />
          ))}
        </Card.Grid>
        {callToActionCaption && callToActionTarget && (
          <Section.CallToAction
            caption={callToActionCaption}
            target={callToActionTarget}
            variant={inverse ? 'secondary' : 'primary'}
          />
        )}
      </Section.Body>
    </Section.Background>
  )
}

export default OperatorCollectionBlock

export const query = graphql`
  fragment OperatorCollectionBlock on ContentfulOperatorCollectionBlock {
    backgroundImage {
      gatsbyImageData(width: 2560)
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    cc
    htmlElementId
    id
    theme
    title
  }
`
