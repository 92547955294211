import React from 'react'
import CallToAction, {
  CallToActionVariant,
  ExternalTarget,
  InternalTarget,
} from '../call-to-action'

export type HeroCallToActionData = {
  caption?: string
  target?: ExternalTarget | InternalTarget | string
}

type HeroCallToActionProps = {
  data: HeroCallToActionData
  inverse: boolean
  landing: boolean
  variant?: CallToActionVariant
}

const HeroCallToAction: React.FC<HeroCallToActionProps> = ({
  data,
  inverse,
  landing,
  variant = 'primary',
}) => {
  if (!data.caption || !data.target) {
    return <></>
  }

  return (
    <CallToAction
      caption={data.caption}
      target={data.target}
      variant={variant}
      size={landing ? 'large' : 'small'}
      fullWidth
      inverse={inverse}
    />
  )
}

export default HeroCallToAction
