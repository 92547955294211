import classNames from 'classnames'
import { useIntl } from 'gatsby-plugin-intl'
import React, { FC, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import Form from '../form/form'
import { Register } from '../form/form-input'
import { encode } from '../../common/encode-form-data'
import { Section } from '../../..'
import PrivacyPolicy, {
  ApplicationPrivacyPolicyData,
} from '../privacy-policy/privacy-policy'
import FormError from '../form/form-error'

export type ContactUsFormProps = {
  inverse: boolean
  name: string
  options: string[]
  optionsMultiselect: boolean
  optionsTranslationKey: string
  privacyPolicy: ApplicationPrivacyPolicyData
  submit?: string
}

type Inputs = {
  body: string
  consent: boolean
  discovery: string
  email: string
  name: string
  organization: string
  topic: string
}

const ContactUsForm: FC<ContactUsFormProps> = ({
  inverse,
  name,
  options,
  optionsMultiselect,
  optionsTranslationKey,
  privacyPolicy,
  submit,
}) => {
  const intl = useIntl()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const [submitted, setSubmitted] = useState<boolean>(false)

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    window['dataLayer'] &&
      window['dataLayer'].push({ event: 'contact_us_form_submission', name })

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': name, ...data }),
    })
      .then((response) => {
        if (response.status === 200) {
          setSubmitted(true)
        }
      })
      .catch((error) => alert(error))
  }

  if (submitted) {
    return (
      <>
        <div
          className={classNames('flex justify-center mb-4', {
            'text-blue-200': inverse,
            'text-blue-500': !inverse,
          })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-24 h-24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        </div>
        <Section.Heading inverse={inverse}>
          {intl.formatMessage({ id: 'form.success.heading' })}
        </Section.Heading>
        <Section.Lead inverse={inverse}>
          {intl.formatMessage({ id: 'form.success.body' })}
        </Section.Lead>
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} name={name} data-netlify="true">
      <input type="hidden" name="form-name" value={name} />
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <Form.Label
            inverse={inverse}
            htmlFor="name"
            text={intl.formatMessage({ id: 'form.name' })}
          />
          <div className="mt-2">
            <Form.Input
              name="name"
              register={register as Register}
              autoComplete="name"
              error={errors.name?.message}
              inverse={inverse}
            />
          </div>
        </div>

        <div className="sm:col-span-1">
          <Form.Label
            inverse={inverse}
            htmlFor="email"
            text={intl.formatMessage({ id: 'form.email' })}
          />
          <div className="mt-2">
            <Form.Input
              name="email"
              register={register as Register}
              type="email"
              autoComplete="email"
              error={errors.email?.message}
              inverse={inverse}
            />
          </div>
        </div>

        <div className="sm:col-span-1">
          <Form.Label
            inverse={inverse}
            htmlFor="organization"
            text={intl.formatMessage({ id: 'form.organization' })}
          />

          <div className="mt-2">
            <Form.Input
              name="organization"
              register={register as Register}
              error={errors.organization?.message}
              inverse={inverse}
            />
          </div>
        </div>

        <div className="sm:col-span-1">
          {!optionsMultiselect && (
            <Form.Label
              inverse={inverse}
              htmlFor="topic"
              text={intl.formatMessage({
                id: `form.${optionsTranslationKey || 'topic'}`,
              })}
            />
          )}
          <div className="mt-2">
            {!optionsMultiselect ? (
              <Form.Select
                name="topic"
                options={['', ...options]}
                required={true}
                register={register as Register}
                error={errors.topic?.message}
                inverse={inverse}
              />
            ) : (
              <fieldset>
                <Form.Legend inverse={inverse}>
                  {intl.formatMessage({ id: `form.${optionsTranslationKey}` })}
                </Form.Legend>
                <div className="mt-1">
                  {options.map((option) => (
                    <div
                      key={option}
                      className="inline-block mr-6 mb-2 sm:mb-1"
                    >
                      <Form.Label
                        inverse={inverse}
                        text={
                          <div className="flex items-center h-6">
                            <Form.Checkbox
                              name="topic"
                              error={errors.topic?.message}
                              inverse={inverse}
                              value={option}
                              register={register as Register}
                            />
                            <span className="ml-2">{option}</span>
                          </div>
                        }
                      />
                    </div>
                  ))}
                  <FormError error={errors.topic?.message} inverse={inverse} />
                </div>
              </fieldset>
            )}
          </div>
        </div>

        <div className="col-span-full">
          <Form.Label
            inverse={inverse}
            htmlFor="body"
            text={intl.formatMessage({ id: 'form.body' })}
          />
          <div className="mt-2">
            <Form.Textarea
              name="body"
              register={register as Register}
              rows={8}
              error={errors.body?.message}
              inverse={inverse}
            />
          </div>
        </div>
        <div className="sm:col-span-1">
          <Form.Label
            inverse={inverse}
            htmlFor="discovery"
            text={intl.formatMessage({
              id: `form.discovery`,
            })}
          />
          <div className="mt-2">
            <Form.Select
              name="discovery"
              options={[
                '',
                'Through a colleague',
                'Search engine',
                'Social media',
                'Publication',
                'Event/congress',
                'Advertising',
                'Other',
              ]}
              required={false}
              register={register as Register}
              error={errors.discovery?.message}
              inverse={inverse}
            />
          </div>
        </div>
        <div className="col-span-full">
          <Form.CheckboxWithLabel
            name="consent"
            register={register as Register}
            error={errors.consent?.message}
            inverse={inverse}
            text={
              <>
                {intl.formatMessage({ id: 'form.consent' })}{' '}
                <span
                  className={classNames({
                    'prose-inverse prose-a:underline': inverse,
                    prose: !inverse,
                  })}
                >
                  <PrivacyPolicy data={privacyPolicy}>
                    {intl.formatMessage({ id: 'form.policy' })}
                  </PrivacyPolicy>
                </span>
              </>
            }
          />
        </div>
      </div>
      <div className="mt-10 text-center">
        <Form.Submit value={submit} inverse={inverse} />
      </div>
    </form>
  )
}

export default ContactUsForm
