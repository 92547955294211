import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import useInnerHTML from '../hooks/use-inner-html'

type TooltipProps = {
  children: ReactNode
  className?: string
  data: string
}

const Tooltip: FC<TooltipProps> = ({ children, className, data }) => {
  const [innerHTML, innerNodes] = useInnerHTML(data)

  return (
    <div className={classNames(className, 'tooltip-wrapper')}>
      {children}
      <span className="tooltip" {...innerHTML}>
        {innerNodes}
      </span>
    </div>
  )
}

export default Tooltip
