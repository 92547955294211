import React from 'react'

/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as inlineComponents from '../inlines'

export default (inline) => {
  const InlineComponent = inlineComponents[inline.__typename]

  if (!InlineComponent) {
    return null
  }

  return <InlineComponent key={inline.contentful_id} data={inline} />
}
