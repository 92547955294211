import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type ApplicationData = {
  application: {
    childContentfulAsset: {
      file: {
        url: string
      }
    }
    metaDescription: string
    metaTitle: string
    twitterUsername: string
    url: string
  }
}

type SEOProps = {
  description?: string
  image?: string
  lang?: string
  meta?: (
    | {
        name: string
        content: string
        property?: undefined
      }
    | {
        property: string
        content: string
        name?: undefined
      }
  )[]
  path?: string
  title?: string
  type?: string
}

type TestableSEOProps = SEOProps & { data: ApplicationData }

export const TestableSEO: React.FC<TestableSEOProps> = ({
  data,
  description,
  image,
  lang,
  meta,
  path,
  title,
  type = 'website',
}) => {
  const { application } = data
  const {
    metaTitle,
    metaDescription: defaultDescription,
    url,
    twitterUsername,
  } = application
  const { url: defaultImage } = application.childContentfulAsset.file

  const htmlLang = lang || 'en'
  const metaDescription = description || defaultDescription
  const metaImage = `https:${image || defaultImage}`
  const metaUrl = `${url}${path || `/`}`
  const defaultTitle = metaTitle

  return (
    <Helmet
      htmlAttributes={{
        lang: htmlLang,
      }}
      defaultTitle={defaultTitle}
      title={title}
      titleTemplate={`%s | ${defaultTitle}`}
      link={[{ rel: 'canonical', href: metaUrl }]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: metaImage,
        },
        {
          property: `og:title`,
          content: title || defaultTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: twitterUsername,
        },
        {
          name: `twitter:title`,
          content: title || defaultTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ].concat(meta || [])}
    />
  )
}

export const SEO: React.FC<SEOProps> = (props) => {
  const data: ApplicationData = useStaticQuery(
    graphql`
      query {
        application {
          childContentfulAsset {
            file {
              url
            }
          }
          metaDescription
          metaTitle
          twitterUsername
          url
        }
      }
    `
  )

  return <TestableSEO data={data} {...props} />
}
