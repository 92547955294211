import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import React, { FC } from 'react'
import CallToAction, { CallToActionProps } from '../call-to-action'
import SectionRichText from './section-rich-text'

type SectionCallToActionWithTextProps = Omit<CallToActionProps, 'size'> & {
  body?: RenderRichTextData<ContentfulRichTextGatsbyReference>
  inverse: boolean
}

const SectionCallToActionWithText: FC<SectionCallToActionWithTextProps> = ({
  body,
  caption,
  inverse,
  target,
  variant = 'primary',
}) => {
  return (
    <div className="md:flex md:space-x-8">
      <div className="w-full md:w-2/3 text-lg">
        {body && (
          <SectionRichText inverse={inverse} align="left">
            {body}
          </SectionRichText>
        )}
      </div>
      <div className="w-full md:w-1/3 flex flex-row justify-end md:flex-col md:items-end mt-8 md:mt-0">
        {caption && target && (
          <CallToAction
            caption={caption}
            target={target}
            size="large"
            variant={variant}
          />
        )}
      </div>
    </div>
  )
}

export default SectionCallToActionWithText
