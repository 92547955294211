import {
  DropdownGroupingData,
  ExternalTarget,
  InternalTarget,
} from '@wunderdogsw/finbb-dms-theme'
import React, { FC } from 'react'
import FooterMenuLink from './footer-menu-link'

type FooterMenuItemProps = {
  data: DropdownGroupingData | ExternalTarget | InternalTarget
}

const FooterMenuItem: FC<FooterMenuItemProps> = ({ data }) => {
  if ('links' in data) {
    return (
      <>
        {data.links.map((link) => (
          <li key={link.id}>
            <FooterMenuLink to={link} />
          </li>
        ))}
      </>
    )
  }

  return (
    <li>
      <FooterMenuLink to={data} />
    </li>
  )
}

export default FooterMenuItem
