import React, { Fragment, useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Section from '../components/section/section'
import Card from '../components/card/card'
import { SectionBlockData } from './section-block'
import Tabs from '../components/tabs/tabs'
import CardWithImageHeader from '../components/card/card-with-image-header'

const ALL = 'All' // TODO: Move to locale file

type CategoryData = {
  id: string
  name: string
  title?: string
}

export type ArticleCollectionBlockData = SectionBlockData & {
  cardCallToActionCaption: string
  category?: CategoryData
  limit: null | number
}

type ArticleCollectionBlockProps = {
  data: ArticleCollectionBlockData
  index: number
  locale: string
}

type ArticleData = {
  childrenContentfulArticlePage: {
    category?: CategoryData
    id: string
    fields: {
      route: string
    }
    fullTitle: string
    metaDescription: null | {
      metaDescription: string
    }
    metaImage: {
      imageData: null | GatsbyImage
    }
    node_locale: string
  }[]
}

type ArticlesData = {
  edges: {
    node: ArticleData
  }[]
  group: {
    edges: {
      node: ArticleData
    }[]
    fieldValue: string
  }[]
}

type CategoriesData = {
  edges: {
    node: CategoryData & {
      node_locale: string
    }
  }[]
}

type StaticData = {
  articles: ArticlesData
  categories: CategoriesData
}

const filteredArticles = (
  data: ArticlesData,
  limit: number,
  locale: string,
  categoryName?: string
): ArticleData[] => {
  if (!categoryName) {
    return data.edges
      .filter(({ node }) => {
        const validLocale =
          node.childrenContentfulArticlePage[0].node_locale === locale

        return validLocale
      })
      .slice(0, limit)
      .map(({ node }) => node)
  }

  const edges = data.group.find(
    (item) => item.fieldValue === categoryName
  )?.edges

  if (!edges) {
    return []
  }

  return edges
    .filter(({ node }) => {
      const validLocale =
        node.childrenContentfulArticlePage[0].node_locale === locale

      return validLocale
    })
    .slice(0, limit)
    .map(({ node }) => node)
}

const ArticleCollectionBlock: React.FC<ArticleCollectionBlockProps> = ({
  data,
  index,
  locale,
}) => {
  const [currentCategoryName, setCurrentCategoryName] = useState<string>('')

  const handleCategorySelect = (name: string) => setCurrentCategoryName(name)

  const { articles, categories }: StaticData = useStaticQuery(
    graphql`
      query {
        articles: allArticle(
          sort: {
            fields: [childrenContentfulArticlePage___publishedAt]
            order: DESC
          }
        ) {
          edges {
            node {
              id
              childrenContentfulArticlePage {
                ...ArticlePageCard
              }
            }
          }
          group(field: childrenContentfulArticlePage___category___name) {
            fieldValue
            edges {
              node {
                id
                childrenContentfulArticlePage {
                  ...ArticlePageCard
                }
              }
            }
          }
        }
        categories: allContentfulArticleCategory {
          edges {
            node {
              id
              name
              node_locale
              title
            }
          }
        }
      }
    `
  )

  const limit = data.limit || articles.edges.length

  const {
    backgroundImage,
    backgroundOverlay,
    body,
    callToActionCaption,
    callToActionTarget,
    cardCallToActionCaption,
    category,
    htmlElementId,
    theme,
    title,
  } = data

  const categoryName = category && category.name
  const inverse = theme === 'dark'

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const categoryParam = params.get('category')
    const defaultCategory = categories.edges.find(({ node }) => {
      const targetName = categoryParam || categoryName

      return node.name === targetName && node.node_locale === locale
    })?.node

    setCurrentCategoryName(defaultCategory?.name)
  }, [categories, categoryName, locale])

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay}
    >
      <Section.Body>
        {title && <Section.Heading inverse={inverse}>{title}</Section.Heading>}
        {body && <Section.Lead inverse={inverse}>{body}</Section.Lead>}
        <div className="mt-3 sm:mt-4">
          <Tabs
            defaultValue={
              categories.edges.find(
                ({ node }) =>
                  node.name === categoryName && node.node_locale === locale
              )?.node?.name
            }
            onChange={handleCategorySelect}
            buttons={
              <>
                <Tabs.Pill
                  current={!currentCategoryName}
                  onClick={() => handleCategorySelect(null)}
                  inverse={inverse}
                >
                  {ALL}
                </Tabs.Pill>

                {categories.edges.map(({ node }) => {
                  if (
                    node.node_locale !== locale ||
                    !articles.group.find(
                      (item) => item.fieldValue === node.name
                    )
                  ) {
                    return <Fragment key={node.id} />
                  }

                  return (
                    <Tabs.Pill
                      key={node.id}
                      current={node.name === currentCategoryName}
                      onClick={() => handleCategorySelect(node.name)}
                      inverse={inverse}
                    >
                      {node.title}
                    </Tabs.Pill>
                  )
                })}
              </>
            }
            options={
              <>
                <option value="">{ALL}</option>
                {categories.edges.map(({ node }) => {
                  if (
                    node.node_locale !== locale ||
                    !articles.group.find(
                      (item) => item.fieldValue === node.name
                    )
                  ) {
                    return <Fragment key={node.id} />
                  }

                  return (
                    <option key={node.id} value={node.name}>
                      {node.title}
                    </option>
                  )
                })}
              </>
            }
          />
        </div>
        <Card.Grid>
          {filteredArticles(articles, limit, locale, currentCategoryName).map(
            (article) => {
              const contentfulArticlePage =
                article.childrenContentfulArticlePage[0]

              return (
                <CardWithImageHeader
                  key={contentfulArticlePage.id}
                  title={contentfulArticlePage.fullTitle}
                  to={contentfulArticlePage.fields.route}
                  meta={contentfulArticlePage.category?.title}
                  gatsbyImageData={
                    contentfulArticlePage.metaImage?.gatsbyImageData
                  }
                  callToActionCaption={cardCallToActionCaption}
                >
                  {contentfulArticlePage.metaDescription &&
                    contentfulArticlePage.metaDescription.metaDescription}
                </CardWithImageHeader>
              )
            }
          )}
        </Card.Grid>
        {callToActionCaption && callToActionTarget && (
          <Section.CallToAction
            caption={callToActionCaption}
            target={callToActionTarget}
            variant={inverse ? 'secondary' : 'primary'}
          />
        )}
      </Section.Body>
    </Section.Background>
  )
}

export default ArticleCollectionBlock

export const query = graphql`
  fragment ArticlePageCard on ContentfulArticlePage {
    category {
      id
      name
      node_locale
      title
    }
    fields {
      route
    }
    fullTitle
    id
    metaDescription {
      metaDescription
    }
    metaImage {
      gatsbyImageData(width: 512)
    }
    node_locale
  }

  fragment ArticleCollectionBlock on ContentfulArticleCollectionBlock {
    backgroundImage {
      gatsbyImageData(width: 512)
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    cardCallToActionCaption
    category {
      id
      name
      title
    }
    htmlElementId
    id
    limit
    theme
    title
  }
`
