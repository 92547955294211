import React from 'react'
import { graphql } from 'gatsby'

const renderBlock = (block, applicationData, locale, index, components) => {
  const BlockComponent = components[block.__typename]

  if (!BlockComponent) {
    return null
  }

  return (
    <BlockComponent
      key={block.id}
      index={index}
      applicationData={applicationData}
      data={block}
      locale={locale}
    />
  )
}

const groupBlocksByTheme = (items) =>
  items.reduce((accumulator, currentBlock) => {
    const currentTheme = currentBlock.backgroundImage || currentBlock.theme
    const { previousTheme } = accumulator
    let themeBlocks
    let blocks

    if (accumulator.blocks) {
      if (currentTheme === previousTheme) {
        const currentThemeBlocks =
          accumulator.blocks[accumulator.blocks.length - 1]
        themeBlocks = [...currentThemeBlocks, currentBlock]
        blocks = [
          ...accumulator.blocks.filter(
            (_, index) => index !== accumulator.blocks.length - 1
          ),
          themeBlocks,
        ]
      } else {
        themeBlocks = [currentBlock]
        blocks = [...accumulator.blocks, themeBlocks]
      }
    } else {
      blocks = [[currentBlock]]
    }

    const result = {
      previousTheme: currentTheme,
      blocks: blocks,
    }

    return result
  }, {})

const BlocksRender = ({ applicationData, children, components, locale }) => {
  return (
    <>
      {groupBlocksByTheme(children).blocks.flatMap((themedBlocks) =>
        themedBlocks.map((block, index) =>
          renderBlock(block, applicationData, locale, index, components)
        )
      )}
    </>
  )
}

export default BlocksRender

export const query = graphql`
  fragment Blocks on Node {
    ... on ContentfulAnnouncementBlock {
      ...AnnouncementBlock
    }
    ... on ContentfulArticleCollectionBlock {
      ...ArticleCollectionBlock
    }
    ... on ContentfulCardCollectionBlock {
      ...CardCollectionBlock
    }
    ... on ContentfulColumnedSectionBlock {
      ...ColumnedSectionBlock
    }
    ... on ContentfulContactCollectionBlock {
      ...ContactCollectionBlock
    }
    ... on ContentfulCtaBlock {
      ...CtaBlock
    }
    ... on ContentfulEcosystemCollectionBlock {
      ...EcosystemCollectionBlock
    }
    ... on ContentfulHighlightCollectionBlock {
      ...HighlightCollectionBlock
    }
    ... on ContentfulHubSpotFormBlock {
      ...HubSpotFormBlock
    }
    ... on ContentfulImageContentBlock {
      ...ImageContentBlock
    }
    ... on ContentfulLogoCollectionBlock {
      ...LogoCollectionBlock
    }
    ... on ContentfulOperatorCollectionBlock {
      ...OperatorCollectionBlock
    }
    ... on ContentfulOrganizationBlock {
      ...OrganizationBlock
    }
    ... on ContentfulServiceCollectionBlock {
      ...ServiceCollectionBlock
    }
    ... on ContentfulStepByStepBlock {
      ...StepByStepBlock
    }
    ... on ContentfulTextContentBlock {
      ...TextContentBlock
    }
    ... on ContentfulTextContentCollectionBlock {
      ...TextContentCollectionBlock
    }
    ... on ContentfulVideoBlock {
      ...VideoBlock
    }
  }
`
