import { ReactNode } from 'react'

type InnerHTML = {
  dangerouslySetInnerHTML?: {
    __html: string
  }
}

const useInnerHTML = (
  children: string | React.ReactNode
): [innerHTML: InnerHTML, innerNodes: ReactNode] => {
  const innerHTML: InnerHTML = {}
  let innerNodes
  if (typeof children === 'string') {
    innerHTML.dangerouslySetInnerHTML = { __html: children }
  } else {
    innerNodes = children
  }

  return [innerHTML, innerNodes]
}

export default useInnerHTML
