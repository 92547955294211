import classNames from 'classnames'
import React from 'react'

import useInnerHTML from '../../hooks/use-inner-html'

type CardTitleProps = {
  children: React.ReactNode
  inverse?: boolean
}

const CardTitle: React.FC<CardTitleProps> = ({ children, inverse = false }) => {
  const [innerHTML, innerNodes] = useInnerHTML(children)

  return (
    <h3
      className={classNames('text-xl font-semibold', {
        'text-white': inverse,
        'text-gray-900': !inverse,
      })}
      {...innerHTML}
    >
      {innerNodes}
    </h3>
  )
}

export default CardTitle
