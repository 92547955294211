import { ExternalLinkIcon } from '@heroicons/react/solid'
import React, { FC, ReactNode } from 'react'
import { ExternalTarget, InternalTarget } from '../call-to-action'
import Link from '../link'

type EcosystemCardLinkProps = {
  children: ReactNode
  to: ExternalTarget | InternalTarget | string
}

const EcosystemCardLink: FC<EcosystemCardLinkProps> = ({ children, to }) => {
  const handleClick = () => {
    const hrefFromObject = (obj: ExternalTarget | InternalTarget) =>
      'url' in obj ? obj.url : obj.fields?.route

    const href = typeof to === 'string' ? to : hrefFromObject(to)

    window['dataLayer'] &&
      window['dataLayer'].push({ event: 'ecosystem_card_link_click', href })
  }

  return (
    <Link
      to={to}
      target="_blank"
      onClick={handleClick}
      className="flex items-center space-x-1 text-blue-700 hover:underline"
    >
      <span>{children}</span>
      <ExternalLinkIcon className="shrink-0 h-4 w-4" />
    </Link>
  )
}

export default EcosystemCardLink
