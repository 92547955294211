import { Popover } from '@headlessui/react'
import React, { FC } from 'react'
import DesktopNavigationMenuItem, {
  NavigationMenuItemData,
} from './desktop-navigation-menu-item'

export type NavigationMenuProps = {
  inverse: boolean
  items: NavigationMenuItemData[]
  logoWidth: number
}

const DesktopNavigationMenu: FC<NavigationMenuProps> = ({
  inverse,
  items,
  logoWidth,
}) => {
  return (
    <Popover.Group as="nav" className="flex items-center space-x-10">
      {items.map((item) => (
        <DesktopNavigationMenuItem
          key={item.id}
          data={item}
          logoWidth={logoWidth}
          inverse={inverse}
        />
      ))}
    </Popover.Group>
  )
}

export default DesktopNavigationMenu
