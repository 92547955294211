import React, { FC, PropsWithChildren } from 'react'
import { ExternalTarget, InternalTarget } from '../call-to-action'
import Link from '../link'

export type ApplicationPrivacyPolicyData = {
  privacyPolicy: ExternalTarget | InternalTarget
}

type PrivacyPolicyProps = PropsWithChildren & {
  data: ApplicationPrivacyPolicyData
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ children, data }) => {
  return <Link to={data.privacyPolicy} openInNewTab>{children}</Link>
}

export default PrivacyPolicy
