import React, { FC, ReactNode } from 'react'

import SectionBackground from './section-background'
import SectionBody from './section-body'
import SectionCallToAction from './section-call-to-action'
import SectionColumn from './section-column'
import SectionCTA from './section-cta'
import SectionHeading from './section-heading'
import SectionLead from './section-lead'
import SectionRichText from './section-rich-text'
import SectionVideo from './section-video'
import SectionYouTubeEmbed from './section-youtube-embed'
import SectionCallToActionWithText from './section-call-to-action-with-text'

type SectionProps = {
  children: ReactNode
}

type SectionComponent = FC<SectionProps> & {
  Background: typeof SectionBackground
  Body: typeof SectionBody
  CallToAction: typeof SectionCallToAction
  Column: typeof SectionColumn
  CTA: typeof SectionCTA
  CallToActionWithText: typeof SectionCallToActionWithText
  Heading: typeof SectionHeading
  Lead: typeof SectionLead
  RichText: typeof SectionRichText
  Video: typeof SectionVideo
  YouTubeEmbed: typeof SectionYouTubeEmbed
}

export type SectionTheme = 'dark' | 'light'

const Section: SectionComponent = ({ children }) => <>{children}</>

Section.Background = SectionBackground
Section.Body = SectionBody
Section.CallToAction = SectionCallToAction
Section.Column = SectionColumn
Section.CTA = SectionCTA
Section.CallToActionWithText = SectionCallToActionWithText
Section.Heading = SectionHeading
Section.Lead = SectionLead
Section.RichText = SectionRichText
Section.Video = SectionVideo
Section.YouTubeEmbed = SectionYouTubeEmbed

export default Section
