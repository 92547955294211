import { Section } from '@wunderdogsw/finbb-dms-theme'
import { SectionBlockData } from '@wunderdogsw/finbb-dms-theme/src/blocks/section-block'
import { graphql } from 'gatsby'
import React, { FC } from 'react'
import Step, { StepData } from '../../../fingenious-site/src/components/step'

export type StepByStepBlockData = SectionBlockData & {
  steps: StepData[]
}

type StepByStepBlockProps = {
  data: StepByStepBlockData
  index: number
}

const StepByStepBlock: FC<StepByStepBlockProps> = ({ data, index }) => {
  const {
    backgroundImage,
    backgroundOverlay,
    body,
    callToActionCaption,
    callToActionTarget,
    htmlElementId,
    steps,
    theme,
    title,
  } = data

  const inverse = theme === 'dark'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay}
    >
      <Section.Body>
        {title && <Section.Heading inverse={inverse}>{title}</Section.Heading>}
        {body && (
          <div className="text-center">
            <Section.RichText inverse={inverse}>{body}</Section.RichText>
          </div>
        )}
        <div className="flex flex-col items-center w-full sm:w-2/3 md:w-1/2 mx-auto my-16">
          {steps.map((step, index) => (
            <Step
              key={index}
              data={step}
              index={index}
              inverse={theme === 'dark'}
              last={index === steps.length - 1}
            />
          ))}
        </div>
        {callToActionCaption && callToActionTarget && (
          <Section.CallToAction
            caption={callToActionCaption}
            target={callToActionTarget}
            variant={inverse ? 'secondary' : 'primary'}
          />
        )}
      </Section.Body>
    </Section.Background>
  )
}

export default StepByStepBlock

export const query = graphql`
  fragment StepByStepBlock on ContentfulStepByStepBlock {
    backgroundImage {
      gatsbyImageData (
        width: 2560
      )
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulAsset {
            ...Asset
          }
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    htmlElementId
    id
    steps {
      body {
        raw
        references {
          __typename
          ... on Node {
            ... on ContentfulAsset {
              ...Asset
            }
            ... on ContentfulSuperscriptInline {
              ...SuperscriptInline
            }
          }
        }
      }
      id
    }
    theme
    title
  }
`
