import { Header } from '@wunderdogsw/finbb-dms-theme'
import React from 'react'
import useEnApplicationNavbar from '../hooks/use-en-application-navbar'
import useFiApplicationNavbar, {
  ApplicationNavbarData,
} from '../hooks/use-fi-application-navbar'

type NavbarProps = {
  inverse: boolean
  locale: 'en-US' | 'fi-FI'
}

const Navbar: React.FC<NavbarProps> = ({ inverse, locale }) => {
  let applicationNavbar: ApplicationNavbarData

  if (locale === 'fi-FI') {
    applicationNavbar = useFiApplicationNavbar()
  } else {
    applicationNavbar = useEnApplicationNavbar()
  }

  const {
    announcementBlock,
    navigationCallToActionCaption,
    navigationCallToActionTarget,
    navigationMenu,
    siteLogo,
    siteLogoInverse,
    userMenu,
  } = applicationNavbar

  return (
    <Header
      title="Fingenious"
      logo={siteLogo.resize.src}
      logoInverse={siteLogoInverse.resize.src}
      inverse={inverse}
      navigationMenu={navigationMenu}
      userMenu={userMenu}
      navigationCallToActionCaption={navigationCallToActionCaption}
      navigationCallToActionTarget={navigationCallToActionTarget}
      announcement={announcementBlock}
    />
  )
}
export default Navbar
