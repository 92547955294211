import {
  BackgroundImage,
  ExternalTarget,
  Hero,
  InternalTarget,
} from '@wunderdogsw/finbb-dms-theme'
import { graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import React, { FC } from 'react'
import LandingHero from '../components/landing-hero/landing-hero'

export type HeroMediaFile = {
  contentType: string
  url: string
}

type HeroMedia = {
  file: HeroMediaFile
  gatsbyImageData: IGatsbyImageData
}

export type HeroBlockData = {
  backgroundMedia: HeroMedia | undefined
  backgroundOverlay?: boolean
  bannerImage?: {
    file: {
      url: string
    }
    gatsbyImageData: IGatsbyImageData
  }
  bannerText?: string
  id: string
  isFullScreen: boolean
  lead: RenderRichTextData<ContentfulRichTextGatsbyReference>
  primaryCallToActionCaption?: string
  primaryCallToActionTarget?: ExternalTarget | InternalTarget
  secondaryCallToActionCaption?: string
  secondaryCallToActionTarget?: ExternalTarget | InternalTarget
  tertiaryCallToActionCaption?: string
  tertiaryCallToActionTarget?: ExternalTarget | InternalTarget
  title: string
}

type HeroBlockProps = {
  data: HeroBlockData
}

const HeroBlock: FC<HeroBlockProps> = ({ data }) => {
  const { backgroundMedia, isFullScreen, title } = data

  if (isFullScreen) {
    return <LandingHero heading={title} media={backgroundMedia} />
  }

  return (
    <header className="relative bg-blue px-4 sm:px-8 pt-16 pb-8">
      {backgroundMedia && backgroundMedia.gatsbyImageData && (
        <BackgroundImage
          gatsbyImageData={backgroundMedia.gatsbyImageData}
          overlay
        />
      )}
      <div className="relative z-20 max-w-3xl mx-auto text-white">
        <Hero.Title
          landing={false}
          inverse={!!backgroundMedia}
          shadow={!!backgroundMedia}
          className="mt-2"
        >
          {title}
        </Hero.Title>
      </div>
    </header>
  )
}

export default HeroBlock

export const query = graphql`
  fragment HeroBlock on ContentfulHeroBlock {
    backgroundMedia {
      gatsbyImageData(width: 2560)
      file {
        contentType
        url
      }
    }
    backgroundOverlay
    bannerImage {
      file {
        url
      }
      gatsbyImageData(width: 640)
    }
    bannerText
    id
    isFullScreen
    lead {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    primaryCallToActionCaption
    primaryCallToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          ...ArticlePage
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          ...Page
        }
      }
    }
    secondaryCallToActionCaption
    secondaryCallToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          ...ArticlePage
        }
        ... on ContentfulLink {
          id
          text
          url
        }
        ... on ContentfulPage {
          ...Page
        }
      }
    }
    tertiaryCallToActionCaption
    tertiaryCallToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          ...ArticlePage
        }
        ... on ContentfulLink {
          id
          text
          url
        }
        ... on ContentfulPage {
          ...Page
        }
      }
    }
    title
  }
`
