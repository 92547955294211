import React, { FC, ReactNode } from 'react'
import useInnerHTML from '../../hooks/use-inner-html'
import classNames from 'classnames'

type ServiceListItemTitleProps = {
  children: string | ReactNode
  className?: string
}

const ServiceListItemTitle: FC<ServiceListItemTitleProps> = ({
  children,
  className,
}) => {
  const [innerHTML, innerNodes] = useInnerHTML(children)

  return (
    <p
      {...innerHTML}
      className={classNames('my-0 text-lg text-gray-900', className)}
    >
      {innerNodes}
    </p>
  )
}

export default ServiceListItemTitle
