import { SearchIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import SearchBadge from './search-badge'
import SearchCategory from './search-category'
import SearchCategoryGroup from './search-category-group'
import { SearchType } from '../../hooks/use-filtered-operator-or-service-data'

// TODO: Move to localization files
const PARTNERS = 'Partners'
const SERVICES = 'Services'
const SEARCH = 'Search'

type SearchProps = {
  categoryGroups: ReactNode
  inverse: boolean
  onQueryChange: (event: any) => void
  onTypeChange?: (event: any) => void
  type: SearchType
}

type SearchComponent = FC<SearchProps> & {
  Badge: typeof SearchBadge
  Category: typeof SearchCategory
  CategoryGroup: typeof SearchCategoryGroup
}

const Search: SearchComponent = ({
  categoryGroups,
  inverse,
  onQueryChange,
  onTypeChange,
  type,
}) => {
  return (
    <div
      className={classNames('my-8 px-3 py-2 rounded-xl shadow', {
        'bg-blue-300': inverse,
        'bg-blue-100': !inverse,
      })}
    >
      {onTypeChange && (
        <div className="sm:flex w-full mb-6 sm:space-x-8 space-y-2 sm:space-y-0 rounded-md shadow-sm">
          <button
            onClick={() => onTypeChange('Operator')}
            className={classNames(
              'inline-flex items-center justify-center relative w-full sm:w-1/2 px-8 py-3 md:px-10 md:py-4 rounded-md shadow-sm text-base font-medium md:text-lg',
              {
                'ring-1 ring-inset ring-blue-300 bg-blue-500 text-white':
                  type === 'Operator',
                'ring-1 ring-inset ring-gray-300 bg-gray-100 hover:bg-gray-50 text-gray-500':
                  type === 'Service',
              }
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={classNames('w-6 h-6 mr-1 ', {
                'text-blue-200': type === 'Operator',
                'text-gray-600': type === 'Service',
              })}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
              />
            </svg>
            {PARTNERS}
          </button>
          <button
            onClick={() => onTypeChange('Service')}
            className={classNames(
              'inline-flex items-center justify-center relative w-full sm:w-1/2 px-8 py-3 md:px-10 md:py-4 rounded-md shadow-sm text-base font-medium md:text-lg',
              {
                'ring-1 ring-inset ring-blue-300 bg-blue-500 text-white':
                  type === 'Service',
                'ring-1 ring-inset ring-gray-300 bg-gray-100 hover:bg-gray-50 text-gray-500':
                  type === 'Operator',
              }
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={classNames('w-6 h-6 mr-1 ', {
                'text-blue-200': type === 'Service',
                'text-gray-600': type === 'Operator',
              })}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
              />
            </svg>
            {SERVICES}
          </button>
        </div>
      )}
      <div className="relative flex-grow focus-within:z-10">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="mobile-search-candidate"
          id="mobile-search-candidate"
          className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-l-md pl-10 border-blue-300 text-gray-900"
          placeholder={SEARCH}
          onChange={onQueryChange}
        />
      </div>
      {categoryGroups}
    </div>
  )
}

Search.Badge = SearchBadge
Search.Category = SearchCategory
Search.CategoryGroup = SearchCategoryGroup

export default Search
