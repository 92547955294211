import { graphql, useStaticQuery } from 'gatsby'
import React, { FC, useState } from 'react'
import { OperatorsData } from './operator-collection-block'
import { ServicesData } from './service-collection-block'
import { SectionBlockData } from './section-block'
import Section from '../components/section/section'
import { BACKGROUND_THEME } from '../common/background-theme'
import Search from '../components/search/search'
import useOperatorOrServiceSearchCategory from '../hooks/use-operator-or-service-search-category'
import useFilteredOperatorOrServiceData, {
  SearchType,
} from '../hooks/use-filtered-operator-or-service-data'
import useOperatorOrServiceSearchQuery from '../hooks/use-operator-or-service-search-query'
import Card from '../components/card/card'
import OperatorCard from '../components/operator-card/operator-card'
import ServiceList from '../components/service-list/service-list'

// TODO: Move to localization files
const EMPTY = 'No results'
const PARTNER = 'Partner'
const SERVICE = 'Service'

export type EcosystemCollectionBlockData = SectionBlockData & { cc: string }

type EcosystemCollectionBlockProps = {
  data: EcosystemCollectionBlockData
  index: number
  locale: 'en-US' | 'fi-FI'
}

const EcosystemCollectionBlock: FC<EcosystemCollectionBlockProps> = ({
  data,
  index,
  locale,
}) => {
  const {
    operators,
    services,
  }: { operators: OperatorsData; services: ServicesData } =
    useStaticQuery(graphql`
      query {
        operators: allOperator(
          sort: {
            fields: [childrenContentfulOperator___fields___sortableDisplayName]
            order: ASC
          }
        ) {
          edges {
            node {
              childrenContentfulOperator {
                category {
                  ... on Node {
                    ... on ContentfulEcosystemCategory {
                      ...EcosystemCategory
                    }
                  }
                }
                contacts {
                  displayName
                  email
                  id
                  phone
                  responsibility
                  title
                }
                coverImage {
                  gatsbyImageData(width: 512)
                }
                displayName
                description {
                  raw
                  references {
                    __typename
                    ... on Node {
                      ... on ContentfulSuperscriptInline {
                        ...SuperscriptInline
                      }
                    }
                  }
                }
                fields {
                  route
                }
                id
                logo {
                  gatsbyImageData(width: 256)
                }
                metaDescription {
                  metaDescription
                }
                node_locale
                partnerLinks {
                  ... on Node {
                    ... on ContentfulLink {
                      ...Link
                    }
                  }
                }
                referenceLinks {
                  ... on Node {
                    ... on ContentfulLink {
                      ...Link
                    }
                  }
                }
                serviceLinks {
                  ... on Node {
                    ... on ContentfulLink {
                      ...Link
                    }
                  }
                }
                services {
                  id
                  description {
                    raw
                    references {
                      __typename
                      ... on Node {
                        ... on ContentfulSuperscriptInline {
                          ...SuperscriptInline
                        }
                      }
                    }
                  }
                  displayName
                  referenceLinks {
                    ... on Node {
                      ... on ContentfulLink {
                        ...Link
                      }
                    }
                  }
                  website
                }
                website
              }
              id
            }
          }
        }
        services: allService(
          sort: {
            fields: [childrenContentfulService___fields___sortableDisplayName]
            order: ASC
          }
        ) {
          edges {
            node {
              childrenContentfulService {
                category {
                  ... on Node {
                    ... on ContentfulEcosystemCategory {
                      ...EcosystemCategory
                    }
                  }
                }
                contacts {
                  displayName
                  email
                  id
                  phone
                  responsibility
                  title
                }
                displayName
                id
                description {
                  raw
                  references {
                    __typename
                    ... on Node {
                      ... on ContentfulSuperscriptInline {
                        ...SuperscriptInline
                      }
                    }
                  }
                }
                metaDescription {
                  metaDescription
                }
                node_locale
                operator {
                  coverImage {
                    gatsbyImageData(width: 512)
                  }
                  displayName
                  fields {
                    route
                  }
                  id
                  logo {
                    gatsbyImageData(width: 256)
                  }
                  website
                }
                referenceLinks {
                  ... on Node {
                    ... on ContentfulLink {
                      ...Link
                    }
                  }
                }
                website
              }
              id
            }
          }
        }
      }
    `)

  const [searchType, setSearchType] = useState<SearchType>('Operator')

  const { searchQuery, onSearchQueryChange } = useOperatorOrServiceSearchQuery()

  const {
    groupedCategories,
    searchCategories,
    onSearchCategoryChange,
    onSearchCategoriesReset,
  } = useOperatorOrServiceSearchCategory(
    [...operators.edges, ...services.edges],
    locale
  )

  const filteredData = useFilteredOperatorOrServiceData(
    [...operators.edges, ...services.edges],
    locale,
    searchType,
    searchQuery,
    searchCategories
  )

  const onSearchTypeChange = (type: SearchType) => {
    onSearchCategoriesReset(type === 'Operator' ? 'Service' : 'Company')
    setSearchType(type)
  }

  const {
    backgroundImage,
    backgroundOverlay,
    body,
    callToActionCaption,
    callToActionTarget,
    cc,
    htmlElementId,
    title,
  } = data

  const theme = data.theme || BACKGROUND_THEME.DEFAULT
  const inverse = theme === 'dark'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay || false}
    >
      <Section.Body>
        {title && (
          <div className="max-w-3xl mx-auto">
            <Section.Heading align="left" inverse={inverse}>
              {title}
            </Section.Heading>
          </div>
        )}
        {body && (
          <div className="">
            <Section.RichText size="base" align="center" inverse={inverse}>
              {body}
            </Section.RichText>
          </div>
        )}
        <Search
          inverse={inverse}
          categoryGroups={Object.keys(groupedCategories).map((key) => {
            if (searchType !== 'Operator' && key === 'Company') {
              return null
            }

            if (searchType !== 'Service' && key === 'Service') {
              return null
            }

            if (groupedCategories[key].length === 0) {
              return null
            }

            return (
              <Search.CategoryGroup
                key={key}
                title={key}
                collapsable={key === 'Data'}
                categories={groupedCategories[key]}
                selectedCategories={searchCategories}
                onCategoryClick={onSearchCategoryChange}
                onClearSelection={() => onSearchCategoriesReset(key)}
                onCollapseSelection={() => onSearchCategoriesReset(key)}
                inverse={inverse}
              />
            )
          })}
          type={searchType}
          onTypeChange={onSearchTypeChange}
          onQueryChange={onSearchQueryChange}
        />
        {filteredData.length > 0 ? (
          searchType === 'Operator' ? (
            <Card.Grid>
              {filteredData.map((operator) => (
                <OperatorCard
                  key={operator.id}
                  cc={cc}
                  data={operator}
                  locale={locale}
                />
              ))}
            </Card.Grid>
          ) : (
            <ServiceList data={filteredData} />
          )
        ) : (
          <Card.Fallback text={EMPTY} />
        )}
        {callToActionCaption && callToActionTarget && (
          <Section.CallToAction
            caption={callToActionCaption}
            target={callToActionTarget}
            variant={inverse ? 'secondary' : 'primary'}
          />
        )}
      </Section.Body>
    </Section.Background>
  )
}

export default EcosystemCollectionBlock

export const query = graphql`
  fragment EcosystemCollectionBlock on ContentfulEcosystemCollectionBlock {
    backgroundImage {
      gatsbyImageData(width: 2560)
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    cc
    htmlElementId
    id
    theme
    title
  }
`
