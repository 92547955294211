import { useEffect, useState } from 'react'
import {
  ChildrenContentfulOperator,
  OperatorData,
} from '../blocks/operator-collection-block'
import {
  ChildrenContentfulService,
  ServiceData,
} from '../blocks/service-collection-block'

export type SearchType = 'Operator' | 'Service'

const useFilteredOperatorOrServiceData = (
  data: ({ node: OperatorData } | { node: ServiceData })[],
  locale: 'en-US' | 'fi-FI',
  type: SearchType,
  query: string,
  categories: string[]
): (OperatorData | ServiceData)[] => {
  const [filteredData, setFilteredData] = useState<
    (OperatorData | ServiceData)[]
  >([])

  useEffect(() => {
    const localeMatches = (
      item: ChildrenContentfulOperator[] | ChildrenContentfulService[]
    ) => {
      return item[0].node_locale === locale
    }

    const isService = (
      item: ChildrenContentfulOperator[] | ChildrenContentfulService[]
    ): boolean => {
      return 'operator' in item[0]
    }

    const typeMatches = (
      item: ChildrenContentfulOperator[] | ChildrenContentfulService[]
    ) => {
      const foundType = isService(item) ? 'Service' : 'Operator'

      return foundType === type
    }

    const categoriesMatch = (
      item: ChildrenContentfulOperator[] | ChildrenContentfulService[]
    ) => {
      if (categories.length === 0) {
        return true
      }

      return item[0].category?.some((category) => {
        if (!category.name) {
          return false
        }

        return categories.includes(category.name)
      })
    }

    const queryMatches = (
      item: ChildrenContentfulOperator[] | ChildrenContentfulService[]
    ) => {
      if (!query || query === '') {
        return true
      } else {
        return (
          item[0].displayName.toLowerCase().includes(query) ||
          item[0].metaDescription.metaDescription.toLowerCase().includes(query)
        )
      }
    }

    const filterCallback = (item: { node: OperatorData | ServiceData }) => {
      const { node } = item

      let data: ChildrenContentfulOperator[] | ChildrenContentfulService[]
      if ('childrenContentfulOperator' in node) {
        data = node.childrenContentfulOperator
      } else {
        data = node.childrenContentfulService
      }

      if (!localeMatches(data)) {
        return false
      }

      if (!typeMatches(data)) {
        return false
      }

      if (!categoriesMatch(data)) {
        return false
      }

      return queryMatches(data)
    }

    const filteredNodes = data.filter((item) => filterCallback(item)) || []

    setFilteredData(filteredNodes.map(({ node }) => node))
  }, [type, query, categories])

  return filteredData
}

export default useFilteredOperatorOrServiceData
