import {
  DropdownGroupingData,
  ExternalTarget,
  InternalTarget,
  Link,
} from '@wunderdogsw/finbb-dms-theme'
import React, { FC } from 'react'

const renderLink = (data: ExternalTarget | InternalTarget) => (
  <Link
    to={data}
    className="text-base text-gray-300 hover:text-white underline"
  />
)

type FooterMenuLinkProps = {
  to: DropdownGroupingData | ExternalTarget | InternalTarget
}

const FooterMenuLink: FC<FooterMenuLinkProps> = ({ to }) => {
  if ('links' in to) {
    return <>{to.links.map((link) => renderLink(link))}</>
  }

  return renderLink(to)
}

export default FooterMenuLink
