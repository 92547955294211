import classNames from 'classnames'
import React, { FC } from 'react'
import HeroCallToAction, { HeroCallToActionData } from './hero-call-to-action'

type HeroCallToActionListProps = {
  inverse: boolean
  landing: boolean
  primary?: HeroCallToActionData
  secondary?: HeroCallToActionData
  tertiary?: HeroCallToActionData
}

const HeroCallToActionList: FC<HeroCallToActionListProps> = ({
  inverse,
  landing,
  primary,
  secondary,
  tertiary,
}) => {
  const captions = primary?.caption || secondary?.caption

  if (!captions) {
    return <></>
  }

  return (
    <div className={classNames('max-w-3xl mx-auto')}>
      <div
        className={classNames(
          'sm:flex sm:justify-center sm:max-w-md mt-6 sm:mt-12 space-y-4 sm:space-y-0 sm:space-x-8',
          { 'mx-auto': landing }
        )}
      >
        {primary && (
          <HeroCallToAction
            data={primary}
            variant="primary"
            landing={landing}
            inverse={inverse}
          />
        )}
        {secondary && (
          <HeroCallToAction
            data={secondary}
            variant="secondary"
            landing={landing}
            inverse={inverse}
          />
        )}
        {!secondary && tertiary && (
          <HeroCallToAction
            data={tertiary}
            variant="tertiary"
            landing={landing}
            inverse={inverse}
          />
        )}
      </div>
      {secondary && tertiary && (
        <div className="mt-4">
          <HeroCallToAction
            data={tertiary}
            variant="tertiary"
            landing={landing}
            inverse={inverse}
          />
        </div>
      )}
    </div>
  )
}

export default HeroCallToActionList
