import classNames from 'classnames'
import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { FC } from 'react'
import Section from '../components/section/section'
import { SectionBlockData } from './section-block'

export type ImageContentBlockData = SectionBlockData & {
  image?: {
    gatsbyImageData: IGatsbyImageData
  }
  imageAlignment?: 'left' | 'right'
}

type ImageContentBlockProps = {
  data: ImageContentBlockData
  index: number
}

const ImageContentBlock: FC<ImageContentBlockProps> = ({ data, index }) => {
  const {
    backgroundImage,
    backgroundOverlay,
    body,
    callToActionCaption,
    callToActionTarget,
    htmlElementId,
    image,
    imageAlignment,
    theme,
    title,
  } = data

  const imageLeft = imageAlignment === 'left'
  const inverse = theme === 'dark'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay}
    >
      <Section.Body>
        <div
          className={classNames(
            'flex flex-col items-center',
            imageLeft ? 'md:flex-row' : 'md:flex-row-reverse'
          )}
        >
          {image && image.gatsbyImageData && (
            <div className="w-full md:w-1/3 p-8">
              <GatsbyImage image={image.gatsbyImageData} />
            </div>
          )}
          <div className="w-full md:w-2/3 p-8">
            {title && (
              <Section.Heading inverse={inverse} align="left">
                {title}
              </Section.Heading>
            )}
            {body && (
              <Section.RichText size="base" inverse={inverse} align="left">
                {body}
              </Section.RichText>
            )}
          </div>
        </div>
        {callToActionCaption && callToActionTarget && (
          <Section.CallToAction
            caption={callToActionCaption}
            target={callToActionTarget}
            variant={inverse ? 'secondary' : 'primary'}
          />
        )}
      </Section.Body>
    </Section.Background>
  );
}

export default ImageContentBlock

export const query = graphql`
  fragment ImageContentBlock on ContentfulImageContentBlock {
    backgroundImage {
      gatsbyImageData (
        width: 2560
      )
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulAsset {
            ...Asset
          }
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    htmlElementId
    id
    image {
      gatsbyImageData (
        width: 2560
      )
    }
    imageAlignment
    theme
    title
  }
`
