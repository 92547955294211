import classNames from 'classnames'
import React from 'react'

import useInnerHTML from '../../hooks/use-inner-html'

type CardBodyProps = {
  children: React.ReactNode
  padded?: boolean
}

const CardBody: React.FC<CardBodyProps> = ({ children, padded = true }) => {
  const [innerHTML, innerNodes] = useInnerHTML(children)

  return (
    <p
      className={classNames(padded && 'mt-3', 'text-base text-gray-500')}
      {...innerHTML}
    >
      {innerNodes}
    </p>
  )
}

export default CardBody
