import { FooterApplicationData } from '@wunderdogsw/finbb-dms-theme'
import { graphql, useStaticQuery } from 'gatsby'

export default (): FooterApplicationData => {
  const data: FooterApplicationData = useStaticQuery(graphql`
    query {
      contentfulApplication(
        title: { eq: "FINBB" }
        node_locale: { eq: "fi-FI" }
      ) {
        address
        copyright
        navigationCallToActionCaption
        navigationCallToActionTarget {
          ... on Node {
            __typename
            ... on ContentfulArticlePage {
              id
              fields {
                route
              }
            }
            ... on ContentfulLink {
              id
              url
            }
            ... on ContentfulPage {
              id
              fields {
                route
              }
            }
          }
        }
        navigationMenu {
          ... on Node {
            __typename
            ... on ContentfulArticlePage {
              ...ArticlePage
            }
            ... on ContentfulDropdown {
              ...Dropdown
            }
            ... on ContentfulLink {
              ...Link
            }
            ... on ContentfulPage {
              ...Page
            }
          }
        }
        organizationLogo {
          file {
            fileName
            url
          }
        }
        organizationName
        privacyPolicy {
          ... on Node {
            __typename
            ... on ContentfulArticlePage {
              ...ArticlePage
            }
            ... on ContentfulLink {
              ...Link
            }
            ... on ContentfulPage {
              ...Page
            }
          }
        }
        socialMediaChannels {
          channelType
          id
          title
          url
        }
        userMenu {
          ... on Node {
            __typename
            ... on ContentfulArticlePage {
              ...ArticlePage
            }
            ... on ContentfulDropdownGrouping {
              ...DropdownGrouping
            }
            ... on ContentfulLink {
              ...Link
            }
            ... on ContentfulPage {
              ...Page
            }
          }
        }
      }
    }
  `)

  return data
}
