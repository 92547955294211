import classNames from 'classnames'
import { useIntl } from 'gatsby-plugin-intl'
import React, { FC } from 'react'
import { FormInputProps } from './form-input'

const FormCheckbox: FC<FormInputProps> = ({
  error,
  inverse,
  name,
  register,
  value,
}) => {
  const intl = useIntl()

  if (!name) {
    return <></>
  }

  return (
    <input
      name={name}
      id={name}
      value={value}
      {...register(name, {
        required: intl.formatMessage({ id: 'form.required' }),
      })}
      type="checkbox"
      className={classNames(
        'h-4 w-4 rounded focus:ring-blue-500 text-blue-500 cursor-pointer',
        {
          'border-blue-300': !error && inverse,
          'border-gray-300': !error && !inverse,
          'border-red-300 bg-red-100': !!error,
        }
      )}
    />
  )
}

export default FormCheckbox
