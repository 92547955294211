import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

type ContactLinkItemProps = {
  children: ReactNode
  href: string
  inverse: boolean
}

const ContactLinkItem: FC<ContactLinkItemProps> = ({
  children,
  href,
  inverse,
}) => {
  return (
    <li className="block my-0 px-0 text-truncate">
      <a
        href={href}
        className={classNames('break-all', {
          'text-blue-300 hover:text-blue-100 no-underline': inverse,
          'text-blue-600 hover:text-blue-700 no-underline': !inverse,
        })}
      >
        {children}
      </a>
    </li>
  )
}

export default ContactLinkItem
