import React, { FC } from 'react'
import { ExternalTarget } from '../call-to-action'
import EcosystemCardLink from './ecosystem-card-link'

type EcosystemCardLinkGroupProps = {
  links: (ExternalTarget | string)[]
  title: string
}

const EcosystemCardLinkGroup: FC<EcosystemCardLinkGroupProps> = ({
  links,
  title,
}) => {
  return (
    <>
      <h4 className="mt-6 text-lg font-semibold text-gray-900">{title}</h4>
      <div className="space-y-2">
        {links.map((link) => {
          const id = typeof link === 'string' ? link : link.id
          const caption = typeof link === 'string' ? link : link.text

          return (
            <EcosystemCardLink key={id} to={link}>
              {caption}
            </EcosystemCardLink>
          )
        })}
      </div>
    </>
  )
}

export default EcosystemCardLinkGroup
