import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import Link, { To } from '../link'

type MenuButtonLinkProps = {
  children: ReactNode
  fullWidth?: boolean
  to: To
}

const MenuButtonLink: FC<MenuButtonLinkProps> = ({
  children,
  fullWidth,
  to,
}) => {
  return (
    <Link
      to={to}
      className={classNames(
        'inline-flex items-center justify-center px-4 py-2 border border-transparent rounded shadow-sm text-base font-medium text-white hover:text-white bg-blue-600 hover:bg-blue-700 no-underline',
        { 'w-full': fullWidth }
      )}
    >
      {children}
    </Link>
  )
}

export default MenuButtonLink
