import { graphql } from 'gatsby'
import React, { FC } from 'react'
import Section from '../components/section/section'
import { SectionBlockData } from './section-block'
import ContactUsForm from '../components/contact-us-form/contact-us-form'
import { BACKGROUND_THEME } from '../common/background-theme'
import { ApplicationPrivacyPolicyData } from '../components/privacy-policy/privacy-policy'

export type CtaBlockData = SectionBlockData & {
  callToActionType: 'Form' | 'Link'
  formName?: string
  formOptions?: string[]
  formOptionsSelection: 'Checkbox' | 'Dropdown'
  formOptionsTranslationKey: 'areaOfInterest' | 'topic'
}

type CtaBlockProps = {
  applicationData: ApplicationPrivacyPolicyData
  data: CtaBlockData
  index: number
}

const CtaBlock: FC<CtaBlockProps> = ({ applicationData, data, index }) => {
  const {
    backgroundImage,
    backgroundOverlay,
    body,
    callToActionCaption,
    callToActionTarget,
    callToActionType,
    formName,
    formOptions,
    formOptionsSelection,
    formOptionsTranslationKey,
    htmlElementId,
    title,
  } = data

  const theme = data.theme || BACKGROUND_THEME.DEFAULT
  const inverse = theme === 'dark'
  const form = callToActionType === 'Form'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay || false}
    >
      <Section.Body>
        {title && (
          <Section.Heading align={form ? 'center' : 'left'} inverse={inverse}>
            {title}
          </Section.Heading>
        )}
        {form ? (
          <>
            {body && <Section.Lead inverse={inverse}>{body}</Section.Lead>}
            {formName && formOptions && formOptions.length > 0 && (
              <ContactUsForm
                inverse={inverse}
                name={formName}
                options={formOptions}
                optionsTranslationKey={formOptionsTranslationKey}
                optionsMultiselect={formOptionsSelection === 'Checkbox'}
                submit={callToActionCaption}
                privacyPolicy={applicationData}
              />
            )}
          </>
        ) : (
          <Section.CallToActionWithText
            body={body}
            inverse={inverse}
            caption={callToActionCaption}
            target={callToActionTarget}
            variant={inverse ? 'secondary' : 'primary'}
          />
        )}
      </Section.Body>
    </Section.Background>
  )
}

export default CtaBlock

export const query = graphql`
  fragment CtaBlock on ContentfulCtaBlock {
    backgroundImage {
      gatsbyImageData(width: 2560)
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulAsset {
            ...Asset
          }
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    callToActionType
    formName
    formOptions
    formOptionsSelection
    formOptionsTranslationKey
    htmlElementId
    id
    theme
    title
  }
`
