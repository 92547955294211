import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC } from 'react'
import Link from '../link'
import CardBody from './card-body'
import CardCallToAction from './card-call-to-action'
import CardImage from './card-image'
import CardMeta from './card-meta'
import CardTitle from './card-title'
import { CardWithImageHeaderProps } from './card-with-image-header'
import CardWrapper from './card-wrapper'

type CardWithTitleHeaderProps = CardWithImageHeaderProps & {
  image?: {
    gatsbyImageData: IGatsbyImageData
  }
  thumbnail?: {
    gatsbyImageData: IGatsbyImageData
  }
}

const CardWithTitleHeader: FC<CardWithTitleHeaderProps> = ({
  callToActionCaption,
  children,
  image,
  meta,
  onClick,
  tags,
  thumbnail,
  title,
  to,
}) => {
  const content = <CardBody padded={false}>{children}</CardBody>
  const header = (
    <>
      <CardMeta inverse>{meta}</CardMeta>
      <CardTitle inverse>{title}</CardTitle>
    </>
  )

  return (
    <CardWrapper>
      <div className="relative flex flex-col shrink-0 grow">
        <CardImage
          gatsbyImageData={image?.gatsbyImageData}
          thumbnail={thumbnail}
          overlay
        >
          {to && <Link to={to}>{header}</Link>}
          {!to && onClick && (
            <button onClick={onClick} className="text-left">
              {header}
            </button>
          )}
        </CardImage>
        <div className="flex flex-col justify-between grow px-6 py-4 bg-white">
          <div className="flex-1">
            {to && (
              <Link to={to} className="block text-left">
                {content}
              </Link>
            )}
            {!to && onClick && (
              <button onClick={onClick} className="block text-left">
                {content}
              </button>
            )}
          </div>
          {callToActionCaption && to && (
            <CardCallToAction caption={callToActionCaption} to={to} />
          )}
        </div>
        {tags && <div className="p-6 bg-white">{tags}</div>}
      </div>
    </CardWrapper>
  )
}

export default CardWithTitleHeader
