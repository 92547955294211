import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import React, { Fragment, ReactNode } from 'react'
import useDropdownCallToActions from '../../hooks/use-dropdown-call-to-actions'
import useDropdownItems from '../../hooks/use-dropdown-items'
import { CallToActionTarget } from '../call-to-action'
import { ExternalLink, InternalLink } from '../navbar/navbar'
import DesktopDropdownCallToAction from './desktop-dropdown-call-to-action'
import DesktopDropdownItem, { DropdownItemData } from './desktop-dropdown-item'

export type DropdownGroupingData = {
  id: string
  links: (ExternalLink | InternalLink)[]
  title: string
}

export type DropdownData = {
  caption: ReactNode
  id: string
  items: DropdownItemData[]
  primaryCallToActionCaption: string
  primaryCallToActionTarget: CallToActionTarget
  secondaryCallToActionCaption: string
  secondaryCallToActionTarget: CallToActionTarget
}

export type DropdownProps = {
  data: DropdownData
  inverse: boolean
  logoWidth: number
}

const DesktopDropdown: React.FC<DropdownProps> = ({
  data,
  inverse,
  logoWidth,
}) => {
  const items = useDropdownItems(data.items)
  const callToActions = useDropdownCallToActions(data)

  const { caption } = data

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open
                ? { 'text-white': inverse, 'text-blue-800': !inverse }
                : { 'text-gray-200': inverse, 'text-blue-900': !inverse },
              'group bg-transparent rounded-md inline-flex items-center h-10 text-base font-medium focus:outline-none',
              {
                'hover:text-white': inverse,
                'hover:text-blue-800': !inverse,
              }
            )}
          >
            <span>{caption}</span>
            <ChevronDownIcon
              className={classNames(
                open
                  ? { 'text-blue-100': inverse, 'text-gray-400': !inverse }
                  : { 'text-blue-200': inverse, 'text-gray-400': !inverse },
                'ml-2 h-5 w-5',
                {
                  'group-hover:text-blue-100': inverse,
                  'group-hover:text-blue-900': !inverse,
                }
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-30 inset-x-0 mt-4 transform">
              <div className="shadow-lg">
                <div className="relative px-5 py-6 sm:p-8 sm:pl-14 bg-white">
                  <div
                    style={{ paddingLeft: logoWidth }}
                    className="flex space-x-8"
                  >
                    {items.map((item) => {
                      const id = 'id' in item ? item.id : item[0].id

                      return (
                        <DesktopDropdownItem
                          key={id}
                          data={item}
                          inverse={inverse}
                        />
                      )
                    })}
                  </div>
                </div>
                {callToActions.length > 0 && (
                  <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:pl-16">
                    <div
                      style={{ paddingLeft: logoWidth }}
                      className="space-y-6 sm:flex sm:space-y-0 sm:space-x-10"
                    >
                      {callToActions.map((callToAction) => (
                        <DesktopDropdownCallToAction
                          key={callToAction.caption}
                          caption={callToAction.caption}
                          target={callToAction.target}
                          variant={callToAction.variant}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default DesktopDropdown

export const query = graphql`
  fragment Dropdown on ContentfulDropdown {
    caption
    id
    items {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          ...ArticlePage
        }
        ... on ContentfulDropdownGrouping {
          ...DropdownGrouping
        }
        ... on ContentfulLink {
          ...Link
        }
        ... on ContentfulPage {
          ...Page
        }
      }
    }
    primaryCallToActionCaption
    primaryCallToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          ...ArticlePage
        }
        ... on ContentfulLink {
          ...Link
        }
        ... on ContentfulPage {
          ...Page
        }
      }
    }
    secondaryCallToActionCaption
    secondaryCallToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          ...ArticlePage
        }
        ... on ContentfulLink {
          ...Link
        }
        ... on ContentfulPage {
          ...Page
        }
      }
    }
  }
`
