import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'
import { ExternalTarget } from '../components/call-to-action'
import Card from '../components/card/card'
import CardWithImageHeader from '../components/card/card-with-image-header'
import Section from '../components/section/section'
import { SectionBlockData } from './section-block'

export type CardCollectionBlockData = SectionBlockData & {
  cardCallToActionCaption: string
  links: (
    | ExternalTarget
    | {
        id: string
        fields?: {
          route?: string
        }
        metaDescription: {
          metaDescription: string
        }
        metaImage?: {
          gatsbyImageData: typeof GatsbyImage
        }
        metaTitle: string
      }
  )[]
}

type CardCollectionBlockProps = {
  data: CardCollectionBlockData
  index: number
}

const CardCollectionBlock: FC<CardCollectionBlockProps> = ({ data, index }) => {
  const {
    backgroundImage,
    backgroundOverlay,
    body,
    cardCallToActionCaption,
    htmlElementId,
    links,
    theme,
    title,
  } = data

  const inverse = theme === 'dark'

  return (
    <Section.Background
      id={htmlElementId}
      theme={theme}
      index={index}
      image={backgroundImage}
      overlay={backgroundOverlay}
    >
      <Section.Body>
        {title && <Section.Heading inverse={inverse}>{title}</Section.Heading>}
        {body && <Section.Lead inverse={inverse}>{body}</Section.Lead>}
        <Card.Grid>
          {links.map((link) => {
            const to = 'url' in link ? link.url : link.fields?.route
            const title = 'text' in link ? link.text : link.metaTitle
            const description =
              'metaDescription' in link && link.metaDescription.metaDescription
            const image = 'metaImage' in link && link.metaImage

            return (
              <CardWithImageHeader
                key={link.id}
                to={to}
                title={title}
                gatsbyImageData={image.gatsbyImageData}
                callToActionCaption={cardCallToActionCaption}
              >
                {description}
              </CardWithImageHeader>
            )
          })}
        </Card.Grid>
      </Section.Body>
    </Section.Background>
  )
}

export default CardCollectionBlock

export const query = graphql`
  fragment CardCollectionBlock on ContentfulCardCollectionBlock {
    backgroundImage {
      gatsbyImageData(width: 2560)
    }
    backgroundOverlay
    body {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    callToActionCaption
    callToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
        }
      }
    }
    cardCallToActionCaption
    htmlElementId
    id
    links {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          id
          fields {
            route
          }
          metaDescription {
            metaDescription
          }
          metaImage {
            gatsbyImageData(width: 2560)
          }
          metaTitle
        }
        ... on ContentfulLink {
          id
          text
          url
        }
        ... on ContentfulPage {
          id
          fields {
            route
          }
          metaDescription {
            metaDescription
          }
          metaImage {
            gatsbyImageData(width: 2560)
          }
          metaTitle
        }
      }
    }
    theme
    title
  }
`
