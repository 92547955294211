import React, { FC } from 'react'
import useInnerHTML from '../../hooks/use-inner-html'
import RichTextWithoutBlocks from '../rich-text/rich-text-without-blocks'

type AnnouncementBodyProps = {
  data: {
    raw: string
    references: any[]
  }
}

const AnnouncementBody: FC<AnnouncementBodyProps> = ({ data }) => {
  const [innerHTML, innerNodes] = useInnerHTML(data)

  return (
    <p {...innerHTML} className="text-white">
      &nbsp;–&nbsp;
      <RichTextWithoutBlocks data={innerNodes} />
    </p>
  )
}

export default AnnouncementBody
