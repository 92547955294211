import { DropdownData } from '../components/dropdown/desktop-dropdown'
import { DropdownCallToAction } from '../components/dropdown/desktop-dropdown-call-to-action'

const useDropdownCallToActions = (data: DropdownData) => {
  const {
    primaryCallToActionCaption,
    primaryCallToActionTarget,
    secondaryCallToActionCaption,
    secondaryCallToActionTarget,
  } = data

  const primaryCallToAction: DropdownCallToAction =
    primaryCallToActionCaption &&
      primaryCallToActionTarget && {
        caption: primaryCallToActionCaption,
        target: primaryCallToActionTarget,
        variant: 'primary',
      }

  const secondaryCallToAction: DropdownCallToAction =
    secondaryCallToActionCaption &&
      secondaryCallToActionTarget && {
        caption: secondaryCallToActionCaption,
        target: secondaryCallToActionTarget,
        variant: 'secondary',
      }

  const result: DropdownCallToAction[] = [
    ...(primaryCallToAction ? [primaryCallToAction] : []),
    ...(secondaryCallToAction ? [secondaryCallToAction] : []),
  ]

  return result
}

export default useDropdownCallToActions
